import {createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../utils/api';
import {User} from '../../utils/ApiInterface';
import {PayloadInterface, AuthenticatedUserActionInterface} from '../interface';
import UserType from '../type/UserType';

class UserAction {
  public static saveAuthenticatedUser(
    user: User,
  ): PayloadInterface<AuthenticatedUserActionInterface> {
    return {
      type: UserType.SAVE_AUTHENTICATED_USER,
      payload: {user},
    };
  }
}

export const getAuthenticatedUser = createAsyncThunk<User>(
  'user/authentication',
  async (parameters, {signal, rejectWithValue}) => {
    const {cancel, token} = api.getCancelToken();
    signal.addEventListener('abort', () => {
      cancel();
    });

    try {
      return await api.getUserProfile({cancelToken: token});
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default UserAction;
