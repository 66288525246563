class ApiType {
  public static get API_INIT(): string {
    return 'API_INIT';
  }

  public static get API_SUCCESS(): string {
    return 'API_SUCCESS';
  }

  public static get API_FAILURE(): string {
    return 'API_FAILURE';
  }

  public static get API_CANCEL(): string {
    return 'API_CANCEL';
  }

  public static get API_REMOVE(): string {
    return 'API_REMOVE';
  }
}

export default ApiType;
