import React from 'react';
import useGetAssetImage from '../hook/useGetAssetImage';
import {Asset} from '../utils/ApiInterface';
import HeroLayout from './HeroLayout';

export interface HeroMobileProps {
  className?: string;
  asset: Asset;
}

const HeroMobile = (
  props: React.PropsWithChildren<HeroMobileProps>,
): JSX.Element => {
  const {children, asset} = props;
  const {title} = asset;
  const poster = useGetAssetImage(asset);

  return (
    <HeroLayout>
      <div className="hero__multimedia">
        <div className="hero__media">
          <img className="hero__img" src={poster} alt={title} />
        </div>
      </div>
      <div className="hero__content">{children}</div>
    </HeroLayout>
  );
};

export default HeroMobile;
