import React from 'react';
import classNames from 'classnames';
import {Nav, NavItem} from 'reactstrap';

export interface TabHeaderUIProps {
  className?: string;
  itemClassName?: string;
  children: React.ReactElement[];
}

const TabHeaderUI: React.FC<TabHeaderUIProps> = (props) => {
  const {children, className, itemClassName} = props;

  return (
    <Nav tabs className={classNames('tab-ui__list', className)}>
      {React.Children.map(children, (child, index) => {
        return (
          <NavItem
            className={classNames(
              'tab-ui__list-item',
              {
                'border-end-0 me-0 pe-0': children.length - 1 === index,
                'border-start-0 ms-0 ps-0': 0 === index,
              },
              itemClassName,
            )}
          >
            {React.cloneElement(child)}
          </NavItem>
        );
      })}
    </Nav>
  );
};

export default TabHeaderUI;
