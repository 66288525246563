/* eslint-disable import/no-cycle */
import apiReducer from './reducer/apiReducer';
import assetReducer from './reducer/assetReducer';
import authenticationReducer from './reducer/authenticationReducer';
import playerStatusReducer from './reducer/playerStatusReducer';
import assetByOptionReducer from './reducer/assetByOptionReducer';
import categoryReducer from './reducer/categoryReducer';
import userReducer from './reducer/userReducer';
import qualificationReducer from './reducer/qualificationReducer';
import searchReducer from './reducer/searchReducer';
import assetFavoriteSlice from './slice/AssetFavoriteSlice';
import apiServer from './service/apiService';

const reducers = {
  api: apiReducer,
  assets: assetReducer,
  authentication: authenticationReducer,
  playerStatus: playerStatusReducer,
  categories: categoryReducer,
  assetByOption: assetByOptionReducer,
  user: userReducer,
  qualification: qualificationReducer,
  search: searchReducer,
  assetFavorite: assetFavoriteSlice,
  [apiServer.reducerPath]: apiServer.reducer,
};

export default reducers;
