/* eslint-disable import/no-cycle */
import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';
import makeGetQualificationIdsByAssetId from './makeGetQualificationIdsByAssetId';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const makeGetQualificationsByAssetId = () =>
  createDeepEqualSelector(
    [
      makeGetQualificationIdsByAssetId(),
      (state: RootState) => state.qualification.qualification,
    ],
    (qualificationIdsByAssetId, qualifications) => {
      if (0 === qualificationIdsByAssetId.length) {
        return [];
      }

      return qualificationIdsByAssetId.map(
        (qualificationId) => qualifications[qualificationId],
      );
    },
  );

export default makeGetQualificationsByAssetId;
