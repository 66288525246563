import createDeepEqualSelector from './createDeepEqualSelector';
import makeGetQualificationIdsByAssetId from './makeGetQualificationIdsByAssetId';
import makeGetUserQualificationByAssetId from './makeGetUserQualificationByAssetId';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const makeGetIfUserRateContent = () =>
  createDeepEqualSelector(
    [makeGetQualificationIdsByAssetId(), makeGetUserQualificationByAssetId()],
    (qualificationByAsset, userQualification) => {
      if (0 === qualificationByAsset.length || false === userQualification) {
        return false;
      }

      return true;
    },
  );

export default makeGetIfUserRateContent;
