import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import History from './History';
import ApiAction from '../store/action/ApiAction';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import Layout from '../component/Layout';
import authenticationValidator from '../router/authenticationValidator';
import useGetRequestStatus from '../hook/useGetRequestStatus';
import Preloader from '../component/Preloader';
import {RootState} from '../store/store';
import ErrorMessage from '../component/ErrorMessage';
import {useGetCategoryQuery} from '../store/service/classificationService';
import CategoryEntity from '../entity/CategoryEntity';

const Component = Layout(History);

const HistoryHCO = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {data, isLoading} = useGetCategoryQuery(CategoryEntity.ID_LANGUAGE);
  const historyRequest = useGetRequestStatus(ApiAction.labelToGetHistory());

  const isHistoryEmpty = useSelector(
    (state: RootState) => 0 === state.assets.history.length,
  );

  React.useEffect(() => {
    if (AsyncActionStatus.NOT_EXECUTED === historyRequest) {
      dispatch(ApiAction.getAssetsViewed('history'));
    }
  }, [dispatch, historyRequest]);

  if (
    undefined === data ||
    isLoading ||
    AsyncActionStatus.NOT_EXECUTED === historyRequest ||
    (AsyncActionStatus.PENDING === historyRequest && isHistoryEmpty)
  ) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Preloader />
      </div>
    );
  }

  if (AsyncActionStatus.FAILURE === historyRequest) {
    return (
      <ErrorMessage>
        <h1>{t('page.error')}</h1>
      </ErrorMessage>
    );
  }

  return <Component />;
};

export default authenticationValidator(HistoryHCO);
