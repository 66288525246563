import React from 'react';
import {TabPane, TabPaneProps} from 'reactstrap';

export interface TabPaneUIProps extends TabPaneProps {
  isActive: boolean;
}

const TabPaneUI: React.FC<TabPaneUIProps> = (props) => {
  const {children, isActive, ...properties} = props;
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    if (!isMounted && isActive) {
      setIsMounted(true);
    }
  }, [isActive, isMounted]);

  if (!isMounted) {
    return null;
  }

  return <TabPane {...properties}>{children}</TabPane>;
};

export default TabPaneUI;
