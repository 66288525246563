import React from 'react';
import Header from './Header';
import Footer from './Footer';

function layoutHOC(Component: React.FC): React.FC {
  function LayoutHOC(): JSX.Element {
    return (
      <div className="d-flex flex-column layout">
        <div className="layout__body">
          <Header />
          <div className="container_inner flex-grow-1">
            <Component />
          </div>
        </div>
        <Footer isAuthenticatedUser />
      </div>
    );
  }

  const componentName = Component.displayName || Component.name || 'component';
  LayoutHOC.displayName = componentName;

  return LayoutHOC;
}

export default layoutHOC;
