import React from 'react';
import Home from '../page/Home';
import QueryAssetModal from '../page/QueryAssetModal';

const HomePageLayout: React.FC = () => {
  return (
    <>
      <QueryAssetModal />
      <Home />
    </>
  );
};

export default HomePageLayout;
