import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import authenticationValidator from '../router/authenticationValidator';
import Search from './Search';
import Layout from '../component/Layout';
import {AppDispatch, RootState} from '../store/store';
import CategoryAction, {
  getCategoriesByContext,
} from '../store/action/CategoryAction';
import {useGetCategoryQuery} from '../store/service/classificationService';
import Preloader from '../component/Preloader';
import CategoryEntity from '../entity/CategoryEntity';

const Component = Layout(Search);

const SearchHOC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {data, isLoading} = useGetCategoryQuery(CategoryEntity.ID_LANGUAGE);

  const isCompetencyLoaded = useSelector(
    (state: RootState) => state.categories.categoryParent.competency,
  );
  const isSalesChannelLoaded = useSelector(
    (state: RootState) => state.categories.categoryParent.sales_channel,
  );
  const isOrganizationLoaded = useSelector(
    (state: RootState) => state.categories.categoryParent.organization,
  );
  const isPositionChannelLoaded = useSelector(
    (state: RootState) => state.categories.categoryParent.position,
  );

  React.useEffect(() => {
    if (undefined === isPositionChannelLoaded) {
      dispatch(
        getCategoriesByContext({
          categoryParam: {
            limit: 100,
            'context.id': ['position'],
            exists: {parent: true},
          },
        }),
      )
        .unwrap()
        .then((response) => {
          dispatch(CategoryAction.saveCategories(response));
        });
    }
  }, [dispatch, isPositionChannelLoaded]);

  React.useEffect(() => {
    if (undefined === isOrganizationLoaded) {
      dispatch(
        getCategoriesByContext({
          categoryParam: {
            limit: 100,
            'context.id': ['organization'],
            exists: {parent: true},
          },
        }),
      )
        .unwrap()
        .then((response) => {
          dispatch(CategoryAction.saveCategories(response));
        });
    }
  }, [dispatch, isOrganizationLoaded]);

  React.useEffect(() => {
    if (undefined === isCompetencyLoaded) {
      dispatch(
        getCategoriesByContext({
          categoryParam: {
            limit: 100,
            'context.id': ['competency'],
            exists: {parent: true},
          },
        }),
      )
        .unwrap()
        .then((response) => {
          dispatch(CategoryAction.saveCategories(response));
        });
    }
  }, [dispatch, isCompetencyLoaded]);

  React.useEffect(() => {
    if (undefined === isSalesChannelLoaded) {
      dispatch(
        getCategoriesByContext({
          categoryParam: {
            limit: 100,
            'context.id': ['sales_channel'],
            exists: {parent: true},
          },
        }),
      )
        .unwrap()
        .then((response) => {
          dispatch(CategoryAction.saveCategories(response));
        });
    }
  }, [dispatch, isSalesChannelLoaded]);

  if (undefined === data || isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Preloader />
      </div>
    );
  }

  return <Component />;
};

export default authenticationValidator(SearchHOC);
