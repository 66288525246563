import React from 'react';

export interface ContainerMultimediaProps {
  title: string;
  className?: string;
}

const ContainerMultimedia = (
  props: React.PropsWithChildren<ContainerMultimediaProps>,
): JSX.Element => {
  const {title, children} = props;

  return (
    <div className="container-multimedia mb-4">
      <h2 className="carousel-asset__title--web mb-4 pb-1">{title}</h2>

      {children}
    </div>
  );
};

export default ContainerMultimedia;
