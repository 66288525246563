import {AnyAction} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import {HydraResponse, UserAssetQualification} from '../../utils/ApiInterface';
import {
  QualificationByAssetActionInterface,
  QualificationsByAssetActionInterface,
} from '../interface';
import QualificationType from '../type/QualificationType';
import AuthenticationType from '../type/AuthenticationType';

export interface QualificationReducer {
  qualification: Record<string, UserAssetQualification>;
  qualificationByAsset: Record<string, HydraResponse<string[]>>;
}

export const initialState: QualificationReducer = {
  qualification: {},
  qualificationByAsset: {},
};

const qualificationSchema = new schema.Entity(
  'qualification',
  {},
  {idAttribute: '@id'},
);

const qualificationReducer = (
  state = initialState,
  action: AnyAction,
): QualificationReducer => {
  const {type, payload} = action;
  switch (type) {
    case QualificationType.SAVE_QUALIFICATIONS_BY_ASSET: {
      const {assetId, qualification} =
        payload as QualificationsByAssetActionInterface;
      const normalizedData = normalize(qualification['hydra:member'], [
        qualificationSchema,
      ]);

      let newQualificationByAsset =
        state.qualificationByAsset[assetId.toString()];

      if (newQualificationByAsset) {
        newQualificationByAsset = {
          ...newQualificationByAsset,
          ...qualification,
          'hydra:member': uniq([
            ...newQualificationByAsset['hydra:member'],
            ...normalizedData.result,
          ]),
        };
      } else {
        newQualificationByAsset = {
          ...qualification,
          'hydra:member': [...normalizedData.result],
        };
      }

      const newQualification = merge(
        {},
        state.qualification,
        normalizedData.entities.qualification,
      );

      return {
        ...state,
        qualificationByAsset: {
          ...state.qualificationByAsset,
          [assetId.toString()]: {...newQualificationByAsset},
        },
        qualification: {
          ...newQualification,
        },
      };
    }

    case QualificationType.SAVE_QUALIFICATION_BY_ASSET: {
      const {assetId, qualification} =
        payload as QualificationByAssetActionInterface;
      const normalizedData = normalize(qualification, qualificationSchema);

      const currentQualification =
        state.qualificationByAsset[assetId.toString()];

      const newQualificationByAsset = merge({}, currentQualification, {
        ...currentQualification,
        'hydra:member': uniq([
          ...currentQualification['hydra:member'],
          normalizedData.result,
        ]),
      });

      const newQualification = merge(
        {},
        {...state.qualification},
        normalizedData.entities.qualification,
      );

      return {
        ...state,
        qualificationByAsset: {
          ...state.qualificationByAsset,
          [assetId.toString()]: {...newQualificationByAsset},
        },
        qualification: {
          ...newQualification,
        },
      };
    }

    case AuthenticationType.SIGN_OUT: {
      return {...initialState};
    }

    default: {
      return state;
    }
  }
};

export default qualificationReducer;
