import {useMemo} from 'react';
import get from 'lodash/get';
import {useSelector} from 'react-redux';
import createDeepEqualSelector from '../selector/createDeepEqualSelector';
import {RootState} from '../store/store';
import {Asset} from '../utils/ApiInterface';

interface MakeGetCategoryById {
  categoryId: string;
}

const makeGetCategoryById = () =>
  createDeepEqualSelector(
    [
      (state: RootState) => {
        return state.assets.assetsByCategory;
      },
      (state: RootState, props: MakeGetCategoryById) => props.categoryId,
    ],
    (assetsByCategory, categoryId) => {
      return get(assetsByCategory, [categoryId, 'hydra:member'], []);
    },
  );

const makeGetAssetByCategoryId = () =>
  createDeepEqualSelector(
    [
      makeGetCategoryById(),
      (state: RootState) => {
        return state.assets.assets;
      },
    ],
    (data, assets) => {
      return data.map((assetId: number) => assets[assetId]);
    },
  );

const useGetAssetsByCategoryId = (categoryId: string): Asset[] => {
  const selectAssetsByCategoryIds = useMemo(makeGetAssetByCategoryId, []);

  const assetCollection = useSelector((state: RootState) =>
    selectAssetsByCategoryIds(state, {categoryId}),
  );

  return assetCollection;
};

export default useGetAssetsByCategoryId;
