import apiService from './apiService';
import {
  GetAssetHierarchiesParam,
  AssetHierarchy,
  AssetHierarchyApi,
  HydraResponse,
} from '../../utils/ApiInterface';

const assetHierarchyService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getHierarchies: builder.query<
      HydraResponse<AssetHierarchy[]>,
      GetAssetHierarchiesParam
    >({
      query: (params: GetAssetHierarchiesParam) => ({
        url: '/asset_hierarchies',
        method: 'get',
        config: {params},
      }),
      transformResponse: (response: HydraResponse<AssetHierarchyApi[]>) => {
        return {
          ...response,
          'hydra:member': response['hydra:member'].map((hierarchy) => {
            /** @todo {Remove this workaround to know the asset identifier when the `@id` is used as identifier}. */
            const childId = hierarchy.child.split('/');
            return {
              ...hierarchy,
              childId: parseInt(childId[childId.length - 1], 10),
            };
          }),
        };
      },
    }),
  }),
});

export const {useGetHierarchiesQuery} = assetHierarchyService;

export default assetHierarchyService;
