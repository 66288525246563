import React from 'react';
import {useSelector} from 'react-redux';
import {Asset} from '../utils/ApiInterface';
import AssetEntity from '../class/Asset';
import useGetAssetImage from '../hook/useGetAssetImage';
import PlayerHandler from './PlayerHandler';
import {RootState} from '../store/store';

export interface HeroWebProps {
  className?: string;
  canPlay?: boolean;
  asset: Asset;
}

const HeroWeb: React.FC<HeroWebProps> = (props) => {
  const {asset, className} = props;
  const {medias} = asset;

  const canPlay = useSelector((state: RootState) => {
    const {playerStatus} = state;

    return (
      0 === Object.keys(playerStatus).filter((key) => 'main' !== key).length
    );
  });

  // Contains `asset.medias` filtered by 'providerMetadata.context' equal to "AssetEntity.MEDIA_METADATA_CONTEXT_TRAILER", if asset does not have a trailer it returns `asset.medias`.
  const assetMedias = React.useMemo(() => {
    const trailers = medias.filter(
      (media) =>
        AssetEntity.MEDIA_METADATA_CONTEXT_TRAILER ===
        media.providerMetadata.context,
    );

    if (0 < trailers.length) {
      return trailers;
    }

    return medias;
  }, [medias]);

  const poster = useGetAssetImage(asset);

  return (
    <PlayerHandler
      canPlay={canPlay}
      identifier="main"
      className={className}
      medias={assetMedias}
      poster={poster}
    />
  );
};

export default HeroWeb;
