import React from 'react';
import useGetAsset from '../hook/useGetAsset';
import AssetItemCarousel from './AssetItemCarousel';

export interface ListItemProps {
  className?: string;
  assetId: number;
}

const ListItem: React.FC<ListItemProps> = (
  props: React.PropsWithChildren<ListItemProps>,
): JSX.Element => {
  const {assetId} = props;
  const asset = useGetAsset(assetId);

  return (
    <div className="grid-list__item">
      <AssetItemCarousel asset={asset} showThumbnail />
    </div>
  );
};

export default ListItem;
