import React from 'react';
import {Modal} from 'reactstrap';
import Preloader from './Preloader';
import AssetLayoutModal from './AssetLayoutModal';
import {AssetLayoutActionEnum} from '../class/AssetLayout';
import {useGetAssetQuery} from '../store/service/assetService';

export interface AssetModalProps {
  assetId: number;
  closeModal: VoidFunction;
  actionLayout?: AssetLayoutActionEnum;
}

/**
 * Renders the detail of an asset on a modal.
 */
const AssetModal: React.FC<AssetModalProps> = (props) => {
  const {assetId, actionLayout, closeModal} = props;
  const {data: asset, isFetching, isError} = useGetAssetQuery(assetId);

  if (isError) {
    closeModal();
  }

  if (false === isFetching && undefined === asset) {
    return null;
  }

  return (
    <Modal isOpen onClosed={closeModal} size="lg" className="asset-modal">
      <div className="asset-modal__header">
        <button
          type="button"
          className="ms-auto btn asset-modal__close btn--dark line-height"
          aria-label="Close"
          onClick={closeModal}
        >
          <i className="fas fa-times standard-icon-size--20" />
        </button>
      </div>
      {isFetching || undefined === asset ? (
        <div className="m-auto">
          <Preloader />
        </div>
      ) : (
        <AssetLayoutModal asset={asset} actionLayout={actionLayout} />
      )}
    </Modal>
  );
};

export default AssetModal;
