/* eslint-disable import/no-cycle */
import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';
import makeGetQualificationsByAssetId from './makeGetQualificationsByAssetId';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const makeGetUserQualificationByAssetId = () =>
  createDeepEqualSelector(
    [
      makeGetQualificationsByAssetId(),
      (state: RootState) => state.user.authenticatedUser,
    ],
    (qualificationsByAssetId, userId) => {
      if (!userId || 0 === qualificationsByAssetId.length) {
        return false;
      }

      const userQualification = qualificationsByAssetId.find(
        (qualification) => qualification.user.id === userId,
      );

      return userQualification || false;
    },
  );

export default makeGetUserQualificationByAssetId;
