import {AnyAction} from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import {
  PlayerInitActionInterface,
  PlayerStatusActionInterface,
  QualificationByAssetActionInterface,
} from '../interface';
import AuthenticationType from '../type/AuthenticationType';
import PlayerStatusType from '../type/PlayerStatusType';
import QualificationType from '../type/QualificationType';

export interface PlayerOption {
  isLoaded: boolean;
  isPlaying: boolean;
  isExecutable: boolean;
  isMuted: boolean;
  isEnding: boolean;
}

export interface MainPlayerReducer {
  [key: string]: PlayerOption;
}

export const initialState: MainPlayerReducer = {};

const defaultState: PlayerOption = {
  isExecutable: false,
  isLoaded: false,
  isMuted: false,
  isPlaying: false,
  isEnding: false,
};

const playerStatusReducer = (
  state = initialState,
  action: AnyAction,
): MainPlayerReducer => {
  const {type, payload} = action;

  switch (type) {
    case PlayerStatusType.SET_ASSET_IS_INIT: {
      const {name, option} = payload as PlayerInitActionInterface;

      return {
        ...state,
        [name]: {...defaultState, ...state[name], ...option},
      };
    }

    case PlayerStatusType.SET_ASSET_STATUS: {
      const {name, option} = payload as PlayerStatusActionInterface;

      return {
        ...state,
        [name]: {...defaultState, ...state[name], isPlaying: option},
      };
    }

    case PlayerStatusType.SET_ASSET_IS_EXECUTABLE: {
      const {name, option} = payload as PlayerStatusActionInterface;

      return {
        ...state,
        [name]: {...defaultState, ...state[name], isExecutable: option},
      };
    }

    case PlayerStatusType.SET_ASSET_IS_LOADED: {
      const {name, option} = payload as PlayerStatusActionInterface;

      return {
        ...state,
        [name]: {...defaultState, ...state[name], isLoaded: option},
      };
    }

    case PlayerStatusType.SET_ASSET_IS_MUTED: {
      const {name, option} = payload as PlayerStatusActionInterface;

      return {
        ...state,
        [name]: {...defaultState, ...state[name], isMuted: option},
      };
    }

    case PlayerStatusType.SET_ASSET_IS_ENDING: {
      const {name, option} = payload as PlayerStatusActionInterface;

      return {
        ...state,
        [name]: {...defaultState, ...state[name], isEnding: option},
      };
    }

    case PlayerStatusType.REMOVE_PLAYER_STATUS: {
      const {name} = payload as PlayerStatusActionInterface;

      return {
        ...omit(state, [name]),
      };
    }

    case QualificationType.SAVE_QUALIFICATION_BY_ASSET: {
      const {assetId} = payload as QualificationByAssetActionInterface;

      return {
        ...omit(state, [assetId.toString()]),
      };
    }

    case AuthenticationType.SIGN_OUT: {
      return {...initialState};
    }

    default: {
      return state;
    }
  }
};

export default playerStatusReducer;
