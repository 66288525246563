import React from 'react';
import classNames from 'classnames';
import {Button} from 'reactstrap';
import {useDispatch} from 'react-redux';
import PlayerStatusAction from '../store/action/PlayerStatusAction';

export interface VideoPlayButtonProps {
  id: string;
  iconClassName?: string;
  buttonClassName?: string;
  containerClassName?: string;
}

const VideoPlayButton: React.FC<VideoPlayButtonProps> = (props) => {
  const {containerClassName, iconClassName, buttonClassName, id} = props;
  const playButtonRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const onPlayEvent = () => {
      dispatch(PlayerStatusAction.setAssetStatus(id, true));
    };
    const button = playButtonRef.current;
    let isEventActive = false;

    if (button) {
      isEventActive = true;
      button.addEventListener('click', onPlayEvent);
    }

    return () => {
      if (button && isEventActive) {
        button.removeEventListener('click', onPlayEvent);
      }
    };
  }, [dispatch, playButtonRef, id]);

  return (
    <Button
      color="dark"
      className={classNames(buttonClassName)}
      innerRef={playButtonRef}
    >
      <div className={classNames(containerClassName)}>
        <i className={classNames(iconClassName, 'icomoon icon-play')} />
      </div>
    </Button>
  );
};

export default VideoPlayButton;
