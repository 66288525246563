import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import makeGetUserQualificationByAssetId from '../selector/makeGetUserQualificationByAssetId';
import {RootState} from '../store/store';
import {UserAssetQualification} from '../utils/ApiInterface';

const useGetUserQualification = (
  assetId: number,
): UserAssetQualification | false => {
  const selectUserQualification = useMemo(
    makeGetUserQualificationByAssetId,
    [],
  );

  const userQualification = useSelector((state: RootState) =>
    selectUserQualification(state, {assetId}),
  );

  return userQualification;
};

export default useGetUserQualification;
