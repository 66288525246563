import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

/**
 * @todo Fix interface to extend from `LinkProps` if 'isLinked' is` true`.
 * @see {https://react-typescript-cheatsheet.netlify.app/docs/advanced/patterns_by_usecase#props-pass-one-only-if-the-other-is-passed}
 */

export interface AssetTitleProps {
  title: string;
  className?: string;
  isLinked?: boolean;
  to?: string;
}

/**
 * Render the asset title.
 */
const AssetTitle: React.FC<AssetTitleProps> = (props) => {
  const {title, className, isLinked, to} = props;

  return (
    <h3 className={classNames('multimedia-card__title', className)}>
      {isLinked && to ? (
        <Link
          className="multimedia-card__link stretched-link text-decoration-none"
          to={to}
        >
          {title}
        </Link>
      ) : (
        title
      )}
    </h3>
  );
};

export default AssetTitle;
