class PlayerStatus {
  public static get SET_ASSET_IS_INIT(): string {
    return 'SET_ASSET_IS_INIT';
  }

  public static get SET_ASSET_IS_LOADED(): string {
    return 'SET_ASSET_IS_LOADED';
  }

  public static get SET_ASSET_STATUS(): string {
    return 'SET_ASSET_STATUS';
  }

  public static get SET_ASSET_IS_EXECUTABLE(): string {
    return 'SET_ASSET_IS_EXECUTABLE';
  }

  public static get SET_ASSET_IS_MUTED(): string {
    return 'SET_ASSET_IS_MUTED';
  }

  public static get SET_ASSET_IS_ENDING(): string {
    return 'SET_ASSET_IS_ENDING';
  }

  public static get REMOVE_PLAYER_STATUS(): string {
    return 'REMOVE_PLAYER_STATUS';
  }
}

export default PlayerStatus;
