import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../store/store';
import PlayerStatusAction from '../store/action/PlayerStatusAction';
import PdfReader from '../page/PdfReader';
import useGetLanguageQueryAsset from '../hook/useGetLanguageQueryAsset';
import useFilterMediaByLanguage from '../hook/useFilterMediaByLanguage';
import AssetEntity from '../class/Asset';
import {Asset} from '../utils/ApiInterface';

export interface AssetPdfLayoutProps {
  asset: Asset;
  trainer?: Asset;
}

const AssetPdfLayout: React.FC<AssetPdfLayoutProps> = (props) => {
  const {asset, trainer} = props;
  const {id: assetId} = asset;
  const {t} = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const userLanguageSelected = useGetLanguageQueryAsset();

  const isAssetEnding = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[assetId];

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isEnding;
  });

  const isAssetLoaded = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[assetId];

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isLoaded;
  });

  const medias = React.useMemo(() => {
    return asset.medias.filter(
      ({providerMetadata: {context}}) =>
        AssetEntity.MEDIA_METADATA_CONTEXT_RESOURCE === context,
    );
  }, [asset]);

  const media = useFilterMediaByLanguage(medias, userLanguageSelected);

  const completeAsset = React.useCallback(() => {
    dispatch(PlayerStatusAction.setAssetIsEnding(assetId.toString(), true));
  }, [dispatch, assetId]);

  React.useEffect(() => {
    const onScroll = () => {
      if (
        document.documentElement.scrollHeight - 50 <=
        window.pageYOffset + window.innerHeight - 50
      ) {
        completeAsset();

        const bodyElement = document.querySelector<HTMLBodyElement>('body');
        if (bodyElement) {
          bodyElement.style.overflow = 'hidden';
        }
      }
    };

    let isEventListenerActive = false;

    if (undefined !== trainer && isAssetLoaded && !isAssetEnding) {
      isEventListenerActive = true;
      document.addEventListener('scroll', onScroll);
    }

    return () => {
      if (isEventListenerActive) {
        document.removeEventListener('scroll', onScroll);
      }
    };
  }, [isAssetLoaded, completeAsset, isAssetEnding, trainer]);

  React.useEffect(() => {
    return () => {
      const bodyElement = document.querySelector<HTMLBodyElement>('body');
      if (bodyElement && 'hidden' === bodyElement.style.overflow) {
        bodyElement.style.overflow = '';
      }
    };
  }, [assetId]);

  return (
    <div className="player-layout">
      <PdfReader
        url={media.publicUrls.reference}
        className="player-layout__content pt-5"
        assetId={assetId}
      />

      <div className="mt-auto player-layout__footer container_inner py-3 text-end">
        {undefined === trainer && (
          <Button color="dark" className="btn--dark" onClick={completeAsset}>
            {t('component.qualifyButton')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default AssetPdfLayout;
