import React from 'react';
import {Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Link, LinkProps} from 'react-router-dom';
import useGetUserQualification from '../hook/useGetUserQualification';

export interface LinkToAssetQualificationProps extends Pick<LinkProps, 'to'> {
  assetId: number;
  className?: string;
  iconClassName?: string;
  textClassName?: string;
  iconContainerClassName?: string;
}

const LinkToAssetQualification: React.FC<LinkToAssetQualificationProps> = (
  props,
) => {
  const {t} = useTranslation();
  const {
    className,
    iconClassName,
    textClassName,
    iconContainerClassName,
    to,
    assetId,
  } = props;

  const isAssetQualified = useGetUserQualification(assetId);

  return (
    <Button tag={Link} color="dark" className={classNames(className)} to={to}>
      <div className={classNames(iconContainerClassName)}>
        <i
          className={classNames(iconClassName, {
            'icomoon icon-star_1': !isAssetQualified,
            'icomoon icon-star_5': isAssetQualified,
          })}
        />
      </div>
      <span className={classNames(textClassName)}>
        {t('component.qualifyButton')}
      </span>
    </Button>
  );
};

export default LinkToAssetQualification;
