import {AnyAction} from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import {AssetByKeyActionInterface} from '../interface';
import AssetType from '../type/AssetType';
import AuthenticationType from '../type/AuthenticationType';

export const initialState: Record<string, number> = {};

const assetByOptionReducer = (
  state = initialState,
  action: AnyAction,
): Record<string, number> => {
  const {type, payload} = action;

  switch (type) {
    case AssetType.SAVE_ASSET_BY_OPTION: {
      const {key, asset} = payload as AssetByKeyActionInterface;

      return {...state, [key]: asset?.id};
    }

    case AssetType.REMOVE_ASSET_BY_OPTION: {
      const {key} = payload;

      return {...omit(state, [key])};
    }

    case AuthenticationType.SIGN_OUT: {
      return {...initialState};
    }

    default: {
      return state;
    }
  }
};

export default assetByOptionReducer;
