import {
  PayloadInterface,
  PlayerInitActionInterface,
  PlayerInitOptionActionInterface,
  PlayerStatusActionInterface,
} from '../interface';
import PlayerStatusType from '../type/PlayerStatusType';

class PlayerStatusAction {
  public static setAssetPlayerInit(
    name: string,
    option: PlayerInitOptionActionInterface,
  ): PayloadInterface<PlayerInitActionInterface> {
    return {
      type: PlayerStatusType.SET_ASSET_IS_INIT,
      payload: {name, option},
    };
  }

  public static setAssetStatus(
    name: string,
    option: boolean,
  ): PayloadInterface<PlayerStatusActionInterface> {
    return {
      type: PlayerStatusType.SET_ASSET_STATUS,
      payload: {name, option},
    };
  }

  public static setAssetIsLoaded(
    name: string,
    option: boolean,
  ): PayloadInterface<PlayerStatusActionInterface> {
    return {
      type: PlayerStatusType.SET_ASSET_IS_LOADED,
      payload: {name, option},
    };
  }

  public static setAssetIsExecutable(
    name: string,
    option: boolean,
  ): PayloadInterface<PlayerStatusActionInterface> {
    return {
      type: PlayerStatusType.SET_ASSET_IS_EXECUTABLE,
      payload: {name, option},
    };
  }

  public static setAssetIsMuted(
    name: string,
    option: boolean,
  ): PayloadInterface<PlayerStatusActionInterface> {
    return {
      type: PlayerStatusType.SET_ASSET_IS_MUTED,
      payload: {name, option},
    };
  }

  public static setAssetIsEnding(
    name: string,
    option: boolean,
  ): PayloadInterface<PlayerStatusActionInterface> {
    return {
      type: PlayerStatusType.SET_ASSET_IS_ENDING,
      payload: {name, option},
    };
  }

  public static removePlayerStatus(
    name: string,
  ): PayloadInterface<Omit<PlayerStatusActionInterface, 'option'>> {
    return {
      type: PlayerStatusType.REMOVE_PLAYER_STATUS,
      payload: {name},
    };
  }
}

export default PlayerStatusAction;
