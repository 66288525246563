import {RootState} from '../store/store';
import createDeepEqualSelector from './createDeepEqualSelector';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export interface MakeGetAsset {
  assetId: number;
}

const makeGetAsset = () =>
  createDeepEqualSelector(
    [
      (state: RootState) => {
        const {
          assets: {assets},
        } = state;

        return assets;
      },
      (state: RootState, props: MakeGetAsset) => props.assetId,
    ],
    (assets, assetId) => {
      return assets[assetId];
    },
  );

export default makeGetAsset;
