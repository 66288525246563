/* eslint-disable import/no-cycle */
import {createAsyncThunk} from '@reduxjs/toolkit';
import {Asset} from '../../utils/ApiInterface';
import api from '../../utils/api';
import {ThunkConfiguration} from '../store';

export const getAssetFavorite = createAsyncThunk<
  Asset | undefined,
  {assetId: number},
  ThunkConfiguration
>(
  'asset/getOneFavorite',
  async ({assetId}, {signal}) => {
    const {cancel, token} = api.getCancelToken();
    signal.addEventListener('abort', () => {
      cancel();
    });

    const response = await api.getUserAssetFavorite(assetId, {
      cancelToken: token,
    });
    const [asset] = response['hydra:member'] || [];

    return asset;
  },
  {
    condition: ({assetId}, {getState}) => {
      const {assetFavorite} = getState();

      if (assetId.toString() in assetFavorite) {
        return false;
      }

      return undefined;
    },
  },
);

export default {
  getAssetFavorite,
};
