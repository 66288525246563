import React from 'react';
import omit from 'lodash/omit';
import {Link} from 'react-router-dom';
import {Button, ButtonProps} from 'reactstrap';
import routeCollection from '../router/routeCollection';
import UrlProcessor from '../utils/UrlProcessor';
import useGetQueryParameters from '../hook/useGetQueryParameters';
import useGetNextAssetId from '../hook/useGetNextAssetId';
import AssetTrainer from '../class/AssetTrainer';

export interface ButtonLinkToPlayerTrainerProps extends ButtonProps {
  assetId: number;
  className?: string;
}

const ButtonLinkToPlayerTrainer: React.FC<ButtonLinkToPlayerTrainerProps> = (
  props,
) => {
  const {assetId, children, disabled, ...properties} = props;
  const queryParameters = useGetQueryParameters();
  let parameters = omit(queryParameters, ['asset']);
  const nextAssetId = useGetNextAssetId(assetId);

  parameters = {
    ...parameters,
    [AssetTrainer.URL_QUERY_PARAMETER_KEY]: assetId.toString(),
  };

  const assetPlayerUrlPath = UrlProcessor.createPath(
    routeCollection.player,
    {
      id: nextAssetId || 0,
    },
    parameters,
  );

  return (
    <Button
      tag={Link}
      to={assetPlayerUrlPath}
      disabled={undefined === nextAssetId || disabled}
      {...properties}
    >
      {children}
    </Button>
  );
};

export default ButtonLinkToPlayerTrainer;
