import uniq from 'lodash/uniq';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable consistent-return */
/* eslint-disable func-names */

const merge = (objectOld: any, objectNew: any) => {
  return mergeWith(
    objectOld,
    objectNew,
    function (objValue: any, srcValue: any) {
      if (isArray(objValue)) {
        return uniq([...objValue, ...srcValue]);
      }
    },
  );
};

export default merge;
