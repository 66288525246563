import createDeepEqualSelector from './createDeepEqualSelector';
import AssetGroupedByDate from '../utils/AssetGroupedByDate';
import makeGetAssetsByCategory from './makeGetAssetsByCategory';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const makeGetAssetsHistory = () =>
  createDeepEqualSelector([makeGetAssetsByCategory()], (assetsHistory) => {
    return AssetGroupedByDate(assetsHistory);
  });

export default makeGetAssetsHistory;
