import React from 'react';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import AssetModel from '../model/AssetModel';
import {RootState} from '../store/store';
import {Asset} from '../utils/ApiInterface';
import HeaderPlayer from './HeaderPlayer';
import UrlProcessor from '../utils/UrlProcessor';
import routeCollection from '../router/routeCollection';
import AssetTrainer from '../class/AssetTrainer';
import useGetQueryParameters from '../hook/useGetQueryParameters';

export interface AssetPlayLayoutHeaderProps {
  asset: Asset;
  trainer?: Asset;
}

const AssetPlayLayoutHeader: React.FC<AssetPlayLayoutHeaderProps> = (props) => {
  const {asset, trainer} = props;
  const {type} = asset;
  const queryParameters = useGetQueryParameters();
  const isAssetPlaying = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[asset.id];

    if (undefined === currentPlayerStatus || !currentPlayerStatus.isLoaded) {
      return false;
    }

    return currentPlayerStatus.isPlaying;
  });

  const assetUrlPath = React.useMemo(
    () =>
      trainer
        ? UrlProcessor.createPath(
            routeCollection.detail,
            {
              id: trainer.id,
            },
            {
              ...omit(queryParameters, [AssetTrainer.URL_QUERY_PARAMETER_KEY]),
            },
          )
        : UrlProcessor.createPath(
            routeCollection.detail,
            {
              id: asset.id,
            },
            {
              ...queryParameters,
            },
          ),
    [queryParameters, asset, trainer],
  );

  return (
    <div
      className={classNames('layout__header', {
        'layout__header--inactive':
          (AssetModel.TYPE_AUDIO !== type && AssetModel.TYPE_VIDEO !== type) ||
          !isAssetPlaying,
      })}
    >
      <HeaderPlayer>
        <Link to={assetUrlPath} className="text-white text-decoration-none">
          <div className="standard-icon-height standard-icon-height--26">
            <i className="icomoon icon-go_back_menu px-2 px-md-3 py-2 header-icon__logout" />
          </div>
        </Link>
      </HeaderPlayer>
    </div>
  );
};

export default AssetPlayLayoutHeader;
