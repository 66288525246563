import React from 'react';
import AssetDurationHumanized from './AssetDurationHumanized';
import AssetMedia from './AssetMedia';
import AssetProgressBar from './AssetProgressBar';
import AssetTitle from './AssetTitle';
import AssetTypeIcon from './AssetTypeIcon';
import MultimediaCardDescription from './MultimediaCardDescription';
import routeCollection from '../router/routeCollection';
import UrlProcessor from '../utils/UrlProcessor';
import AssetTrainer from '../class/AssetTrainer';
import {Asset} from '../utils/ApiInterface';

export interface AssetListItemLayoutProps {
  trainingId?: number;
  asset: Asset;
  position: number;
}

const AssetListItemLayout: React.FC<AssetListItemLayoutProps> = (props) => {
  const {asset, trainingId, position} = props;
  const {title, duration, content, type} = asset;

  const queryParameter = trainingId
    ? {[AssetTrainer.URL_QUERY_PARAMETER_KEY]: trainingId}
    : {};

  const playerPath = UrlProcessor.createPath(
    routeCollection.player,
    {
      id: asset.id,
    },
    queryParameter,
  );

  return (
    <div className="row position-relative">
      <div className="order-1 col-auto align-self-start align-self-md-center">
        <div className="text-center asset-list__position">{position}</div>
      </div>
      <div className="order-1 col-auto asset-list__image-wrapper align-self-start align-self-md-center">
        <div className="background-9-16">
          <div className="background-9-16__asset_display">
            <AssetMedia asset={asset} />
          </div>
          <div className="asset-overlaid__icon-type w-100 d-flex justify-content-end pe-2">
            <AssetTypeIcon
              type={type}
              showType={false}
              wrapperClassName="p-1 container-icon container-icon--list"
              iconClassName="standard-icon-size--14 detail-page-icon__pdf--small"
            />
          </div>
          <div className="asset-overlaid__progress-bar">
            <AssetProgressBar asset={asset} trainerId={trainingId} />
          </div>
        </div>
      </div>
      <div className="order-2 order-md-1 col-12 col-md position-static mt-2 pt-1 pt-md-0 mt-md-0">
        <div className="asset-list__title-wrapper">
          <AssetTitle
            isLinked
            className="asset-list__title mb-0"
            to={playerPath}
            title={title}
          />
        </div>

        <div className="asset-list__description-wrapper">
          {content && <MultimediaCardDescription content={content} />}
        </div>
      </div>
      <div className="order-1 col-auto ms-auto">
        {duration && 0 < duration ? (
          <AssetDurationHumanized
            className="pt-0 pt-md-3"
            duration={duration}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AssetListItemLayout;
