import React from 'react';
import {useSelector} from 'react-redux';
import ApiAction from '../store/action/ApiAction';
import {RootState} from '../store/store';
import AssetByCategory from './AssetByCategory';

export interface AssetByCategoriesLoadProps {
  categoryId: string;
  storeLabelId: string;
}

const AssetByCategoriesLoad: React.FC<AssetByCategoriesLoadProps> = (props) => {
  const {storeLabelId, categoryId} = props;

  // @todo This component needs to handle pagination.

  const category = useSelector(
    (state: RootState) => state.categories.category[categoryId],
  );

  return (
    <div>
      <AssetByCategory
        categoryName={category.name}
        categoryId={storeLabelId}
        labelApiId={ApiAction.labelToGetAssetsByCategory(storeLabelId)}
      />
    </div>
  );
};

export default AssetByCategoriesLoad;
