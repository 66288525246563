import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import makeGetNextPage from '../selector/makeGetNextPage';
import {RootState} from '../store/store';

const useGetNextPage = (categoryName: string): number | undefined => {
  const selectUserQualification = useMemo(makeGetNextPage, []);

  const page = useSelector((state: RootState) =>
    selectUserQualification(state, {categoryName}),
  );

  return page;
};

export default useGetNextPage;
