import React from 'react';
import {useTranslation} from 'react-i18next';
import Parser from 'html-react-parser';

const TermsCondition = (): JSX.Element => {
  const {t} = useTranslation();

  return (
    <div className="condition-page">
      <div className="card">
        <div className="card-header text-center">{t('page.privacy.title')}</div>
        <div className="card-body">
          {Parser(t('page.privacy.termsCondition'))}
        </div>
      </div>
    </div>
  );
};
export default TermsCondition;
