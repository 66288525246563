import sortBy from 'lodash/sortBy';
import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';
import makeGetChildrenCategoriesByCategory from './makeGetChildrenCategoriesByCategory';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const makeGetChildrenCategoriesIdsByCategory = () =>
  createDeepEqualSelector(
    [
      makeGetChildrenCategoriesByCategory(),
      (state: RootState) => state.categories.category,
    ],
    (childrenCategories, categoryCollection) => {
      return sortBy(
        childrenCategories.map((categoryId) => categoryCollection[categoryId]),
        [(option) => option.position],
      );
    },
  );

export default makeGetChildrenCategoriesIdsByCategory;
