import React from 'react';
import {Alert} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import storage from '../utils/storage';

const ResolutionWarningMessage: React.FC = () => {
  const isToggle = React.useMemo(() => {
    try {
      const userNotification = JSON.parse(
        storage.getItem('userNotification') || '',
      );

      return !userNotification.resolution;
    } catch (error) {
      return true;
    }
  }, []);
  const [visible, setVisible] = React.useState(isToggle);

  const onDismiss = () => {
    storage.setItem('userNotification', JSON.stringify({resolution: true}));
    setVisible(false);
  };
  const {t} = useTranslation();

  return (
    <div className="user-notification user-notification--resolution">
      <Alert
        color="warning"
        className="font-size--tiny rounded-0"
        isOpen={visible}
        toggle={onDismiss}
      >
        {t('component.userNotification.resolution')}
      </Alert>
    </div>
  );
};

export default ResolutionWarningMessage;
