/* eslint-disable import/no-cycle */
import {createSlice} from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import {getAssetFavorite} from '../action/asset';

/**
 * The object key is the asset identifier and the value represents whether the asset is marked as favorite or not.
 *
 * @example
 ```js
  const state = {
    1: true,
    2: false,
  };
  ```
 */
export const initialState: Record<string, boolean> = {};

/**
 * Stores the favorite property of an asset
 */
const assetFavoriteSlice = createSlice({
  name: 'assetFavoriteSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAssetFavorite.pending, (state, action) => {
      const {
        meta: {
          arg: {assetId},
        },
      } = action;

      return {...state, [assetId]: false};
    });
    builder.addCase(getAssetFavorite.fulfilled, (state, action) => {
      const {
        meta: {
          arg: {assetId},
        },
        payload,
      } = action;

      return {...state, [assetId]: !!payload};
    });
    builder.addCase(getAssetFavorite.rejected, (state, action) => {
      return omit(state, action.meta.arg.assetId.toString());
    });
  },
});

export default assetFavoriteSlice.reducer;
