import React from 'react';
import classNames from 'classnames';
import {Button} from 'reactstrap';
import {useDispatch} from 'react-redux';
import PlayerStatusAction from '../store/action/PlayerStatusAction';

export interface VideoPlayButtonProps {
  id: string;
  iconClassName?: string;
  buttonClassName?: string;
  containerClassName?: string;
}

const VideoRePlayButton: React.FC<VideoPlayButtonProps> = (props) => {
  const {containerClassName, iconClassName, buttonClassName, id} = props;
  const rePlayButtonRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const onReplayEvent = () => {
      dispatch(PlayerStatusAction.setAssetIsEnding(id, false));
      dispatch(PlayerStatusAction.setAssetStatus(id, true));
    };
    const button = rePlayButtonRef.current;

    let isEventActive = false;

    if (button) {
      isEventActive = true;
      button.addEventListener('click', onReplayEvent);
    }

    return () => {
      if (button && isEventActive) {
        button.removeEventListener('click', onReplayEvent);
      }
    };
  }, [dispatch, rePlayButtonRef, id]);

  return (
    <Button
      color="dark"
      className={classNames(buttonClassName)}
      innerRef={rePlayButtonRef}
    >
      <div className={classNames(containerClassName)}>
        <i className={classNames(iconClassName, 'icomoon icon-refresh')} />
      </div>
    </Button>
  );
};

export default VideoRePlayButton;
