import {createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../utils/api';
import {
  HydraResponse,
  Category,
  GetCategoriesParam,
} from '../../utils/ApiInterface';
import {PayloadInterface} from '../interface';
import CategoryType from '../type/CategoryType';

class CategoryAction {
  public static saveCategories(
    categories: HydraResponse<Category[]>,
  ): PayloadInterface<HydraResponse<Category[]>> {
    return {
      type: CategoryType.SAVE_CATEGORIES,
      payload: categories,
    };
  }
}

export const getCategoriesByContext = createAsyncThunk<
  HydraResponse<Category[]>,
  {categoryParam: GetCategoriesParam}
>('category/getCategoriesByContext', async ({categoryParam}, {signal}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  return await api.getCategories(categoryParam, {cancelToken: token});
});

export default CategoryAction;
