import {useCallback, useEffect, useState} from 'react';
import {Hub} from 'aws-amplify';
import {useDispatch, useSelector} from 'react-redux';
import AuthenticationModel from '../model/AuthenticationModel';
import AuthenticationAction from '../store/action/AuthenticationAction';
import {AppDispatch, RootState} from '../store/store';
import UserSession from '../utils/UserSession';
import UserAction, {getAuthenticatedUser} from '../store/action/UserAction';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface UseAuthenticationValidator {
  isValidating: boolean;
  isAuthenticated: boolean;
  showGif: boolean;
  authenticatedUser?: number;
}

const useAuthenticationValidator = (): UseAuthenticationValidator => {
  const [showGif, setShowGif] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector(
    (state: RootState) => state.authentication.isAuthenticated,
  );
  const [isValidating, setIsValidating] = useState(!isAuthenticated);
  const authenticatedUser = useSelector(
    (state: RootState) => state.user.authenticatedUser,
  );

  const signIn = useCallback(
    (storage: Record<string, string>): void => {
      let authenticationType = '';

      if ('false' === storage['amplify-signin-with-hostedUI']) {
        authenticationType =
          AuthenticationModel.AUTHENTICATION_TYPE_USER_PASSWORD;
      }

      dispatch(AuthenticationAction.signIn(authenticationType));
    },
    [dispatch],
  );

  useEffect(() => {
    // if user isn't authenticated and authentication is being validated.
    if (!isAuthenticated && isValidating) {
      UserSession.getSession()
        .then(() => {
          UserSession.isAuthenticated()
            .then((userData) => {
              const {storage} = userData;
              signIn(userData.storage);

              if (
                'true' === storage['amplify-redirected-from-hosted-ui'] &&
                'true' === storage['amplify-signin-with-hostedUI']
              ) {
                setShowGif(true);
              }

              setIsValidating(false);
            })
            .catch(() => {
              setIsValidating(false);
              dispatch(AuthenticationAction.signOut());
            });
        })
        .catch(() => {
          setIsValidating(false);
        });
    }
  }, [dispatch, signIn, isAuthenticated, isValidating]);

  useEffect(() => {
    const hubListener = (data: any) => {
      if ('signOut' === data.payload.event) {
        setShowGif(false);
      }

      if ('signIn' === data.payload.event) {
        signIn(data.payload.data.storage);
        setShowGif(true);
      }

      if ('cognitoHostedUI' === data.payload.event) {
        setShowGif(true);
      }

      // @todo Fix authentication flow by listening for correct events.
      // if ('cognitoHostedUI_failure' === data.payload.event) {
      // }
    };

    if (!isValidating) {
      Hub.listen('auth', hubListener);
    }

    return () => {
      Hub.remove('auth', hubListener);
    };
  }, [signIn, isValidating]);

  useEffect(() => {
    let abortPromise: VoidFunction | undefined;
    if (isAuthenticated && undefined === authenticatedUser) {
      const promise = dispatch(getAuthenticatedUser());
      abortPromise = promise.abort;

      promise
        .unwrap()
        .then((user) => {
          dispatch(UserAction.saveAuthenticatedUser(user));
        })
        .catch(() => {
          dispatch(AuthenticationAction.signOut());
        })
        .finally(() => {
          abortPromise = undefined;
        });
    }

    return () => {
      if (abortPromise) {
        abortPromise();
      }
    };
  }, [dispatch, isAuthenticated, authenticatedUser]);

  return {
    isAuthenticated,
    isValidating,
    authenticatedUser,
    showGif,
  };
};

export default useAuthenticationValidator;
