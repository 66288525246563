class Asset {
  public static MEDIA_METADATA_CONTEXT_RESOURCE = 'resource';

  public static MEDIA_METADATA_CONTEXT_TRAILER = 'trailer';

  public static AVAILABLE_MEDIA_METADATA_CONTEXTS = [
    Asset.MEDIA_METADATA_CONTEXT_RESOURCE,
    Asset.MEDIA_METADATA_CONTEXT_TRAILER,
  ];
}

export default Asset;
