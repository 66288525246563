import React from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../store/store';
import SearchAction from '../store/action/SearchAction';

export interface FilterItemProps {
  position: number;
  categoryId: string;
  context: string;
}

const FilterItem: React.FC<FilterItemProps> = (props) => {
  const {categoryId, position, context} = props;
  const dispatch = useDispatch();

  const category = useSelector(
    (state: RootState) => state.categories.category[categoryId],
  );

  const isCategoryActive = useSelector((state: RootState) => {
    const {
      search: {categoryIds},
    } = state;

    const data = categoryIds[context] || [];

    return data.includes(categoryId);
  });

  const checkCategory = React.useCallback(() => {
    dispatch(SearchAction.saveCategoryId(categoryId, context));
  }, [dispatch, categoryId, context]);

  const unCheckCategory = React.useCallback(() => {
    dispatch(SearchAction.removeCategoryId(categoryId, context));
  }, [dispatch, categoryId, context]);

  return (
    <div
      className="d-flex align-items-baseline w-100 outline-0"
      role="button"
      tabIndex={position}
      onKeyPress={() =>
        isCategoryActive ? unCheckCategory() : checkCategory()
      }
      onClick={() => (isCategoryActive ? unCheckCategory() : checkCategory())}
    >
      <i
        className={classNames('me-3 text-white', {
          'far fa-square': !isCategoryActive,
          'far fa-check-square': isCategoryActive,
        })}
      />
      <span className="text-white">{category.name}</span>
    </div>
  );
};

export default FilterItem;
