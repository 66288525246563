import React from 'react';
import {useParams} from 'react-router-dom';
import useGetTrainerIdFromQuery from '../hook/useGetTrainerIdFromQuery';
import {useGetUserAssetHistoriesQuery} from '../store/service/userAssetHistoryService';
import {GetUserAssetHistoriesParam} from '../utils/ApiInterface';
import Preloader from './Preloader';

function assetHistoryValidator(Component: React.FC): React.FC {
  function AssetHistoryValidator(): JSX.Element {
    const params = useParams<{id: string}>();
    const id = React.useMemo(() => parseInt(params.id, 10), [params.id]);
    const trainerId = useGetTrainerIdFromQuery();
    const userAssetHistoryParams = React.useMemo(() => {
      let query: GetUserAssetHistoriesParam = {'asset.id': [id]};
      if (trainerId) {
        query = {...query, 'asset.parent.id': trainerId};
      }

      return query;
    }, [trainerId, id]);
    const {isLoading} = useGetUserAssetHistoriesQuery(userAssetHistoryParams);

    if (isLoading) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Preloader />
        </div>
      );
    }

    return <Component />;
  }

  const componentName =
    `AssetHistoryValidator${Component.displayName}` ||
    `AssetHistoryValidator${Component.name}` ||
    'AssetHistoryValidatorComponent';
  AssetHistoryValidator.displayName = componentName;

  return AssetHistoryValidator;
}

export default assetHistoryValidator;
