import React from 'react';
import {Button, Navbar, NavbarBrand} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import salesSchool from '../../assets/sales-school.png';
import routeCollection from '../router/routeCollection';

const ErrorMessage: React.FC = (props) => {
  const {t} = useTranslation();
  const {children} = props;

  return (
    <div className="d-flex flex-column">
      <Navbar dark className="header">
        <NavbarBrand
          tag={Link}
          className="navbar-brand order-1 d-flex align-items-center me-2"
          to={routeCollection.home}
        >
          <img src={salesSchool} alt="sales-school" className="header__logo" />
        </NavbarBrand>
      </Navbar>
      <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="mb-4">{children}</div>
        <Button tag="a" href="/" color="dark">
          {t('menu.home')}
        </Button>
      </div>
    </div>
  );
};

export default ErrorMessage;
