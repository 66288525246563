import React from 'react';
import AssetEntity from '../class/Asset';
import AssetModel from '../model/AssetModel';
import {Asset} from '../utils/ApiInterface';
import AssetMedia from './AssetMedia';
import HeroWeb from './HeroWeb';

export interface HeroWebByAssetTypeProps {
  className?: string;
  asset: Asset;
}

const HeroWebByAssetType: React.FC<HeroWebByAssetTypeProps> = (props) => {
  const {children, asset} = props;
  const {type, medias} = asset;

  // Contains `asset.medias` filtered by 'providerMetadata.context' equal to `AssetEntity.MEDIA_METADATA_CONTEXT_TRAILER`.
  const assetTrailers = React.useMemo(() => {
    return medias.filter(
      (media) =>
        AssetEntity.MEDIA_METADATA_CONTEXT_TRAILER ===
        media.providerMetadata.context,
    );
  }, [medias]);

  if (assetTrailers.length || AssetModel.TYPE_VIDEO === type) {
    return (
      <div className="hero hero--web">
        <HeroWeb {...props} className="hero__multimedia" />
        <div className="hero__content">{children}</div>
      </div>
    );
  }

  return (
    <div className="hero hero--web">
      <div className="hero__multimedia">
        <AssetMedia asset={asset} className="h-100" />
      </div>
      <div className="hero__content">{children}</div>
    </div>
  );
};

export default HeroWebByAssetType;
