import {AnyAction} from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import AsyncActionStatus from '../../interface/AsyncActionStatus';
import {ApiPayloadActionInterface} from '../interface';
import ApiType from '../type/ApiType';
import AuthenticationType from '../type/AuthenticationType';

/* eslint-disable @typescript-eslint/no-explicit-any */
// We disable the rule "@typescript-eslint/no-explicit-any" to avoid linter error and because we can have multiple types of definitions for error in requests.

interface RequestStatus extends Omit<ApiPayloadActionInterface, 'label'> {
  status: AsyncActionStatus;
}

interface ApiReducer {
  [key: string]: RequestStatus;
}

export const initialState: ApiReducer = {};

const apiReducer = (state = initialState, action: AnyAction): ApiReducer => {
  switch (action.type) {
    case ApiType.API_INIT: {
      const {label, ...properties} = action.payload;

      return {
        ...state,
        [label]: {status: AsyncActionStatus.PENDING, ...properties},
      };
    }

    case ApiType.API_SUCCESS: {
      const {label} = action.payload;

      return {...state, [label]: {status: AsyncActionStatus.SUCCESS}};
    }

    case ApiType.API_FAILURE: {
      const {label, error} = action.payload;

      return {...state, [label]: {status: AsyncActionStatus.FAILURE, error}};
    }

    case ApiType.API_CANCEL: {
      const {label} = action.payload;

      return {...state, [label]: {status: AsyncActionStatus.CANCEL}};
    }

    case ApiType.API_REMOVE: {
      const {label} = action.payload;

      return {...omit(state, [label])};
    }

    case AuthenticationType.SIGN_OUT: {
      return {...initialState};
    }

    default: {
      return state;
    }
  }
};

export default apiReducer;
