import React from 'react';
import HeroHeader from '../component/HeroHeader';
import Footer from '../component/Footer';
import HeroContent from '../component/HeroContent';
import InfinityScrollCategoryLoad from '../component/InfinityScrollCategoryLoad';
import useGetAssetsByCategoryId from '../hook/useGetAssetsByCategoryId';
import ApiAction from '../store/action/ApiAction';
import useGetIsUserProfileLoaded from '../hook/useGetIsUserProfileLoaded';
import QueryAssetModal from './QueryAssetModal';

const Category = (): JSX.Element => {
  const [parentCategoryIds] = React.useState(['competency', 'sales_channel']);
  const labelToGetLastAssets = React.useMemo(
    () => ApiAction.labelToGetLastAssets(),
    [],
  );
  const assets = useGetAssetsByCategoryId(labelToGetLastAssets);

  const isUserProfileLoaded = useGetIsUserProfileLoaded();

  return (
    <div>
      <QueryAssetModal />
      <HeroHeader assetId={assets[0].id}>
        <HeroContent assetId={assets[0].id} />
      </HeroHeader>

      <div className="page body-main">
        <InfinityScrollCategoryLoad
          showAllCategories
          keyStorage="category"
          isAssetByUserProfile={isUserProfileLoaded}
          parentCategoryIds={parentCategoryIds}
        />
      </div>
      <Footer isAuthenticatedUser />
    </div>
  );
};

export default Category;
