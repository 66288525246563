import React from 'react';
import classNames from 'classnames';
import AssetMedia from './AssetMedia';
import AssetPublicationDate from './AssetPublicationDate';
import AssetTitle from './AssetTitle';
import AssetViewNumber from './AssetViewNumber';
import MultimediaCardDescription from './MultimediaCardDescription';
import AssetTypeIcon from './AssetTypeIcon';
import AssetProgressBar from './AssetProgressBar';
import useUpdateQueryParameters from '../hook/useUpdateQueryParameters';
import {Asset} from '../utils/ApiInterface';

export interface AssetGridItemLayoutProps {
  asset: Asset;
  className?: string;
}

/**
 * Renders the layout of an asset on a card.
 *
 * @todo The component requires adding the icon according to the type of asset on the image.
 */
const AssetGridItemLayout: React.FC<AssetGridItemLayoutProps> = (props) => {
  const {asset, className} = props;
  const {getUrl} = useUpdateQueryParameters();
  const {title, publishStartAt, content, totalViews, type, id: assetId} = asset;

  const assetUrlPath = React.useMemo(
    () => getUrl({asset: assetId.toString()}),
    [assetId, getUrl],
  );

  return (
    <div className={classNames('item-card position-relative', className)}>
      <div className="background-9-16">
        <div className="background-9-16__asset_display">
          <AssetMedia asset={asset} />
        </div>
        <div className="asset-overlaid__icon-type w-100 d-flex justify-content-end px-2">
          <AssetTypeIcon
            type={type}
            showType={false}
            wrapperClassName="p-1 container-icon"
            iconClassName="standard-icon-size--18 detail-page-icon__pdf--big"
          />
        </div>
        <div className="asset-overlaid__progress-bar">
          <AssetProgressBar asset={asset} />
        </div>
      </div>
      <div className="pt-3 pb-4 px-4">
        <div className="mb-2 d-flex justify-content-between align-items-end font-size--small ">
          <AssetPublicationDate
            publishStartAt={publishStartAt}
            iconClassName="icomoon icon-calendar"
            className="me-2"
          />

          <AssetViewNumber totalViews={totalViews} />
        </div>
        <div className="mb-3">
          <AssetTitle isLinked title={title} to={assetUrlPath} />
        </div>

        {content && (
          <MultimediaCardDescription content={content} className="mb-2 pb-2" />
        )}
      </div>
    </div>
  );
};

export default AssetGridItemLayout;
