import React from 'react';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {VideoJsPlayerOptions} from 'video.js';
import VideoPlayer from '../component/VideoPlayer';
import useGetMediaToPlayer from '../hook/useGetMediaToPlayer';
import {AppDispatch} from '../store/store';
import AssetModel from '../model/AssetModel';
import usePlayer from '../hook/usePlayer';
import PlayerStatusAction from '../store/action/PlayerStatusAction';
import trackTimeReducer from '../hook/trackTimeReducer';
import useGetLanguageQueryAsset from '../hook/useGetLanguageQueryAsset';
import useGetTrack from '../hook/useGetTrack';
import useGetAssetImage from '../hook/useGetAssetImage';
import useFilterMediaByLanguage from '../hook/useFilterMediaByLanguage';
import AssetEntity from '../class/Asset';
import {Asset, GetUserAssetHistoriesParam} from '../utils/ApiInterface';
import useHandlerPlayer from '../hook/useHandlerPlayer';
import {useGetUserAssetHistoriesQuery} from '../store/service/userAssetHistoryService';

interface PlayerProps {
  asset: Asset;
  trainer?: Asset;
}

const Player: React.FC<PlayerProps> = (props) => {
  const {asset, trainer} = props;
  const {id: assetId} = asset;
  const dispatch = useDispatch<AppDispatch>();
  const [timeTracked, setTimeTracked] = React.useReducer(trackTimeReducer, 0);

  const identifier = React.useMemo(() => asset.id.toString(), [asset]);

  const languageQueryParameter = useGetLanguageQueryAsset();

  const userAssetHistoryParams = React.useMemo(() => {
    let query: GetUserAssetHistoriesParam = {'asset.id': [assetId]};
    if (trainer) {
      query = {...query, 'asset.parent.id': trainer.id};
    }

    return query;
  }, [trainer, assetId]);
  const {data} = useGetUserAssetHistoriesQuery(userAssetHistoryParams);
  const [history] = data?.['hydra:member'] || [];

  const medias = React.useMemo(() => {
    return asset.medias.filter(
      ({providerMetadata: {context}}) =>
        AssetEntity.MEDIA_METADATA_CONTEXT_RESOURCE === context,
    );
  }, [asset]);
  const media = useFilterMediaByLanguage(medias, languageQueryParameter);
  const sources = useGetMediaToPlayer(media);
  const poster = useGetAssetImage(asset);
  const tracks = useGetTrack(media);

  const option: VideoJsPlayerOptions = React.useMemo(() => {
    return {
      ...media,
      sources,
      poster,
      tracks,
      controls: true,
      bigPlayButton: true,
      muted: false,
      autoplay: true,
    };
  }, [media, poster, sources, tracks]);

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const playerRef = usePlayer(videoRef, option);

  const {updateLastPosition} = useHandlerPlayer(asset, trainer);

  React.useEffect(() => {
    const player = playerRef.current;
    const onReady = () => {
      if (player && history) {
        player.currentTime(history.lastPosition || 0);
      }
    };

    if (player) {
      player.on('ready', onReady);
    }

    return () => {
      if (null !== player) {
        player.off('ready', onReady);
      }
    };
  }, [dispatch, identifier, playerRef, history]);

  React.useEffect(() => {
    const player = playerRef.current;
    const onCanplaythrough = () => {
      if (player) {
        dispatch(PlayerStatusAction.setAssetIsLoaded(identifier, true));
        dispatch(
          PlayerStatusAction.setAssetStatus(identifier, !player.paused()),
        );
        dispatch(
          PlayerStatusAction.setAssetIsMuted(identifier, player.muted()),
        );
      }
    };

    if (player) {
      player.on('canplaythrough', onCanplaythrough);
    }

    return () => {
      if (null !== player) {
        player.off('canplaythrough', onCanplaythrough);
      }
    };
  }, [dispatch, identifier, playerRef]);

  React.useEffect(() => {
    const player = playerRef.current;
    const onTimeupdate = () => {
      if (player) {
        const currentTime = player.currentTime();
        const duration = player.duration();

        if (currentTime && duration) {
          // The second viewed is recorded every 10% elapsed according to the duration of the content.
          const interval = duration * 0.1;
          setTimeTracked({currentTime, interval});
        }
      }
    };

    if (player) {
      player.on('timeupdate', onTimeupdate);
    }

    return () => {
      if (null !== player) {
        player.off('timeupdate', onTimeupdate);
      }
    };
  }, [playerRef]);

  React.useEffect(() => {
    const player = playerRef.current;
    const onEnded = () => {
      // It will redisplay the poster image. it will restart the video on next interaction, but you can save the time stamp and start playing from there if you wish.
      // player.load();
      dispatch(PlayerStatusAction.setAssetStatus(identifier, false));
      dispatch(PlayerStatusAction.setAssetIsEnding(identifier, true));
    };

    if (player) {
      player.on('ended', onEnded);
    }

    return () => {
      if (null !== player) {
        player.off('ended', onEnded);
      }
    };
  }, [dispatch, identifier, playerRef]);

  React.useEffect(() => {
    const player = playerRef.current;
    const onPlay = () => {
      if (player) {
        const currentTime = parseInt(player.currentTime().toFixed(0), 10);
        dispatch(PlayerStatusAction.setAssetStatus(identifier, true));
        updateLastPosition(currentTime);
      }
    };

    if (player) {
      player.on('play', onPlay);
    }

    return () => {
      if (null !== player) {
        player.off('play', onPlay);
      }
    };
  }, [dispatch, identifier, playerRef, updateLastPosition]);

  React.useEffect(() => {
    const player = playerRef.current;
    const onPause = () => {
      if (player) {
        const currentTime = parseInt(player.currentTime().toFixed(0), 10);
        dispatch(PlayerStatusAction.setAssetStatus(identifier, false));
        updateLastPosition(currentTime);
      }
    };

    if (player) {
      player.on('pause', onPause);
    }

    return () => {
      if (null !== player) {
        player.off('pause', onPause);
      }
    };
  }, [dispatch, identifier, playerRef, updateLastPosition]);

  // Update when asset update time.
  React.useEffect(() => {
    let abortPromise: VoidFunction | undefined;
    if (timeTracked) {
      const currentTime = parseInt(timeTracked.toFixed(0), 10);
      updateLastPosition(currentTime);
    }

    return () => {
      if (abortPromise) {
        abortPromise();
      }
    };
  }, [dispatch, timeTracked, assetId, updateLastPosition]);

  return (
    <div
      className={classNames('player-page h-100 w-100', {
        'show-poster': AssetModel.TYPE_AUDIO === asset.type,
      })}
    >
      <VideoPlayer ref={videoRef} />
    </div>
  );
};

export default Player;
