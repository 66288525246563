import createDeepEqualSelector from './createDeepEqualSelector';
import makeGetAuthenticatedUserCategories from './makeGetAuthenticatedUserCategories';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const makeIsUserProfileLoaded = () =>
  createDeepEqualSelector(
    [makeGetAuthenticatedUserCategories()],
    (userData) => {
      const {organizationIds, positionIds, salesChannelIds} = userData;
      return (
        0 < organizationIds.length &&
        0 < positionIds.length &&
        0 < salesChannelIds.length
      );
    },
  );

export default makeIsUserProfileLoaded;
