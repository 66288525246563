/* eslint-disable import/no-cycle */
import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const makeGetAuthenticatedUserCategories = () =>
  createDeepEqualSelector(
    [
      (state: RootState) =>
        state.user.authenticatedUser
          ? state.user.user[state.user.authenticatedUser]
          : undefined,
    ],
    (user) => {
      if (!user) {
        return {organizationIds: [], positionIds: [], salesChannelIds: []};
      }
      const {organizations, positions, salesChannels} = user;

      const getId = (identifier: string) => {
        const data = identifier.split('/');

        return data[data.length - 1];
      };

      return {
        organizationIds: organizations.map(getId),
        positionIds: positions.map(getId),
        salesChannelIds: salesChannels.map(getId),
      };
    },
  );

export default makeGetAuthenticatedUserCategories;
