import React from 'react';
import {useTranslation} from 'react-i18next';
import PdfPageList from '../component/PdfPageList';
import Preloader from '../component/Preloader';

export interface PdfReaderProps {
  url: string;
  assetId: number;
  className?: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */

const PdfReader: React.FC<PdfReaderProps> = (props) => {
  const {t} = useTranslation();
  const {url, className, assetId} = props;
  const [currentUrl, setCurrentUrl] = React.useState<string>(url);
  const [pdfInstance, setPdfInstance] = React.useState<any>();
  const [isInitLoading, setInitLoading] = React.useState<boolean>(true);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [totalNumberPage, setTotalNumberPage] = React.useState(0);

  React.useEffect(() => {
    // The pdf is retrieved asynchronously to be able to interact instance generated by `pdfjsLib`.
    setCurrentUrl(url);
    setInitLoading(true);

    window.pdfjsLib
      .getDocument({url, withCredentials: true})
      .promise.then((pdf: any) => {
        // If the pdf is loaded successfully:
        // * The pdf instance is saved.
        // * The total number of available pages of the pdf is updated.
        // * The flag is updated to indicate that the pdf pages can be rendered.

        setPdfInstance(pdf);
        setTotalNumberPage(pdf.numPages);
        setInitLoading(false);
        setHasError(false);
      })
      .catch(() => {
        // If the pdf isn't loaded successfully:
        // * The `hasError` is updated to indicate that the pdf could not be loaded successfully.
        // * The `isInitLoading` is updated to remove the `Preloader` component and render the error message.

        setHasError(false);
        setInitLoading(false);
        setPdfInstance(undefined);
        setTotalNumberPage(0);
      });
  }, [url]);

  if (url !== currentUrl || isInitLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center my-auto">
        <Preloader />
      </div>
    );
  }

  if (false === hasError && 0 < totalNumberPage) {
    return (
      <PdfPageList
        assetId={assetId}
        className={className}
        totalNumberPage={totalNumberPage}
        pdfInstance={pdfInstance}
      />
    );
  }

  return (
    <div className="d-flex justify-content-center align-items-center my-auto">
      <p>{t('page.player.pdfErrorLoad')}</p>
    </div>
  );
};

export default PdfReader;
