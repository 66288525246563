class QualificationType {
  public static get SAVE_QUALIFICATIONS_BY_ASSET(): string {
    return 'SAVE_QUALIFICATIONS_BY_ASSET';
  }

  public static get SAVE_QUALIFICATION_BY_ASSET(): string {
    return 'SAVE_QUALIFICATION_BY_ASSET';
  }
}

export default QualificationType;
