import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Button,
} from 'reactstrap';
import classNames from 'classnames';
import MenuNavItem from './MenuNavItem';
import routeCollection from '../router/routeCollection';
import salesSchool from '../../assets/sales-school.png';
import LogoutDropdown from './LogoutDropdown';

const Header: React.FC = () => {
  const {t} = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const menuOptions = [
    {to: routeCollection.home, title: t('menu.home'), exact: true},
    {to: routeCollection.favorite, title: t('menu.favorites')},
    {to: routeCollection.category, title: t('menu.categories')},
    {to: routeCollection.history, title: t('menu.history')},
  ];

  return (
    <Navbar
      dark
      expand="md"
      container={false}
      className={classNames('header d-flex justify-content-between', {
        'header--open': isMenuOpen,
      })}
    >
      <NavbarBrand
        tag={Link}
        className="navbar-brand order-1 d-flex align-items-center me-2"
        to={routeCollection.home}
      >
        <img src={salesSchool} alt="sales-school" className="header__logo" />
      </NavbarBrand>
      <Collapse isOpen={isMenuOpen} className="order-2" navbar>
        <Nav className="me-auto" navbar>
          {menuOptions.map((menu) => {
            return (
              <MenuNavItem
                exact
                key={menu.to}
                to={menu.to}
                className="py-1 py-lg-2"
              >
                {menu.title}
              </MenuNavItem>
            );
          })}
        </Nav>
      </Collapse>

      <div className="d-flex align-items-center order-1 order-md-3">
        <Link
          to={routeCollection.search}
          className="text-white text-decoration-none"
        >
          <div className="standard-icon-height standard-icon-height--26">
            <i className="icomoon icon-search px-1 py-2 ms-2" />
          </div>
        </Link>
        {/* <i className="icomoon icon-remember px-2 px-md-3 py-2" /> */}
        <LogoutDropdown className="ms-2" />
        <NavbarToggler
          onClick={toggleMenu}
          tag={Button}
          color="dark"
          className="header__toggler outline-0 border-0 btn--dark"
        >
          <i className="fas fa-bars" />
        </NavbarToggler>
      </div>
    </Navbar>
  );
};

export default Header;
