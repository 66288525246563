import React from 'react';
import classNames from 'classnames';
import {Button} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import AssetMedia from './AssetMedia';
import {Asset} from '../utils/ApiInterface';
import AssetEntity from '../class/Asset';
import PlayerHandler from './PlayerHandler';
import useGetAssetImage from '../hook/useGetAssetImage';
import PlayerStatusAction from '../store/action/PlayerStatusAction';
import {RootState} from '../store/store';
import VideoRePlayButton from './VideoReplayButton';
import VideoPlayButton from './VideoPlayButton';

export interface AssetMediaHeaderProps {
  className?: string;
  asset: Asset;
}

const AssetMediaHeader: React.FC<AssetMediaHeaderProps> = (props) => {
  const {className, asset} = props;
  const {medias} = asset;
  const dispatch = useDispatch();
  const muteButtonRef = React.useRef<HTMLButtonElement>(null);
  const replayButtonRef = React.useRef<HTMLButtonElement>(null);
  const poster = useGetAssetImage(asset);
  const identifier = React.useMemo(() => `header-${asset.id}`, [asset]);

  const isPlaying = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;

    if (undefined === playerStatus[identifier]) {
      return false;
    }

    return playerStatus[identifier].isPlaying;
  });

  const isAssetEnding = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[identifier];

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isEnding;
  });

  const isAssetLoaded = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[identifier];

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isLoaded;
  });

  const isAssetExecutable = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[identifier];

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isExecutable;
  });

  const isMuted = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;

    if (
      undefined === playerStatus[identifier] ||
      !playerStatus[identifier].isExecutable ||
      !playerStatus[identifier].isLoaded
    ) {
      return true;
    }

    return playerStatus[identifier].isMuted;
  });

  // Contains `asset.medias` filtered by 'providerMetadata.context' equal to `AssetEntity.MEDIA_METADATA_CONTEXT_TRAILER`".
  const assetTrailers = React.useMemo(() => {
    return medias.filter(
      (media) =>
        AssetEntity.MEDIA_METADATA_CONTEXT_TRAILER ===
        media.providerMetadata.context,
    );
  }, [medias]);

  React.useEffect(() => {
    return () => {
      dispatch(PlayerStatusAction.removePlayerStatus(identifier));
    };
  }, [dispatch, identifier]);

  React.useEffect(() => {
    const onMuteEvent = () => {
      dispatch(PlayerStatusAction.setAssetIsMuted(identifier, !isMuted));
    };
    const button = muteButtonRef.current;
    let isEventActive = false;

    if (button && isPlaying) {
      isEventActive = true;
      button.addEventListener('click', onMuteEvent);
    }

    return () => {
      if (button && isEventActive) {
        button.removeEventListener('click', onMuteEvent);
      }
    };
  }, [muteButtonRef, isPlaying, dispatch, isMuted, identifier]);

  React.useEffect(() => {
    const onReplayEvent = () => {
      dispatch(PlayerStatusAction.setAssetIsEnding(identifier, false));
    };
    const button = replayButtonRef.current;
    let isEventActive = false;

    if (button && replayButtonRef) {
      isEventActive = true;
      button.addEventListener('click', onReplayEvent);
    }

    return () => {
      if (button && isEventActive) {
        button.removeEventListener('click', onReplayEvent);
      }
    };
  }, [dispatch, identifier, isAssetEnding, replayButtonRef]);

  return (
    <div className={classNames('background-9-16__asset_display', className)}>
      {0 < assetTrailers.length ? (
        <PlayerHandler
          canPlay
          className="h-100"
          medias={assetTrailers}
          identifier={identifier}
          poster={poster}
        />
      ) : (
        <AssetMedia asset={asset} />
      )}
      {isPlaying && (
        <Button
          color="dark"
          className="button_player btn--dark"
          innerRef={muteButtonRef}
        >
          <div className="standard-icon-height standard-icon-height--16">
            <i
              className={classNames(
                'multimedia__size text-white detail-page-icon__favorite line-height',
                {
                  'icomoon icon-volume_off': !isMuted,
                  'icomoon icon-volume_on': isMuted,
                },
              )}
            />
          </div>
        </Button>
      )}
      {isAssetEnding && (
        <VideoRePlayButton
          id={identifier}
          buttonClassName="button_player btn--dark button_player--replay"
          containerClassName="standard-icon-height standard-icon-height--16"
          iconClassName="multimedia__size text-white detail-page-icon__favorite line-height"
        />
      )}
      {!isAssetEnding && !isPlaying && isAssetExecutable && isAssetLoaded && (
        <VideoPlayButton
          id={identifier}
          buttonClassName="button_player btn--dark button_player--play"
          containerClassName="standard-icon-height standard-icon-height--16"
          iconClassName="multimedia__size text-white detail-page-icon__favorite line-height"
        />
      )}
    </div>
  );
};

export default AssetMediaHeader;
