import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';
import {GetAssetParam} from '../utils/ApiInterface';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const makeGetSearchParameter = () =>
  createDeepEqualSelector(
    [(state: RootState) => state.search],
    ({categoryIds, term}) => {
      let query: GetAssetParam = {};

      if (term) {
        query = {...query, search: term};
      }

      Object.keys(categoryIds).forEach((key) => {
        if ('organization' === key) {
          query = {...query, organizationParent: categoryIds[key]};

          return;
        }

        if ('position' === key) {
          query = {...query, positionParent: categoryIds[key]};

          return;
        }

        if ('sales_channel' === key) {
          query = {...query, salesChannelParent: categoryIds[key]};

          return;
        }

        query = {...query, competencyParent: categoryIds[key]};
      });

      return query;
    },
  );

export default makeGetSearchParameter;
