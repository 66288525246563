// QUERY PARAMETERS

export interface Pagination {
  page?: number;
  limit?: number;
  pagination?: boolean;
}

export interface Exists {
  parent?: boolean;
  organizations?: boolean;
}

export interface GetCategoriesParam extends Pagination {
  leafChildOf?: string;
  exists?: Exists;
  'context.id'?: string[];
}

export interface GetFavoritesAssetParam extends Pagination {
  search?: string;
  type?: string;
  'parent.id'?: string;
  'tags.name'?: string;
  'author.id'?: string;
}

export interface PostContactMessageBody {
  name: string;
  subject: string;
  message: string;
  organization: string;
}

export enum OrderOption {
  asc = 'asc',
  desc = 'desc',
}

export interface GetAssetHierarchiesParam extends Pagination {
  'child.id'?: number[];
  'parent.id'?: number[];
  'order[position]'?: OrderOption;
}

export interface GetUserAssetHistoriesParam extends Pagination {
  'asset.id'?: number[];
  'asset.parent.id'?: number;
  'order[lastViewAt]'?: OrderOption;
}

export enum ShowViewedOption {
  inProgress = 'in_progress',
  completed = 'completed',
}

export interface Order {
  totalViews?: OrderOption;
  publishStartAt?: OrderOption;
}

export interface GetAssetParam extends Pagination, Order {
  'author.id'?: string;
  'tags.name'?: string;
  'parent.id'?: number[];
  id?: number[];
  type?: string;
  isPublic?: boolean;
  exists?: Exists;
  salesChannelParent?: string[];
  positionParent?: string[];
  order?: Order;
  organizationParent?: string[];
  countryParent?: string[];
  competencyParent?: string[];
  search?: string;
  showViewed?: ShowViewedOption;
  showOnlyFavorites?: boolean;
  includeActivity?: boolean;
  includeIsFavorite?: boolean;
  showOnlyViewed?: boolean;
}

export interface BodyUpdateLastPositionParam {
  isFinished: boolean;
  lastPosition: number;
  parentAsset?: string;
}

export interface GetUpdateLastPositionParam {
  id: number;
  body: BodyUpdateLastPositionParam;
}

export interface BodyStartReproductionParam {
  parentAsset?: string;
}

export interface GetStartReproductionParam {
  id: number;
  body?: BodyStartReproductionParam;
}

export interface Identity {
  id: number[];
}

export interface GetUserAssetQualificationParam extends Pagination {
  'user.id'?: number[];
  userEmployee?: number | Identity;
  'asset.id'?: number[];
  score?: number[];
  orderCreatedAt?: OrderOption;
  orderScore?: OrderOption;
}

// INTERFACES

export interface HydraView {
  '@id': string;
  '@type': string;
  'hydra:first'?: string;
  'hydra:last'?: string;
  'hydra:next'?: string;
}

export interface HydraMapping {
  '@type': string;
  variable: string;
  property: string;
  required: boolean;
}

export interface HydraSearch {
  '@type': string;
  'hydra:template': string;
  'hydra:variableRepresentation': string;
  'hydra:mapping': HydraMapping[];
}

export interface HydraResponse<T> {
  '@context'?: string;
  '@id'?: string;
  '@type'?: string;
  'hydra:member': T;
  'hydra:totalItems': number;
  'hydra:view'?: HydraView;
  'hydra:search'?: HydraSearch;
}

export interface HydraErrorMessage {
  propertyPath: string;
  message: string;
}

export interface HydraError {
  '@context'?: string;
  '@type'?: string;
  '@hydra:description': string;
  '@hydra:title': string;
  violations: HydraErrorMessage[];
}

export interface HistoryAsset {
  title: string;
  multimediaCollection: number[];
}

export interface ProviderMetadata {
  source_language?: string;
  filename?: string;
  context?: string;
}

export interface Subtitles {
  [key: string]: string;
}

export interface PublicUrls {
  reference: string;
  clip?: string;
  raw_reference?: string;
  subtitles?: Subtitles;
}

export interface Media {
  id?: number;
  name?: string;
  providerReference?: string;
  providerMetadata: ProviderMetadata;
  publicUrls: PublicUrls;
}

export interface Author {
  '@context'?: string;
  '@id'?: string;
  '@type'?: string;
  firstname: string;
  lastname: string;
  id: number;
}

export interface Competency {
  '@id': string;
  '@type': string;
  description: string | null;
  slug: string;
  id: string;
  name: string;
}

export interface ContentAudience {
  '@id'?: string;
  '@type'?: string;
  name?: string | null;
  slug?: string | null;
  description?: string | null;
  id?: string | null;
}

export interface AssetHierarchyApi {
  '@context': string;
  '@id': string;
  '@type': string;
  id: number;
  parent: string;
  child: string;
  position: number;
  createdAt: string;
  updatedAt: string;
}

export interface AssetHierarchy extends AssetHierarchyApi {
  childId: number;
}

export interface Asset {
  '@context'?: string;
  '@id': string;
  '@type': string;
  id: number;
  type: string;
  medias: Media[];
  thumbnail?: Media | null;
  cover?: Media | null;
  children: AssetHierarchy[];
  author?: Competency | null;
  totalViews: number;
  competencies: Competency[];
  contentAudience?: string | null | ContentAudience;
  countries: string[];
  organizations: Competency[];
  positions: [];
  parent?: string | null;
  salesChannels: [];
  external?: boolean;
  duration?: number;
  tags: Record<string, string | number | null>[];
  title: string;
  abstract?: string | null;
  content?: string | null;
  publishStartAt: string;
  publishEndAt?: string | null;
  activity?: {lastViewAt: string};
  url?: string | null;
}

export interface Context {
  id: string;
  name: string;
}

export interface Category {
  '@context'?: string;
  '@id': string;
  '@type': string;
  carouselLimit: number;
  name: string;
  slug: string;
  description?: string | null;
  media?: null;
  position: number;
  id: string;
  context?: Context;
  parent?: null | Category;
  children?: Category[];
}

export interface ContactMessage {
  '@context': string;
  '@id': string;
  '@type': string;
  id: number;
  name: string;
  organization: string;
  subject: string;
  message: string;
  createdAt: string;
  user: string;
}

export interface UserAssetQualification {
  '@context': string;
  '@id': string;
  '@type': string;
  comment: string;
  score: number;
  asset: Pick<Asset, 'title' | 'id'>;
  user: Author;
  createdAt: string;
  updatedAt: string;
}

export interface Avatar {
  id: number;
  name: string;
  providerReference: string;
  publicUrls: PublicUrls;
}

export interface User {
  '@context': string;
  '@id': string;
  '@type': string;
  employeeId: string;
  avatar: Avatar;
  identityProvider: string;
  createdAt: Date;
  updatedAt: Date;
  firstname: string;
  lastname: string;
  locale: string;
  timezone: string;
  id: number;
  username: string;
  email: string;
  enabled: boolean;
  lastLogin: Date;
  competencies: Competency[];
  countries: Competency[];
  organizations: string[];
  positions: string[];
  salesChannels: string[];
}

export interface QualificationBody {
  asset: string;
  score: number;
  comment: string;
}

export interface AssetFavorites {
  '@context'?: string;
  '@id': string;
  '@type': string;
  asset: Pick<Asset, '@id' | 'id' | '@type'>;
}

export interface AssetHistory {
  '@id': string;
  '@type': string;
  context: string;
  asset: Pick<Asset, '@id' | 'id' | '@type'>;
  completedAt: string | null;
  lastViewAt: string | null;
  lastPosition: number;
  parent?: AssetHistory;
}
