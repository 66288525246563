import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import makeGetAuthenticatedUserCategories from '../selector/makeGetAuthenticatedUserCategories';
import {RootState} from '../store/store';

interface UseGetUserCategories {
  organizationIds: string[];
  positionIds: string[];
  salesChannelIds: string[];
}

const useGetUserCategories = (): UseGetUserCategories => {
  const selectUserQualification = useMemo(
    makeGetAuthenticatedUserCategories,
    [],
  );

  const userQualification = useSelector((state: RootState) =>
    selectUserQualification(state),
  );

  return userQualification;
};

export default useGetUserCategories;
