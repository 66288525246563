import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';
import makeGetAuthenticatedUserCategories from './makeGetAuthenticatedUserCategories';
import makeGetCategoryIdsByContext from './makeGetCategoryIdsByContext';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export interface MakeGetCategoryIdsByContextAndUser {
  context: string;
}

// Retorna una colleccion de id de categorias segun el contexto dado y filtrado por el perfil del usuario.
const makeGetCategoryIdsByContextAndUserProfile = () =>
  createDeepEqualSelector(
    [
      makeGetCategoryIdsByContext(),
      makeGetAuthenticatedUserCategories(),
      (state: RootState, props: MakeGetCategoryIdsByContextAndUser) =>
        props.context,
    ],
    (categoryIds, userCategory, context) => {
      const {salesChannelIds} = userCategory;

      if ('sales_channel' === context) {
        return salesChannelIds;
      }

      return categoryIds;
    },
  );

export default makeGetCategoryIdsByContextAndUserProfile;
