import React from 'react';
import {useGetUserAssetHistoriesQuery} from '../store/service/userAssetHistoryService';
import {Asset, GetUserAssetHistoriesParam} from '../utils/ApiInterface';
import useHandlerPlayer from './useHandlerPlayer';

const useCreateReproduction = (asset: Asset, trainer?: Asset): boolean => {
  const [isHistoryLoaded, setIsHistoryLoaded] = React.useState(false);
  const userAssetHistoryParams = React.useMemo(() => {
    let query: GetUserAssetHistoriesParam = {
      'asset.id': [asset.id],
    };
    if (trainer) {
      query = {
        ...query,
        'asset.parent.id': trainer.id,
      };
    }
    return query;
  }, [asset, trainer]);

  const {data = {'hydra:member': []}, isLoading} =
    useGetUserAssetHistoriesQuery(userAssetHistoryParams, {
      skip: isHistoryLoaded,
    });
  const {
    'hydra:member': [history],
  } = data;

  const {startReproduction} = useHandlerPlayer(asset, trainer);

  React.useEffect(() => {
    if (false === isLoading) {
      setIsHistoryLoaded(true);

      if (undefined === history || null !== history.completedAt) {
        startReproduction();
      }
    }
  }, [startReproduction, isLoading, history]);

  return isLoading;
};

export default useCreateReproduction;
