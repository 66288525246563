import {useMemo} from 'react';
import AssetTrainer from '../class/AssetTrainer';
import useGetQueryParameters from './useGetQueryParameters';

const useGetTrainerIdFromQuery = (): number | undefined => {
  const queryParameters = useGetQueryParameters();
  return useMemo((): number | undefined => {
    const queryTrainerId =
      queryParameters[AssetTrainer.URL_QUERY_PARAMETER_KEY];
    if (queryTrainerId && 'string' === typeof queryTrainerId) {
      return parseInt(queryTrainerId, 10);
    }

    return undefined;
  }, [queryParameters]);
};

export default useGetTrainerIdFromQuery;
