import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';
import UrlProcessor from '../utils/UrlProcessor';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
interface MakeGetNextPageProps {
  categoryName: string;
}

const makeGetNextPage = () =>
  createDeepEqualSelector(
    [
      (state: RootState, props: MakeGetNextPageProps) => {
        const {
          assets: {assetsByCategory},
        } = state;
        const {categoryName} = props;

        return assetsByCategory[categoryName];
      },
    ],
    (collectionCategory) => {
      if (
        collectionCategory &&
        collectionCategory['hydra:view'] &&
        collectionCategory['hydra:view']['hydra:next']
      ) {
        const nextParams =
          collectionCategory['hydra:view']['hydra:next'].split('?')[1];
        const queryParams = UrlProcessor.queryToObject(nextParams);

        return queryParams.page
          ? parseInt(queryParams.page as string, 10)
          : undefined;
      }

      return undefined;
    },
  );

export default makeGetNextPage;
