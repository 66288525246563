import React from 'react';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {ModalBody, NavLink, TabContent} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import ClipboardAssetURL from './ClipboardAssetURL';
import InternalAssetLogo from './InternalAssetLogo';
import FavoriteButton from './FavoriteButton';
import LinkToAssetQualification from './LinkToAssetQualification';
import AssetTitle from './AssetTitle';
import AssetPublicationDate from './AssetPublicationDate';
import AssetTypeIcon from './AssetTypeIcon';
import AssetLinkToPlayer from './AssetLinkToPlayer';
import AssetMediaHeader from './AssetMediaHeader';
import MultimediaCardDescription from './MultimediaCardDescription';
import TabHeaderUI from './TabHeaderUI';
import TabPaneUI from './TabPaneUI';
import SuggestAssetList from './SuggestAssetList';
import CommentList from './CommentList';
import AssetLanguageSelect from './AssetLanguageSelect';
import AssetModel from '../model/AssetModel';
import useGetQueryParameters from '../hook/useGetQueryParameters';
import useUpdateQueryParameters from '../hook/useUpdateQueryParameters';
import AssetLayout, {AssetLayoutActionEnum} from '../class/AssetLayout';
import AssetDurationHumanized from './AssetDurationHumanized';
import {AppDispatch} from '../store/store';
import AssetTrainingList from './AssetTrainingList';
import {getQualificationsByAssetId} from '../store/action/userAssetQualification';
import AssetAction from '../store/action/AssetAction';
import AssetAuthor from './AssetAuthor';
import {Asset} from '../utils/ApiInterface';

export interface AssetLayoutModalUIProps {
  asset: Asset;
  activeTab?: AssetLayoutActionEnum;
}

/**
 * Renders the layout of the UI for the detail of an asset on a modal
 */
const AssetLayoutModalUI: React.FC<AssetLayoutModalUIProps> = (props) => {
  const {asset, activeTab = AssetLayoutActionEnum.SUGGEST} = props;
  const {external, title, publishStartAt, type, content} = asset;
  const {duration, id: assetId, author} = asset;
  const {name: authorName = ''} = author || {};

  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const supportDropdownAssetTypes = [
    AssetModel.TYPE_PDF,
    AssetModel.TYPE_VIDEO,
    AssetModel.TYPE_AUDIO,
  ];

  const {updateHistoryBrowser, getUrl} = useUpdateQueryParameters();
  const queryParameters = useGetQueryParameters();

  const toggleTab = React.useCallback(
    (newActiveTab: AssetLayoutActionEnum) => {
      if (activeTab !== newActiveTab) {
        updateHistoryBrowser({
          ...queryParameters,
          [AssetLayout.ASSET_LAYOUT_ACTION]: newActiveTab,
        });
      }
    },
    [activeTab, updateHistoryBrowser, queryParameters],
  );

  const qualificationPath = React.useMemo(() => {
    return getUrl({
      ...queryParameters,
      asset: assetId.toString(),
      action: AssetLayoutActionEnum.QUALIFICATION,
    });
  }, [getUrl, assetId, queryParameters]);

  React.useEffect(() => {
    const promise = dispatch(getQualificationsByAssetId({assetId}));
    let abortPromise: VoidFunction | undefined = promise.abort;

    promise
      .unwrap()
      .then((response) => {
        abortPromise = undefined;
        dispatch(AssetAction.saveQualificationsByAsset(assetId, response));
      })
      .catch(() => {
        abortPromise = undefined;
      });

    return () => {
      if (abortPromise) {
        abortPromise();
      }
    };
  }, [assetId, dispatch]);

  let headerElementTab = [
    <NavLink
      key={AssetLayoutActionEnum.SUGGEST}
      className={classNames('tab-ui__list-link', {
        active: AssetLayoutActionEnum.SUGGEST === activeTab,
      })}
      onClick={() => {
        toggleTab(AssetLayoutActionEnum.SUGGEST);
      }}
    >
      {t('page.detail.tabs.suggest')}
    </NavLink>,
    <NavLink
      key={AssetLayoutActionEnum.RANKING}
      className={classNames('tab-ui__list-link', {
        active: AssetLayoutActionEnum.RANKING === activeTab,
      })}
      onClick={() => {
        toggleTab(AssetLayoutActionEnum.RANKING);
      }}
    >
      {t('page.detail.tabs.ratings')}
    </NavLink>,
  ];

  if (AssetLayoutActionEnum.TRAINING === type) {
    headerElementTab = [
      <NavLink
        key={AssetLayoutActionEnum.TRAINING}
        className={classNames('tab-ui__list-link', {
          active: AssetLayoutActionEnum.TRAINING === activeTab,
        })}
        onClick={() => {
          toggleTab(AssetLayoutActionEnum.TRAINING);
        }}
      >
        {t('page.detail.tabs.training')}
      </NavLink>,
      ...headerElementTab,
    ];
  }

  return (
    <div className="multimedia-card pb-4">
      <div className="background-9-16 modal-border__header">
        <AssetMediaHeader
          asset={asset}
          className="background-9-16__after-shadow"
        />
      </div>

      <ModalBody>
        <div
          className={classNames('d-flex flex-row justify-content-between', {
            'mb-2 pb-1': !external || supportDropdownAssetTypes.includes(type),
          })}
        >
          {!external && <InternalAssetLogo />}
          {supportDropdownAssetTypes.includes(type) && (
            <AssetLanguageSelect direction="down" asset={asset} showIcon />
          )}
        </div>
        <div className="mb-2">
          <AssetTitle title={title} />
        </div>

        <div className="d-flex justify-content-between mt-1 mb-3 pb-2">
          <div className="font-size--small d-flex">
            <AssetPublicationDate
              iconClassName="icomoon icon-calendar"
              publishStartAt={publishStartAt}
              className="me-2"
            />

            <AssetTypeIcon type={type} className="me-2" />

            {duration && 0 < duration ? (
              <AssetDurationHumanized duration={duration} />
            ) : null}
          </div>
        </div>

        <div className="mb-3 pb-2 multimedia-card__action">
          <AssetLinkToPlayer
            assetId={assetId}
            color="dark"
            className="btn--dark multimedia__size d-flex align-items-center justify-content-center"
          >
            <i className="icomoon icon-play me-2" />
            <span>{t('component.playButton')}</span>
          </AssetLinkToPlayer>
        </div>

        {content && (
          <MultimediaCardDescription content={content} className="mb-3 pb-2" />
        )}

        <AssetAuthor className="mb-3 pb-2" authorName={authorName} />

        <div className="d-flex mb-3">
          <FavoriteButton
            assetId={assetId}
            identifier="detail"
            className="border-0 btn--dark button-width"
            textClassName="font-size--regular"
            iconContainerClassName="standard-icon-height standard-icon-height--40 mb-2"
            iconClassName="hero-content-mobile__favorite"
          />

          <LinkToAssetQualification
            assetId={assetId}
            to={qualificationPath}
            className="border-0 btn--dark button-width"
            textClassName="font-size--regular"
            iconContainerClassName="standard-icon-height standard-icon-height--40 mb-2"
            iconClassName="hero-content-mobile__qualify"
          />

          <ClipboardAssetURL assetId={assetId} />
        </div>

        <TabHeaderUI>{headerElementTab}</TabHeaderUI>
        <TabContent activeTab={activeTab}>
          {AssetLayoutActionEnum.TRAINING === type && (
            <TabPaneUI
              tabId={AssetLayoutActionEnum.TRAINING}
              isActive={AssetLayoutActionEnum.TRAINING === activeTab}
            >
              <AssetTrainingList trainerId={assetId} />
            </TabPaneUI>
          )}
          <TabPaneUI
            tabId={AssetLayoutActionEnum.SUGGEST}
            isActive={AssetLayoutActionEnum.SUGGEST === activeTab}
          >
            <div className="detail-page__suggest">
              <div className="detail-page__suggest-list">
                <SuggestAssetList asset={asset} />
              </div>
            </div>
          </TabPaneUI>
          <TabPaneUI
            tabId={AssetLayoutActionEnum.RANKING}
            isActive={AssetLayoutActionEnum.RANKING === activeTab}
          >
            <CommentList assetId={assetId} />
          </TabPaneUI>
        </TabContent>
      </ModalBody>
    </div>
  );
};

export default AssetLayoutModalUI;
