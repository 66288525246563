import React from 'react';
import classNames from 'classnames';

interface DividerProps {
  className?: string;
}

const Divider: React.FC<DividerProps> = (props) => {
  const {children, className} = props;

  return (
    <div className={classNames('divider__container', className)}>
      <div className="divider__border" />
      {children && <div className="divider__content">{children}</div>}
    </div>
  );
};

export default Divider;
