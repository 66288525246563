import {Middleware} from '@reduxjs/toolkit';
import axios from 'axios';
import ApiType from '../type/ApiType';
import AsyncActionStatus from '../../interface/AsyncActionStatus';

/* eslint-disable @typescript-eslint/no-explicit-any */
// We disable the rule "@typescript-eslint/no-explicit-any" to avoid linter error and because we can have multiple types of definitions for response and error in requests.

const apiMiddleware: Middleware = (store) => (dispatch) => (action) => {
  // If the action does not include the "api" key, it is dispatched normally.
  if (!('api' in action)) {
    dispatch(action);

    return;
  }

  const {api} = store.getState();
  const oldRequest = api[action.payload.label];

  // If the request is previously running, it is canceled to run a new request.
  // This cancellation is optional only if the method is included in the request state.
  if (
    oldRequest &&
    AsyncActionStatus.PENDING === oldRequest.status &&
    oldRequest.cancelToken
  ) {
    oldRequest.cancelToken.cancel();
    // The "cancel" action is dispatched to update the request state in the store.
    dispatch({
      type: ApiType.API_CANCEL,
      payload: {label: action.payload.label},
    });
  }

  dispatch(action);

  action.api
    .promise()
    .then((response: any) => {
      if (action.api.onSuccess) {
        dispatch(action.api.onSuccess(response));
      }
      dispatch({
        type: ApiType.API_SUCCESS,
        payload: {label: action.payload.label},
      });
    })
    .catch((error: any) => {
      if (action.api.onFailure) {
        dispatch(action.api.onFailure(error));
      }

      if (!axios.isCancel(error)) {
        dispatch({
          type: ApiType.API_FAILURE,
          payload: {label: action.payload.label, error},
        });
      }
    });
};

export default apiMiddleware;
