import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';
import HeroHeader from '../component/HeroHeader';
import Footer from '../component/Footer';
import HeroContent from '../component/HeroContent';
import {AppDispatch, RootState} from '../store/store';
import ApiAction from '../store/action/ApiAction';
import AssetById from '../component/AssetById';
import AssetResult from '../component/AssetResult';
import QueryAssetModal from './QueryAssetModal';
import AssetAction, {getFavoriteAssets} from '../store/action/AssetAction';
import ApiType from '../store/type/ApiType';

const Favorite = (): JSX.Element => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const categoryName = React.useMemo(
    () => ApiAction.labelToGetFavoriteAssets(),
    [],
  );

  const assetsByFavoritesCategory = useSelector((state: RootState) => {
    const {
      assets: {assetsByCategory},
    } = state;

    return get(assetsByCategory, [categoryName, 'hydra:member'], []);
  });

  const getMoreData = React.useCallback(
    (page = 1) => {
      const promise = dispatch(getFavoriteAssets({page}));
      const label = ApiAction.labelToGetFavoriteAssets();
      dispatch({
        type: ApiType.API_INIT,
        payload: {label},
      });

      promise
        .unwrap()
        .then((response) => {
          dispatch(AssetAction.saveAssetsByCategory(label, response));
          dispatch({
            type: ApiType.API_SUCCESS,
            payload: {label},
          });
        })
        .catch((error) => {
          dispatch({
            type: ApiType.API_FAILURE,
            payload: {label, error},
          });
        });
    },
    [dispatch],
  );

  return (
    <div>
      <QueryAssetModal />
      <HeroHeader
        assetId={
          0 < assetsByFavoritesCategory.length
            ? assetsByFavoritesCategory[0]
            : undefined
        }
      >
        {0 < assetsByFavoritesCategory.length && (
          <HeroContent assetId={assetsByFavoritesCategory[0]} />
        )}
      </HeroHeader>

      <div
        className={classNames('page container_inner', {
          'd-flex align-items-center justify-content-center h-100 mb-0':
            0 === assetsByFavoritesCategory.length,
        })}
      >
        {0 === assetsByFavoritesCategory.length && (
          <div className="text-center">{t('page.favorites.noFavorites')}</div>
        )}

        {1 < assetsByFavoritesCategory.length && (
          <h2 className="carousel-asset__title--web mb-2">
            {t('page.favorites.title')}
          </h2>
        )}

        {1 < assetsByFavoritesCategory.length && (
          <AssetResult getMoreData={getMoreData} categoryName={categoryName}>
            <div className="grid-list">
              {assetsByFavoritesCategory.slice(1).map((assetId) => (
                <div
                  className="grid-list__item"
                  key={`favorite_index_${assetId}`}
                >
                  <AssetById assetId={assetId} />
                </div>
              ))}
            </div>
          </AssetResult>
        )}
      </div>
      <Footer isAuthenticatedUser />
    </div>
  );
};

export default Favorite;
