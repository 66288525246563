import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import videoJs from 'video.js';
import {Media} from '../utils/ApiInterface';

const useGetTrack = (media: Media): videoJs.TextTrackOptions[] => {
  const {t, i18n} = useTranslation();

  return useMemo(() => {
    const {publicUrls, providerMetadata} = media || {};
    const {subtitles = {}} = publicUrls || {};
    const {source_language: sourceLanguage} = providerMetadata || {};
    const assetLanguage = sourceLanguage ? sourceLanguage.split(/[_-]/)[0] : '';

    return Object.keys(subtitles).map((subtitleKey) => {
      const keyTraduction = `languages.${subtitleKey}`;
      const src = subtitles[subtitleKey];
      let trackSelected = false;

      if (
        assetLanguage &&
        i18n.language.toLowerCase() !== assetLanguage.toLowerCase() &&
        subtitleKey.toLowerCase() === i18n.language.toLowerCase()
      ) {
        trackSelected = true;
      }

      return {
        src,
        default: trackSelected,
        srclang: subtitleKey,
        label: t(keyTraduction),
        language: t(keyTraduction),
      };
    });
  }, [media, i18n, t]);
};

export default useGetTrack;
