import React from 'react';
import useGetAsset from '../hook/useGetAsset';
import AssetItemCarousel from './AssetItemCarousel';

export interface AssetByIdProps {
  assetId: number;
}

const AssetById: React.FC<AssetByIdProps> = (props) => {
  const {assetId} = props;

  const asset = useGetAsset(assetId);

  return <AssetItemCarousel asset={asset} />;
};

export default AssetById;
