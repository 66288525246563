const routeCollection = {
  home: '/',
  favorite: '/favorites',
  category: '/categories',
  history: '/history',
  search: '/search',
  detail: '/detail/:id',
  player: '/player/:id',
  contact: '/contact',
  termsCondition: '/terms-conditions',
  logout: '/logout',
};

export default routeCollection;
