import React from 'react';
import {useGetHierarchiesQuery} from '../store/service/assetHierarchyService';
import {useGetAssetsQuery} from '../store/service/assetService';
import {Asset, OrderOption} from '../utils/ApiInterface';

const useGetHierarchies = (assetId: number): [Asset[], boolean] => {
  const {data = {'hydra:member': []}, isFetching} = useGetHierarchiesQuery({
    'parent.id': [assetId],
    'order[position]': OrderOption.asc,
  });

  const trainerIds = React.useMemo(() => {
    const hierarchies = data?.['hydra:member'] || [];

    return hierarchies
      .slice()
      .sort(
        (lastHierarchies, newHierarchies) =>
          lastHierarchies.position - newHierarchies.position,
      )
      .map((hierarchy) => hierarchy.childId);
  }, [data]);

  const {data: assets = {'hydra:member': []}, isFetching: isAssetFetching} =
    useGetAssetsQuery(
      {
        'parent.id': [assetId],
        id: trainerIds,
      },
      {skip: 0 === trainerIds.length},
    );

  return React.useMemo(
    () => [
      trainerIds.reduce<Asset[]>((lastValue, trainerId) => {
        const asset = assets['hydra:member'].find(({id}) => id === trainerId);

        if (asset) {
          return [...lastValue, asset];
        }

        return lastValue;
      }, []),
      isFetching || isAssetFetching,
    ],
    [trainerIds, assets, isAssetFetching, isFetching],
  );
};

export default useGetHierarchies;
