import React from 'react';
import trainerIdValidator from './trainerIdValidator';
import useGetTrainerIdFromQuery from '../hook/useGetTrainerIdFromQuery';

function queryTrainerIdValidator(Component: React.FC): React.FC {
  const ComponentWithTrainerId = trainerIdValidator(Component);

  function QueryTrainerIdValidator(): JSX.Element {
    const trainerId = useGetTrainerIdFromQuery();

    return undefined === trainerId ? (
      <Component />
    ) : (
      <ComponentWithTrainerId assetId={trainerId} />
    );
  }

  const componentName =
    Component.displayName || Component.name || 'queryTrainerIdValidator';
  QueryTrainerIdValidator.displayName = componentName;

  return QueryTrainerIdValidator;
}

export default queryTrainerIdValidator;
