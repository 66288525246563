/* eslint-disable import/no-cycle */
import {Auth} from 'aws-amplify';
import {ThunkAction} from '@reduxjs/toolkit';
import api from '../../utils/api';
import {
  HydraResponse,
  Category,
  GetAssetParam,
  GetUserAssetQualificationParam,
  ContactMessage,
  PostContactMessageBody,
  GetCategoriesParam,
  UserAssetQualification,
  User,
  Asset,
  QualificationBody,
} from '../../utils/ApiInterface';
import CategoryAction from './CategoryAction';
import ApiType from '../type/ApiType';
import {
  ApiActionInterface,
  ApiPayloadActionInterface,
  PayloadInterface,
  ApiInterface,
  AssetsByKeyActionInterface,
} from '../interface';
import AssetAction from './AssetAction';
import {RootState} from '../store';
import UserAction from './UserAction';
import makeGetUserQualificationByAssetId from '../../selector/makeGetUserQualificationByAssetId';

class ApiAction {
  public static removeRequest(
    label: string,
  ): PayloadInterface<ApiPayloadActionInterface> {
    return {
      type: ApiType.API_REMOVE,
      payload: {
        label,
      },
    };
  }

  public static clearRequest(
    label: string,
  ): ThunkAction<
    void,
    RootState,
    undefined,
    PayloadInterface<ApiPayloadActionInterface>
  > {
    return (dispatch, getState) => {
      const {api: apiState} = getState();
      const request = apiState[label];

      if (request && request.cancelToken) {
        request.cancelToken.cancel();
      }

      dispatch(ApiAction.removeRequest(label));
    };
  }

  public static labelToGetCategories(): string {
    return 'API_GET_CATEGORIES';
  }

  public static getCategories(
    categoryParam: GetCategoriesParam = {},
  ): ApiActionInterface<HydraResponse<Category[]>, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToGetCategories(),
      },
      api: {
        promise: () =>
          api.getCategories(categoryParam, {cancelToken: cancelToken.token}),
        onSuccess: (categories) => CategoryAction.saveCategories(categories),
      },
    };
  }

  public static labelToGetCategoriesByContext(context: string): string {
    return `API_GET_CATEGORIES_BY_CONTEXT_${context}`;
  }

  public static getCategoriesByContext(
    context: string,
    categoryParam: GetCategoriesParam = {},
  ): ApiActionInterface<HydraResponse<Category[]>, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToGetCategoriesByContext(context),
      },
      api: {
        promise: () =>
          api.getCategories(categoryParam, {cancelToken: cancelToken.token}),
        onSuccess: (categories) => CategoryAction.saveCategories(categories),
      },
    };
  }

  public static labelToGetFavoriteAssets(): string {
    return 'API_GET_FAVORITE_ASSETS';
  }

  public static labelToPostMarkAsFavorite(id: number): string {
    return `API_POST_MARK_AS_FAVORITE_${id}`;
  }

  public static postMarkAsFavorite(
    id: number,
  ): ApiActionInterface<void, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToPostMarkAsFavorite(id),
      },
      api: {
        promise: () =>
          api.postMarkAsFavorite(id, {cancelToken: cancelToken.token}),
      },
    };
  }

  public static labelToDeleteUnmarkAsFavorite(id: number): string {
    return `API_DELETE_UNMARK_AS_FAVORITE_${id}`;
  }

  public static deleteUnmarkAsFavorite(
    id: number,
  ): ApiActionInterface<void, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToDeleteUnmarkAsFavorite(id),
      },
      api: {
        promise: () =>
          api.deleteUnmarkAsFavorite(id, {cancelToken: cancelToken.token}),
      },
    };
  }

  public static labelToGetAssetsByCategory(name: string): string {
    return `API_GET_ASSETS_BY_CATEGORY_${name}`;
  }

  public static labelToGetMyTrainingPlan(): string {
    return `API_GET_MY_TRAINING_PLANE`;
  }

  public static labelToGetSearchAssets(): string {
    return `API_GET_SEARCH_ASSETS`;
  }

  public static labelToGetLastAssets(): string {
    return `API_GET_LAST_ASSETS`;
  }

  public static labelToGetSuggest(id: number): string {
    return `API_SUGGEST_${id}`;
  }

  public static labelToGetAsset(id: number): string {
    return `API_ASSET_${id}`;
  }

  public static getAsset(
    id: number,
  ): ApiActionInterface<Asset, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToGetAsset(id),
      },
      api: {
        promise: () => api.getAsset(id, {cancelToken: cancelToken.token}),
        onSuccess: (asset) => AssetAction.saveAsset(asset),
      },
    };
  }

  public static labelToPostLogin(): string {
    return 'API_LOGIN';
  }

  public static postLogin(
    email: string,
    password: string,
  ): ApiActionInterface<HydraResponse<void>, ApiPayloadActionInterface> {
    return {
      type: ApiType.API_INIT,
      payload: {
        label: ApiAction.labelToPostLogin(),
      },
      api: {
        promise: () => Auth.signIn(email, password),
      },
    };
  }

  public static labelToPostContactMessage(): string {
    return 'API_POST_CONTACT_MESSAGE';
  }

  public static postContactMessage(
    body: PostContactMessageBody,
  ): ApiActionInterface<ContactMessage, ApiPayloadActionInterface> {
    return {
      type: ApiType.API_INIT,
      payload: {
        label: ApiAction.labelToPostContactMessage(),
      },
      api: {
        promise: () => api.postContactMessage(body),
      },
    };
  }

  public static labelToGetQualificationsByAssetId(assetId: number): string {
    return `API_GET_QUALIFICATION_BY_ASSET_${assetId}`;
  }

  public static getQualificationsByAssetId(
    assetId: number,
    options: Omit<GetUserAssetQualificationParam, 'asset'> = {},
  ): ThunkAction<
    void,
    RootState,
    undefined,
    | ApiActionInterface<
        HydraResponse<UserAssetQualification[]>,
        ApiPayloadActionInterface
      >
    | PayloadInterface<AssetsByKeyActionInterface>
  > {
    return (dispatch) => {
      const label = ApiAction.labelToGetQualificationsByAssetId(assetId);

      dispatch(
        ApiAction.getGenericAssetQualifications(
          {...options, 'asset.id': [assetId]},
          label,
          {
            onSuccess: (qualification) =>
              AssetAction.saveQualificationsByAsset(assetId, qualification),
          },
        ),
      );
    };
  }

  public static labelToGetUserQualificationByAssetId(
    userId: number,
    assetId: number,
  ): string {
    return `API_GET_USER_QUALIFICATION_BY_ASSET_${userId}_${assetId}`;
  }

  public static getUserQualificationByAssetId(
    assetId: number,
    options: Omit<GetUserAssetQualificationParam, 'asset'> = {},
  ): ThunkAction<
    void,
    RootState,
    undefined,
    | ApiActionInterface<
        HydraResponse<UserAssetQualification[]>,
        ApiPayloadActionInterface
      >
    | PayloadInterface<AssetsByKeyActionInterface>
  > {
    return (dispatch, getState) => {
      const {
        user: {authenticatedUser},
      } = getState();
      if (!authenticatedUser) {
        throw new Error(
          '`ApiAction.getUserQualificationByAssetId()` needs an authenticated user.',
        );
      }

      const label = ApiAction.labelToGetUserQualificationByAssetId(
        authenticatedUser,
        assetId,
      );

      dispatch(
        ApiAction.getGenericAssetQualifications(
          {...options, 'asset.id': [assetId], 'user.id': [authenticatedUser]},
          label,
          {
            onSuccess: (qualification) =>
              AssetAction.saveQualificationsByAsset(assetId, qualification),
          },
        ),
      );
    };
  }

  public static getGenericAssetQualifications(
    parameters: GetUserAssetQualificationParam = {},
    label: string,
    callback: Omit<
      ApiInterface<HydraResponse<UserAssetQualification[]>>,
      'promise'
    >,
  ): ApiActionInterface<
    HydraResponse<UserAssetQualification[]>,
    ApiPayloadActionInterface
  > {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label,
      },
      api: {
        promise: () =>
          api.getQualificationsByAsset(parameters, {
            cancelToken: cancelToken.token,
          }),
        ...callback,
      },
    };
  }

  public static labelToGetAuthenticatedUser(): string {
    return `API_GET_AUTHENTICATED_USER`;
  }

  public static getAuthenticatedUser(): ApiActionInterface<
    User,
    ApiPayloadActionInterface
  > {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToGetAuthenticatedUser(),
      },
      api: {
        promise: () => api.getUserProfile({cancelToken: cancelToken.token}),
        onSuccess: (user) => UserAction.saveAuthenticatedUser(user),
      },
    };
  }

  public static labelToPostQualificationByAsset(assetId: number): string {
    return `API_POST_QUALIFICATION_BY_ASSET_${assetId}`;
  }

  public static requestQualification(
    assetId: number,
    body: Omit<QualificationBody, 'asset'>,
  ): ThunkAction<
    void,
    RootState,
    undefined,
    ApiActionInterface<UserAssetQualification, ApiPayloadActionInterface>
  > {
    return (dispatch, getState) => {
      const store = getState();
      const {
        assets: {assets},
      } = store;

      const userQualification = makeGetUserQualificationByAssetId()(store, {
        assetId,
      });
      const label = ApiAction.labelToPostQualificationByAsset(assetId);
      const assetIdentifier = assets[assetId]['@id'];

      if (userQualification) {
        const e = userQualification['@id'].split('/');
        const qualificationId = e[e.length - 1];

        dispatch(
          ApiAction.putQualificationByAsset(label, qualificationId, assetId, {
            ...body,
            asset: assetIdentifier,
          }),
        );
      } else {
        dispatch(
          ApiAction.postQualificationByAsset(label, assetId, {
            ...body,
            asset: assetIdentifier,
          }),
        );
      }
    };
  }

  public static postQualificationByAsset(
    label: string,
    assetId: number,
    body: QualificationBody,
  ): ApiActionInterface<UserAssetQualification, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        label,
        cancelToken,
      },
      api: {
        promise: () =>
          api.postQualificationsByAsset(body, {
            cancelToken: cancelToken.token,
          }),
        onSuccess: (qualification) =>
          AssetAction.saveQualificationByAsset(assetId, qualification),
      },
    };
  }

  public static putQualificationByAsset(
    label: string,
    qualificationId: string,
    assetId: number,
    body: QualificationBody,
  ): ApiActionInterface<UserAssetQualification, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        label,
        cancelToken,
      },
      api: {
        promise: () =>
          api.putQualificationsByAsset(qualificationId, body, {
            cancelToken: cancelToken.token,
          }),
        onSuccess: (qualification) =>
          AssetAction.saveQualificationByAsset(assetId, qualification),
      },
    };
  }

  public static labelToPostUpdateLastPosition(assetId: number): string {
    return `API_POST_UPDATE_LAST_POSITION_${assetId}`;
  }

  public static postUpdateLastPosition(
    assetId: number,
    currentTime: number,
    isFinished = false,
  ): ApiActionInterface<void, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToPostQualificationByAsset(assetId),
      },
      api: {
        promise: () =>
          api.postUpdateLastPosition(assetId, currentTime, isFinished, {
            cancelToken: cancelToken.token,
          }),
      },
    };
  }

  public static labelToPostStartReproduction(assetId: number): string {
    return `API_POST_START_REPRODUCTION_${assetId}`;
  }

  public static postStartReproduction(
    assetId: number,
  ): ApiActionInterface<void, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToPostStartReproduction(assetId),
      },
      api: {
        promise: () =>
          api.postStartReproduction(assetId, {
            cancelToken: cancelToken.token,
          }),
      },
    };
  }

  public static labelToGetHistory(): string {
    return `API_GET_HISTORY`;
  }

  public static getAssetsViewed(
    name: string,
    params: GetAssetParam = {},
  ): ApiActionInterface<HydraResponse<Asset[]>, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToGetHistory(),
      },
      api: {
        promise: () =>
          api.getAssets(
            {...params, includeActivity: true, showOnlyViewed: true},
            {
              cancelToken: cancelToken.token,
            },
          ),
        onSuccess: (data) => {
          return AssetAction.saveAssetByViewed(name, data);
        },
      },
    };
  }

  public static labelToAssetToggleFavorite(assetId: number): string {
    return `API_ASSET_TOGGLE_FAVORITE_${assetId}`;
  }

  public static postAssetMarkAsFavorite(
    assetId: number,
  ): ApiActionInterface<void, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToAssetToggleFavorite(assetId),
      },
      api: {
        promise: () =>
          api.postAssetMarkAsFavorite(assetId, {
            cancelToken: cancelToken.token,
          }),
        onSuccess: () => AssetAction.saveAssetMarkAsFavorite(assetId),
      },
    };
  }

  public static deleteAssetMarkAsFavorite(
    assetId: number,
  ): ApiActionInterface<void, ApiPayloadActionInterface> {
    const cancelToken = api.getCancelToken();

    return {
      type: ApiType.API_INIT,
      payload: {
        cancelToken,
        label: ApiAction.labelToAssetToggleFavorite(assetId),
      },
      api: {
        promise: () =>
          api.deleteUnmarkAsFavorite(assetId, {
            cancelToken: cancelToken.token,
          }),
        onSuccess: () => AssetAction.removeAssetMarkAsFavorite(assetId),
      },
    };
  }
}

export default ApiAction;
