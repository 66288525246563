import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Collapse} from 'reactstrap';
import {useSelector} from 'react-redux';
import FilterFormSearch from './FilterFormSearch';
import UrlProcessor from '../utils/UrlProcessor';
import routeCollection from '../router/routeCollection';
import FilterListByCategory from './FilterListByCategory';
import {RootState} from '../store/store';

export interface FilterSearchProps {
  activateSearch: () => void;
}

const FilterSearch: React.FC<FilterSearchProps> = (props) => {
  const {activateSearch} = props;
  const termSavedInStorage = useSelector(
    (state: RootState) => state.search.term,
  );
  const [parentCategoryIds] = React.useState([
    'competency',
    'sales_channel',
    'organization',
    'position',
  ]);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const history = useHistory();

  const saveSearch = React.useCallback(
    (term: string) => {
      if (termSavedInStorage !== term) {
        const newUrl = UrlProcessor.createPath(
          routeCollection.search,
          {},
          {term: term || termSavedInStorage},
        );

        history.push(newUrl);
      }
    },
    [history, termSavedInStorage],
  );

  return (
    <div className="filter-search">
      <div className="filter-search__header">
        <Button
          onClick={toggleMenu}
          color="dark"
          className="filter-search__button filter-search__button--menu d-block btn--dark d-lg-none"
        >
          <i className="fas fa-bars" />
        </Button>
        <FilterFormSearch
          activateSearch={activateSearch}
          saveSearch={saveSearch}
        />
      </div>
      <div className="filter-search__content">
        <Collapse isOpen={isMenuOpen}>
          {parentCategoryIds.map((category) => {
            return (
              <FilterListByCategory
                key={`search_parent_${category}`}
                context={category}
              />
            );
          })}
        </Collapse>
      </div>
    </div>
  );
};

export default FilterSearch;
