import React from 'react';
import AssetLayoutModalUI from './AssetLayoutModalUI';
import AssetQualificationModalUI from './AssetQualificationModalUI';
import {AssetLayoutActionEnum} from '../class/AssetLayout';
import AssetModel from '../model/AssetModel';
import useGetUserQualificationByAsset from '../hook/useGetUserQualificationByAsset';
import {Asset} from '../utils/ApiInterface';

export interface AssetLayoutModalProps {
  asset: Asset;
  actionLayout?: AssetLayoutActionEnum;
}

/**
 * Renders a layout according to value received in `prop.actionLayout`.
 */
const AssetLayoutModal: React.FC<AssetLayoutModalProps> = (props) => {
  const {asset, actionLayout} = props;
  const {type} = asset;
  useGetUserQualificationByAsset(asset.id);

  const layout = React.useMemo(() => {
    if (
      AssetModel.TYPE_TRAINING_PROGRAM === type &&
      undefined === actionLayout
    ) {
      return AssetLayoutActionEnum.TRAINING;
    }

    return actionLayout || AssetLayoutActionEnum.SUGGEST;
  }, [type, actionLayout]);

  if (actionLayout === AssetLayoutActionEnum.QUALIFICATION) {
    return <AssetQualificationModalUI assetId={asset.id} />;
  }

  return <AssetLayoutModalUI asset={asset} activeTab={layout} />;
};

export default AssetLayoutModal;
