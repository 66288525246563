import React from 'react';
import {Asset, BodyUpdateLastPositionParam} from '../utils/ApiInterface';
import {
  useStartReproductionMutation,
  useUpdateLastPositionMutation,
} from '../store/service/assetService';

export interface UseHandlerPlayer {
  startReproduction: () => void;
  updateLastPosition: (lastPosition: number, isFinished?: boolean) => void;
}

const useHandlerPlayer = (asset: Asset, trainer?: Asset): UseHandlerPlayer => {
  const [postUpdateLastPosition] = useUpdateLastPositionMutation();
  const [postStartReproduction] = useStartReproductionMutation();

  const startReproduction = React.useCallback(() => {
    let body;
    if (trainer) {
      body = {parentAsset: trainer['@id']};
    }

    postStartReproduction({id: asset.id, body});
  }, [asset, trainer, postStartReproduction]);

  const updateLastPosition = React.useCallback(
    (lastPosition: number, isFinished = false) => {
      let body: BodyUpdateLastPositionParam = {lastPosition, isFinished};
      if (trainer) {
        body = {...body, parentAsset: trainer['@id']};
      }

      postUpdateLastPosition({
        id: asset.id,
        body,
      });
    },
    [asset, trainer, postUpdateLastPosition],
  );

  return {
    startReproduction,
    updateLastPosition,
  };
};

export default useHandlerPlayer;
