import React from 'react';
import classNames from 'classnames';
import QualificationValueOption from '../interface/QualificationValueOption';

export interface QualificationOptionProps {
  option: QualificationValueOption;
  className?: string;
}

const QualificationOption: React.FC<QualificationOptionProps> = (props) => {
  const {option, className} = props;

  if (QualificationValueOption.VERY_POOR === option) {
    return <i className={classNames('icomoon icon-star_1', className)} />;
  }

  if (QualificationValueOption.POOR === option) {
    return <i className={classNames('icomoon icon-star_2', className)} />;
  }

  if (QualificationValueOption.REGULAR === option) {
    return <i className={classNames('icomoon icon-star_3', className)} />;
  }

  if (QualificationValueOption.GOOD === option) {
    return <i className={classNames('icomoon icon-star_4', className)} />;
  }

  if (QualificationValueOption.EXCELLENT === option) {
    return <i className={classNames('icomoon icon-star_5', className)} />;
  }

  throw new Error(`The option ${option} is not a valid option.`);
};

export default QualificationOption;
