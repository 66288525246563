import React from 'react';
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';
import AssetItemCarousel from './AssetItemCarousel';
import CarouselComponent from './CarouselComponent';
import {Asset} from '../utils/ApiInterface';

export interface CarouselProps {
  carouselKey: string;
  sectionTitle?: string;
  className?: string;
  slideData: Asset[];
}

const Carousel = (
  props: React.PropsWithChildren<CarouselProps>,
): JSX.Element => {
  const {slideData, sectionTitle, carouselKey, className} = props;

  return (
    <div className="mb-4">
      <div className={classNames('carousel-asset__title', className)}>
        <h2
          className={classNames({
            'carousel-asset__title--web': !isMobile,
            'carousel-asset__title--mobile': isMobile,
          })}
        >
          {sectionTitle}
        </h2>
      </div>
      <CarouselComponent
        slideData={slideData}
        carouselKey={carouselKey}
        Children={AssetItemCarousel}
      />
    </div>
  );
};

export default Carousel;
