import React from 'react';
import Amplify from 'aws-amplify';
import {BrowserRouter} from 'react-router-dom';
import '../styles/style.scss';
import awsConfiguration from './awsConfiguration';
import SwitchRouter from './router/SwitchRouter';
import ReduxConnection from './component/ReduxConnection';

Amplify.configure(awsConfiguration);

function App(): JSX.Element {
  return (
    <ReduxConnection>
      <BrowserRouter>
        <SwitchRouter />
      </BrowserRouter>
    </ReduxConnection>
  );
}

export default App;
