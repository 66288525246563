import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

export interface ImageLoadProps {
  src: string;
  alt: string;
  className?: string;
}

const ImageLoad = React.memo<ImageLoadProps>((props) => {
  const {className, src, alt} = props;
  const [loading, setLoading] = useState(true);
  const [currentSrc, updateSrc] = useState<string>();

  useEffect(() => {
    // start loading original image
    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => {
      // When image is loaded replace the src and set loading to false
      setLoading(false);
      updateSrc(src);
    };
  }, [src]);

  if (!currentSrc) {
    return null;
  }

  return (
    <img
      src={currentSrc}
      style={{
        opacity: loading ? 0.5 : 1,
        transition: 'opacity 0.15s linear',
      }}
      className={classNames(className)}
      alt={alt}
    />
  );
});

export default ImageLoad;
