import React from 'react';
import {Provider} from 'react-redux';
import {store} from '../store/store';

const ReduxConnection: React.FC = (props): JSX.Element => {
  const {children} = props;

  return <Provider store={store}>{children}</Provider>;
};

export default ReduxConnection;
