import React from 'react';
import Login from '../page/Login';
import Preloader from '../component/Preloader';
import loginGif from '../../assets/login-gif.gif';
import loginGifBackground from '../../assets/login-gif-background.png';
import useAuthenticationValidator from '../hook/useAuthenticationValidator';

function authenticationValidatorHOC(Component: React.FC): React.FC {
  function AuthenticationValidatorHOC(): JSX.Element {
    const {isAuthenticated, isValidating, authenticatedUser, showGif} =
      useAuthenticationValidator();
    const [isGifEnding, setIsGifEnding] = React.useState(false);

    React.useEffect(() => {
      let timeoutId: NodeJS.Timeout;
      if (showGif) {
        timeoutId = setTimeout(() => {
          setIsGifEnding(true);
        }, 5000);
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [showGif]);

    if (showGif && false === isGifEnding) {
      return (
        <div className="login-page">
          <img
            src={loginGifBackground}
            alt="login gif background"
            className="login-page__gif-background"
          />
          <img src={loginGif} alt="login gif" className="login-page__gif" />
        </div>
      );
    }

    if (isValidating || (isAuthenticated && undefined === authenticatedUser)) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Preloader />
        </div>
      );
    }

    if (isAuthenticated && authenticatedUser) {
      return <Component />;
    }

    return (
      <>
        <link rel="preload" href={loginGif} as="image" />
        <link rel="preload" href={loginGifBackground} as="image" />
        <Login />
      </>
    );
  }

  const componentName = Component.displayName || Component.name || 'component';
  AuthenticationValidatorHOC.displayName = componentName;

  return AuthenticationValidatorHOC;
}

export default authenticationValidatorHOC;
