import React from 'react';
import InfinityScroll from './InfinityScroll';
import CategoriesByContext from './CategoriesByContext';
import ApiAction from '../store/action/ApiAction';
import useIsThereAnActiveRequest from '../hook/useIsThereAnActiveRequest';
import useGetCategoryIdByContext from '../hook/useGetCategoryIdByContext';

export interface InfinityScrollCategoryLoadProps {
  parentCategoryIds: string[];
  isAssetByUserProfile?: boolean;
  showAllCategories?: boolean;
  keyStorage?: string;
}

const InfinityScrollCategoryLoad: React.FC<InfinityScrollCategoryLoadProps> = (
  props,
) => {
  const {
    parentCategoryIds,
    isAssetByUserProfile,
    showAllCategories,
    keyStorage = undefined,
  } = props;

  const [page, setPage] = React.useState<number>(1);

  const categoryIds = useGetCategoryIdByContext(
    parentCategoryIds[page - 1] || '',
    // `undefined` is passed as argument 2 to display all categories.
    showAllCategories ? undefined : isAssetByUserProfile,
  );

  const labelCategoryIds = React.useMemo(() => {
    return categoryIds.map((apiId) => {
      return ApiAction.labelToGetAssetsByCategory(
        keyStorage ? `${keyStorage}_${apiId}` : apiId,
      );
    });
  }, [categoryIds, keyStorage]);

  const isThereAnActiveRequest = useIsThereAnActiveRequest(labelCategoryIds);

  React.useEffect(() => {
    const condition = 0 < page && !isThereAnActiveRequest;
    if (
      0 < labelCategoryIds.length &&
      false === condition &&
      page < parentCategoryIds.length
    ) {
      setPage(page + 1);
    }
  }, [page, isThereAnActiveRequest, labelCategoryIds, parentCategoryIds]);

  const fetchAllAssetsByCategoryIds = React.useCallback(() => {
    setPage((oldPage) => oldPage + 1);
  }, []);

  return (
    <InfinityScroll
      hasNextPage={page < parentCategoryIds.length}
      isLoading={0 < page && !isThereAnActiveRequest}
      onLoadMore={fetchAllAssetsByCategoryIds}
      paginatorClassName="container_inner mb-4"
    >
      {0 < page &&
        parentCategoryIds
          .slice(0, page)
          .map((categoryName) => (
            <CategoriesByContext
              context={categoryName}
              keyStorage={keyStorage}
              showCategoryByProfile={!showAllCategories}
              isAssetByUserProfile={isAssetByUserProfile}
              key={`category-name-${categoryName}`}
            />
          ))}
    </InfinityScroll>
  );
};

export default InfinityScrollCategoryLoad;
