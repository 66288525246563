class AssetType {
  public static get SAVE_ASSETS_BY_CATEGORY(): string {
    return 'SAVE_ASSETS_BY_CATEGORY';
  }

  public static get SAVE_ASSETS_BY_SEARCH(): string {
    return 'SAVE_ASSETS_BY_SEARCH';
  }

  public static get SAVE_ASSET_BY_VIEWED(): string {
    return 'SAVE_ASSETS_BY_VIEWED';
  }

  public static get SAVE_ASSET(): string {
    return 'SAVE_ASSET';
  }

  public static get SAVE_SUGGESTED_ASSETS(): string {
    return 'SAVE_SUGGESTED_ASSETS';
  }

  public static get SAVE_FAVORITE_ASSETS(): string {
    return 'SAVE_FAVORITE_ASSETS';
  }

  public static get SAVE_ASSET_BY_OPTION(): string {
    return 'SAVE_ASSET_BY_OPTION';
  }

  public static get REMOVE_ASSET_BY_OPTION(): string {
    return 'REMOVE_ASSET_BY_OPTION';
  }

  public static get SAVE_ASSET_MARK_AS_FAVORITE(): string {
    return 'SAVE_ASSET_MARK_AS_FAVORITE';
  }

  public static get REMOVE_ASSET_MARK_AS_FAVORITE(): string {
    return 'REMOVE_ASSET_MARK_AS_FAVORITE';
  }
}

export default AssetType;
