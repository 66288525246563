import React from 'react';
import {Asset} from '../utils/ApiInterface';
import AssetMedia from './AssetMedia';
import AssetTypeIcon from './AssetTypeIcon';
import MultimediaCardDescription from './MultimediaCardDescription';

export interface PreviewNextAssetDetailProps {
  asset: Asset;
}

const PreviewNextAssetDetail: React.FC<PreviewNextAssetDetailProps> = (
  props,
) => {
  const {asset} = props;

  return (
    <div className="player-layout__next-asset">
      <div className="player-layout__next-asset__image mb-2">
        <p className="m-0 mb-2">{asset.title}</p>
        <div className="background-9-16">
          <div className="background-9-16__asset_display">
            <AssetMedia asset={asset} />
          </div>
          <div className="asset-overlaid__title">
            <div className="d-flex justify-content-between align-items-start px-2">
              <div className="asset-list__container ms-auto">
                <AssetTypeIcon
                  type={asset.type}
                  showType={false}
                  iconClassName="m-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {asset.content && (
        <MultimediaCardDescription
          content={asset.content}
          className="mb-2 pb-2"
        />
      )}
    </div>
  );
};

export default PreviewNextAssetDetail;
