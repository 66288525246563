import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, UncontrolledTooltip} from 'reactstrap';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import useGetRequestStatus from '../hook/useGetRequestStatus';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import ApiAction from '../store/action/ApiAction';
import {AppDispatch, RootState} from '../store/store';
import {getAssetFavorite} from '../store/action/asset';

export interface FavoriteButtonProps {
  assetId: number;
  identifier: string;
  className?: string;
  iconClassName?: string;
  textClassName?: string;
  iconContainerClassName?: string;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const {t} = useTranslation();
  const {
    assetId,
    iconClassName,
    iconContainerClassName,
    textClassName,
    className,
    identifier,
  } = props;

  const [isFetching, setIsFetching] = React.useState(false);
  const isAssetFavorite = useSelector(
    (state: RootState): boolean | undefined => state.assetFavorite[assetId],
  );

  const requestStatus = useGetRequestStatus(
    ApiAction.labelToAssetToggleFavorite(assetId),
  );

  const markAsFavorite = React.useCallback(() => {
    if (AsyncActionStatus.PENDING !== requestStatus) {
      dispatch(ApiAction.postAssetMarkAsFavorite(assetId));
    }
  }, [dispatch, assetId, requestStatus]);

  const removeMarkAsFavorite = React.useCallback(() => {
    if (AsyncActionStatus.PENDING !== requestStatus) {
      dispatch(ApiAction.deleteAssetMarkAsFavorite(assetId));
    }
  }, [dispatch, assetId, requestStatus]);

  React.useEffect(() => {
    setIsFetching(true);
    const promise = dispatch(getAssetFavorite({assetId}));
    promise.finally(() => {
      setIsFetching(false);
    });

    return () => {
      promise.abort();
    };
  }, [dispatch, assetId]);

  if (undefined === isAssetFavorite) {
    return null;
  }

  return (
    <Button
      disabled={isFetching}
      color="dark"
      className={classNames(className)}
      onClick={isAssetFavorite ? removeMarkAsFavorite : markAsFavorite}
      id={`favorite_${assetId}_${identifier}`}
    >
      <div className={classNames(iconContainerClassName)}>
        <i
          className={classNames(iconClassName, {
            'icomoon icon-favorite_on': isAssetFavorite,
            'icomoon icon-favorite_off': !isAssetFavorite,
          })}
        />
      </div>
      <span className={classNames(textClassName)}>
        {t('page.home.myFavorites')}
      </span>
      <UncontrolledTooltip target={`favorite_${assetId}_${identifier}`}>
        {isAssetFavorite
          ? t('component.UnfavoriteButtonShore')
          : t('component.favoriteButtonShore')}
      </UncontrolledTooltip>
    </Button>
  );
};

export default FavoriteButton;
