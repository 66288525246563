/* eslint-disable import/no-cycle */
import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import reducers from './reducers';
import apiMiddleware from './middleware/apiMiddleware';
import apiService from './service/apiService';

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiMiddleware).concat(apiService.middleware),
  preloadedState: {},
  devTools: 'production' !== process.env.NODE_ENV,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export interface ThunkConfiguration {
  state: RootState;
  dispatch: AppDispatch;
}
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
