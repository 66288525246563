import React from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {Button} from 'reactstrap';
import RateQualification from './RateQualification';
import {AppDispatch} from '../store/store';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import {HydraError, HydraErrorMessage} from '../utils/ApiInterface';
import FormMessage from './FormMessage';
import AssetAction, {requestQualification} from '../store/action/AssetAction';

export interface QualificationBody {
  score: string;
  comment: string;
}

export interface QualificationFormProps {
  assetId: number;
  onSuccessCallback?: () => void;
  onCancelCallback?: () => void;
  formData?: QualificationBody;
}

const QualificationForm: React.FC<QualificationFormProps> = (props) => {
  const {assetId, onSuccessCallback, onCancelCallback, formData} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const {register, handleSubmit, errors} = useForm<QualificationBody>({
    defaultValues: {...formData},
  });

  const [requestStatus, setRequestStatus] = React.useState(
    AsyncActionStatus.NOT_EXECUTED,
  );
  const [errorRequest, setErrorRequest] = React.useState<
    HydraErrorMessage | undefined
  >(undefined);

  const saveQualification = React.useCallback(
    (data: QualificationBody) => {
      if (AsyncActionStatus.PENDING === requestStatus) {
        return;
      }

      const promise = dispatch(
        requestQualification({
          assetId,
          body: {
            ...data,
            score: parseInt(data.score, 10),
          },
        }),
      );
      promise
        .unwrap()
        .then((qualification) => {
          setRequestStatus(AsyncActionStatus.SUCCESS);
          dispatch(
            AssetAction.saveQualificationByAsset(assetId, qualification),
          );
        })
        .catch((error) => {
          setRequestStatus(AsyncActionStatus.FAILURE);
          if (error) {
            /** {@todo Improve the promise definition so that the returned error is of type `HydraError`}. */
            const {violations = []} = error as HydraError;

            setErrorRequest(violations[0]);
          }
        });
    },

    [dispatch, assetId, requestStatus],
  );

  React.useEffect(() => {
    if (AsyncActionStatus.SUCCESS === requestStatus && onSuccessCallback) {
      onSuccessCallback();
    }
  }, [requestStatus, onSuccessCallback]);

  return (
    <div className="survey">
      <h5 className="survey__title mb-4 mt-5">
        {t('qualification.titleQualification')}
      </h5>
      <form
        className="survey__rate-section"
        onSubmit={handleSubmit(saveQualification)}
      >
        <div className="mb-5">
          <RateQualification
            register={register}
            name="score"
            rules={{
              required: {
                value: true,
                message: t('form.rate.error.score.required'),
              },
            }}
            isDisabled={AsyncActionStatus.PENDING === requestStatus}
          />
        </div>
        <div className="mb-3">
          <label className="survey__comment-section" htmlFor="commentBox">
            <p className="font-size--regular text-center">
              {t('form.qualification.commentInput')}
            </p>
            <textarea
              name="comment"
              id="commentBox"
              className="rounded outline-0 font-size--regular"
              ref={register({required: false})}
              autoComplete="off"
              disabled={AsyncActionStatus.PENDING === requestStatus}
              rows={4}
            />
          </label>
        </div>

        <div className="d-block d-lg-block justify-content-between align-items-center">
          {errors && errors.score && (
            <FormMessage isError className="mb-3 mb-lg-0 font-size--regular">
              {errors.score.message}
            </FormMessage>
          )}
          {undefined !== errorRequest && errorRequest.propertyPath && (
            <FormMessage isError className="mb-3 mb-lg-0 font-size--regular">
              {t(`form.qualification.error.${errorRequest.propertyPath}`, {
                defaultValue: errorRequest.message,
              })}
            </FormMessage>
          )}

          <div className="ms-auto ps-4 pt-2 text-center">
            {onCancelCallback && (
              <Button
                color="dark"
                className="btn--dark me-2"
                onClick={onCancelCallback}
              >
                {t('form.qualification.cancelButton')}
              </Button>
            )}
            <Button color="dark" className="btn--dark me-2" type="submit">
              {t('form.qualification.submitButton')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QualificationForm;
