import {VideoJsPlayerOptions} from 'video.js';

const mainVideoSetting: VideoJsPlayerOptions = {
  controls: false,
  preload: 'auto',
  muted: true,
  html5: {
    nativeTextTracks: false,
    vhs: {
      withCredentials: true,
    },
  },
  bigPlayButton: true,
  controlBar: {
    pictureInPictureToggle: false,
    remainingTimeDisplay: false,
  },
};

export default mainVideoSetting;
