import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import UrlProcessor from '../utils/UrlProcessor';
import routeCollection from '../router/routeCollection';
import AssetLayout from '../class/AssetLayout';
import useGetQueryParameters from '../hook/useGetQueryParameters';
import HomeHOC from './HomeHOC';
import AssetModal from '../component/AssetModal';

const Detail: React.FC = () => {
  const {id: identifier} = useParams<{id: string}>();
  const id = React.useMemo(() => parseInt(identifier, 10), [identifier]);
  const {push} = useHistory();

  const queryParameters = useGetQueryParameters();

  const action = React.useMemo(() => {
    const actionLayoutQueryParameter =
      queryParameters[AssetLayout.ASSET_LAYOUT_ACTION];
    if (
      actionLayoutQueryParameter &&
      'string' === typeof actionLayoutQueryParameter
    ) {
      try {
        return AssetLayout.getAssetLayoutAction(actionLayoutQueryParameter);
      } catch (error) {
        return undefined;
      }
    }

    return undefined;
  }, [queryParameters]);

  const closeModal = () => {
    push(UrlProcessor.createPath(routeCollection.home));
  };

  return (
    <>
      <AssetModal assetId={id} closeModal={closeModal} actionLayout={action} />
      <HomeHOC />
    </>
  );
};

export default Detail;
