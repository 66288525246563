import moment from 'moment';
import {Asset, HistoryAsset} from './ApiInterface';

const AssetGroupedByDate = (assets: Asset[]): HistoryAsset[] => {
  const assetsGroupByDate: {[key: string]: number[]} = {};
  assets.forEach((asset: Asset) => {
    const {id} = asset;
    const dateAsset = asset?.activity?.lastViewAt
      ? moment(asset.activity.lastViewAt).format('MMM DD, YYYY')
      : moment().format('MMM DD, YYYY');

    if (assetsGroupByDate[dateAsset]) {
      assetsGroupByDate[dateAsset] = [...assetsGroupByDate[dateAsset], id];
    } else {
      assetsGroupByDate[dateAsset] = [id];
    }
  });

  const historyArray = Object.keys(assetsGroupByDate).map((key) => {
    return {
      title: key,
      multimediaCollection: assetsGroupByDate[key],
    };
  });

  return historyArray;
};

export default AssetGroupedByDate;
