import React from 'react';
import {useForm} from 'react-hook-form';
import {Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import InputGroup from './InputGroup';

export interface AuthenticationForm {
  associate: string;
  password: string;
}

export interface LoginFormProps {
  isLoading: boolean;
  authenticationErrorMessage?: string;
  onSubmit: (data: AuthenticationForm) => void;
}

const LoginForm = (
  props: React.PropsWithChildren<LoginFormProps>,
): JSX.Element => {
  const {t} = useTranslation();

  const {handleSubmit, register, watch, errors} = useForm<AuthenticationForm>({
    defaultValues: {
      associate: '',
      password: '',
    },
  });
  const {onSubmit, isLoading, authenticationErrorMessage} = props;
  const {associate, password} = watch(['associate', 'password']);

  return (
    <form
      className="d-flex flex-column form-data"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-2 pb-2">
        <InputGroup
          disabled={isLoading}
          hasValue={0 < associate?.length}
          errorMessage={errors?.associate?.message}
          label={t('form.login.userNamePlaceholder')}
          id="associate-id"
          name="associate"
          iconClassName="icomoon icon-user me-2"
          ref={register({
            required: {
              message: t('form.login.error.isFieldRequired'),
              value: true,
            },
          })}
        />
      </div>

      <div className="mb-2 pb-2">
        <InputGroup
          disabled={isLoading}
          errorMessage={errors?.password?.message}
          hasValue={0 < password?.length}
          label={t('form.login.passwordPlaceholder')}
          id="password"
          name="password"
          type="password"
          iconClassName="icomoon icon-password me-2"
          ref={register({
            required: {
              message: t('form.login.error.isFieldRequired'),
              value: true,
            },
          })}
        />
      </div>

      {authenticationErrorMessage && (
        <div className="mb-3 pb-2 form-data__label">
          {authenticationErrorMessage}
        </div>
      )}

      <div className="mb-2 pb-2">
        <Button
          color="dark"
          type="submit"
          className="form-data__button w-100 btn--dark"
          disabled={isLoading}
        >
          {t('form.login.submitButton')}
        </Button>
      </div>

      {/* <Button
        disabled
        color="transparent"
        tag={Link}
        to="/"
        className="form-data__label"
      >
        {t('form.login.forgotPassword')}
      </Button> */}
    </form>
  );
};

export default LoginForm;
