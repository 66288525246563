/* eslint-disable import/no-cycle */
import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  HydraResponse,
  Asset,
  UserAssetQualification,
  QualificationBody,
  GetAssetParam,
  GetFavoritesAssetParam,
} from '../../utils/ApiInterface';
import {
  PayloadInterface,
  AssetsByKeyActionInterface,
  AssetByKeyActionInterface,
  AssetActionInterface,
  QualificationsByAssetActionInterface,
  AssetsHistoryInterface,
  QualificationByAssetActionInterface,
  AssetMarkAsFavoritesAction,
  AssetUnmarkAsFavoritesAction,
} from '../interface';
import AssetType from '../type/AssetType';
import QualificationType from '../type/QualificationType';
import api from '../../utils/api';
import {RootState} from '../store';
import makeGetUserQualificationByAssetId from '../../selector/makeGetUserQualificationByAssetId';
import makeGetAuthenticatedUserCategories from '../../selector/makeGetAuthenticatedUserCategories';
import AsyncActionStatus from '../../interface/AsyncActionStatus';
import ApiType from '../type/ApiType';
import ApiAction from './ApiAction';

class AssetAction {
  public static saveAssetsByCategory(
    categoryId: string,
    assets: HydraResponse<Asset[]>,
  ): PayloadInterface<AssetsByKeyActionInterface> {
    return {
      type: AssetType.SAVE_ASSETS_BY_CATEGORY,
      payload: {key: categoryId, assets},
    };
  }

  public static saveAssetsBySearch(
    categoryId: string,
    assets: HydraResponse<Asset[]>,
  ): PayloadInterface<AssetsByKeyActionInterface> {
    return {
      type: AssetType.SAVE_ASSETS_BY_SEARCH,
      payload: {key: categoryId, assets},
    };
  }

  public static saveAsset(
    asset: Asset,
  ): PayloadInterface<AssetActionInterface> {
    return {
      type: AssetType.SAVE_ASSET,
      payload: {asset},
    };
  }

  public static saveSuggestedAsset(
    assetId: string,
    assets: HydraResponse<Asset[]>,
  ): PayloadInterface<AssetsByKeyActionInterface> {
    return {
      type: AssetType.SAVE_SUGGESTED_ASSETS,
      payload: {key: assetId, assets},
    };
  }

  public static saveFavoriteAssets(
    categoryId: string,
    assets: HydraResponse<Asset[]>,
  ): PayloadInterface<AssetsByKeyActionInterface> {
    return {
      type: AssetType.SAVE_FAVORITE_ASSETS,
      payload: {key: categoryId, assets},
    };
  }

  public static saveAssetByOption(
    name: string,
    asset: Asset,
  ): PayloadInterface<AssetByKeyActionInterface> {
    return {
      type: AssetType.SAVE_ASSET_BY_OPTION,
      payload: {asset, key: name},
    };
  }

  public static saveQualificationsByAsset(
    assetId: number,
    qualification: HydraResponse<UserAssetQualification[]>,
  ): PayloadInterface<QualificationsByAssetActionInterface> {
    return {
      type: QualificationType.SAVE_QUALIFICATIONS_BY_ASSET,
      payload: {assetId, qualification},
    };
  }

  public static saveQualificationByAsset(
    assetId: number,
    qualification: UserAssetQualification,
  ): PayloadInterface<QualificationByAssetActionInterface> {
    return {
      type: QualificationType.SAVE_QUALIFICATION_BY_ASSET,
      payload: {assetId, qualification},
    };
  }

  public static saveAssetByViewed(
    name: string,
    asset: HydraResponse<Asset[]>,
  ): PayloadInterface<AssetsHistoryInterface> {
    return {
      type: AssetType.SAVE_ASSET_BY_VIEWED,
      payload: {asset, key: name},
    };
  }

  public static saveAssetMarkAsFavorite(
    assetId: number,
  ): PayloadInterface<AssetMarkAsFavoritesAction> {
    return {
      type: AssetType.SAVE_ASSET_MARK_AS_FAVORITE,
      payload: {assetId},
    };
  }

  public static removeAssetMarkAsFavorite(
    assetId: number,
  ): PayloadInterface<AssetUnmarkAsFavoritesAction> {
    return {
      type: AssetType.REMOVE_ASSET_MARK_AS_FAVORITE,
      payload: {assetId},
    };
  }
}

export const getAssetDetail = createAsyncThunk<Asset, {assetId: number}>(
  'asset/getOne',
  async ({assetId}, {signal}) => {
    const {cancel, token} = api.getCancelToken();
    signal.addEventListener('abort', () => {
      cancel();
    });

    return await api.getAsset(assetId, {cancelToken: token});
  },
);

export const requestQualification = createAsyncThunk<
  UserAssetQualification,
  {assetId: number; body: Omit<QualificationBody, 'asset'>},
  {state: RootState}
>('asset/assetQualification', async ({assetId, body}, {signal, getState}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  const store = getState();
  const {
    assets: {assets},
  } = store;

  const userQualification = makeGetUserQualificationByAssetId()(store, {
    assetId,
  });
  const assetIdentifier = assets[assetId]['@id'];

  if (userQualification) {
    const identifier = userQualification['@id'].split('/');
    const qualificationId = identifier[identifier.length - 1];
    return await api.putQualificationsByAsset(
      qualificationId,
      {
        ...body,
        asset: assetIdentifier,
      },
      {
        cancelToken: token,
      },
    );
  }
  return await api.postQualificationsByAsset(
    {
      ...body,
      asset: assetIdentifier,
    },
    {
      cancelToken: token,
    },
  );
});

export const getMyTrainingPlan = createAsyncThunk<
  HydraResponse<Asset[]>,
  void,
  {state: RootState}
>('asset/getMyTrainingPlan', async (argument, {getState, signal}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  const state = getState();
  const {
    user: {authenticatedUser},
  } = state;

  if (!authenticatedUser) {
    throw new Error('`getMyTrainingPlan()` needs an authenticated user.');
  }

  const userCategories = makeGetAuthenticatedUserCategories()(state);
  const {
    organizationIds = [],
    positionIds = [],
    salesChannelIds = [],
  } = userCategories;
  let queryParams: GetAssetParam = {isPublic: false};

  if (0 < salesChannelIds.length) {
    queryParams = {...queryParams, salesChannelParent: salesChannelIds};
  }

  if (0 < organizationIds.length) {
    queryParams = {...queryParams, organizationParent: organizationIds};
  }

  if (0 < positionIds.length) {
    queryParams = {...queryParams, positionParent: positionIds};
  }

  return await api.getAssets({...queryParams}, {cancelToken: token});
});

export const getLastAssets = createAsyncThunk<
  HydraResponse<Asset[]>,
  GetAssetParam,
  {state: RootState}
>('asset/getLastAssets', async (parameters, {signal}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  return await api.getAssets(
    {
      ...parameters,
      exists: {organizations: false},
    },
    {cancelToken: token},
  );
});

export const getFavoriteAssets = createAsyncThunk<
  HydraResponse<Asset[]>,
  GetFavoritesAssetParam
>('asset/getFavoriteAssets', async (parameters = {}, {signal}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  return await api.getFavoriteAssets(parameters, {cancelToken: token});
});

export const getSuggest = createAsyncThunk<
  HydraResponse<Asset[]>,
  GetAssetParam,
  {state: RootState}
>('asset/getSuggest', async (parameters, {signal}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  return await api.getAssets(parameters, {cancelToken: token});
});

export const getAssetsByCategory = createAsyncThunk<
  HydraResponse<Asset[]>,
  GetAssetParam,
  {state: RootState}
>('asset/getAssetsByCategory', async (parameters, {signal}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  return await api.getAssets({...parameters}, {cancelToken: token});
});

export const getAssetsByCategoryIds = createAsyncThunk<
  Promise<void>,
  {categoryIds: string[]; parameters: GetAssetParam; keyStorage?: string},
  {state: RootState}
>(
  'asset/getAssetsByCategoryIds',
  async (
    {categoryIds, parameters, keyStorage},
    {getState, signal, dispatch},
  ) => {
    const {cancel, token} = api.getCancelToken();
    signal.addEventListener('abort', () => {
      cancel();
    });
    const {api: apiStatus} = getState();

    const promise = categoryIds.map((categoryId) => {
      const categoryName = keyStorage
        ? `${keyStorage}_${categoryId}`
        : categoryId;

      const actionName = ApiAction.labelToGetAssetsByCategory(categoryName);

      const categoryRequest = apiStatus[actionName];
      const categoryStatus = categoryRequest
        ? categoryRequest.status
        : AsyncActionStatus.NOT_EXECUTED;

      if (
        AsyncActionStatus.PENDING !== categoryStatus &&
        AsyncActionStatus.SUCCESS !== categoryStatus
      ) {
        dispatch({
          type: ApiType.API_INIT,
          payload: {label: actionName},
        });
        return api.getAssets(
          {
            ...parameters,
            competencyParent: [categoryId],
          },
          {cancelToken: token},
        );
      }

      return Promise.resolve(null);
    });

    const responses = await Promise.all(promise);

    responses.forEach((response, index) => {
      if (null !== response) {
        const categoryName = keyStorage
          ? `${keyStorage}_${categoryIds[index]}`
          : categoryIds[index];

        const actionName = ApiAction.labelToGetAssetsByCategory(categoryName);

        dispatch(AssetAction.saveAssetsByCategory(categoryName, response));
        dispatch({
          type: ApiType.API_SUCCESS,
          payload: {label: actionName},
        });
      }
    });
  },
);

export const getAssetsBySalesChannelIds = createAsyncThunk<
  Promise<void>,
  {categoryIds: string[]; parameters: GetAssetParam; keyStorage?: string},
  {state: RootState}
>(
  'asset/getAssetsBySalesChannelIds',
  async (
    {categoryIds, parameters, keyStorage},
    {getState, signal, dispatch},
  ) => {
    const {cancel, token} = api.getCancelToken();
    signal.addEventListener('abort', () => {
      cancel();
    });
    const {api: apiStatus} = getState();

    const promise = categoryIds.map((categoryId) => {
      const categoryName = keyStorage
        ? `${keyStorage}_${categoryId}`
        : categoryId;

      const actionName = ApiAction.labelToGetAssetsByCategory(categoryName);

      const categoryRequest = apiStatus[actionName];
      const categoryStatus = categoryRequest
        ? categoryRequest.status
        : AsyncActionStatus.NOT_EXECUTED;

      if (
        AsyncActionStatus.PENDING !== categoryStatus &&
        AsyncActionStatus.SUCCESS !== categoryStatus
      ) {
        dispatch({
          type: ApiType.API_INIT,
          payload: {label: actionName},
        });
        return api.getAssets(
          {
            ...parameters,
            salesChannelParent: [categoryId],
          },
          {cancelToken: token},
        );
      }

      return Promise.resolve(null);
    });

    const responses = await Promise.all(promise);

    responses.forEach((response, index) => {
      if (null !== response) {
        const categoryName = keyStorage
          ? `${keyStorage}_${categoryIds[index]}`
          : categoryIds[index];

        const actionName = ApiAction.labelToGetAssetsByCategory(categoryName);

        dispatch(AssetAction.saveAssetsByCategory(categoryName, response));
        dispatch({
          type: ApiType.API_SUCCESS,
          payload: {label: actionName},
        });
      }
    });
  },
);

export const getLastAssetsFallback = createAsyncThunk<
  HydraResponse<Asset[]>,
  GetAssetParam,
  {state: RootState}
>('asset/getLastAssetsFallback', async (parameters, {signal}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  return await api.getAssets(
    {...parameters, isPublic: true},
    {cancelToken: token},
  );
});

export const postUpdateLastPosition = createAsyncThunk<
  Promise<void>,
  {assetId: number; currentTime: number; isFinished: boolean}
>(
  'asset/postUpdateLastPosition',
  async ({assetId, currentTime, isFinished}, {signal}) => {
    const {cancel, token} = api.getCancelToken();
    signal.addEventListener('abort', () => {
      cancel();
    });

    await api.postUpdateLastPosition(assetId, currentTime, isFinished, {
      cancelToken: token,
    });
  },
);

export const postStartReproduction = createAsyncThunk<
  Promise<void>,
  {assetId: number}
>('asset/postStartReproduction', async ({assetId}, {signal}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  await api.postStartReproduction(assetId, {
    cancelToken: token,
  });
});

export const getSearchAssets = createAsyncThunk<
  HydraResponse<Asset[]>,
  GetAssetParam
>('asset/getSearchAssets', async (parameters = {}, {signal}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  return api.getAssets({...parameters}, {cancelToken: token});
});

export default AssetAction;
