import React, {PropsWithChildren, useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {
  DropdownProps,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

export interface LanguageSelectProps extends Pick<DropdownProps, 'direction'> {
  className?: string;
  showIcon?: boolean;
}

const LanguageSelect = (
  props: PropsWithChildren<LanguageSelectProps>,
): JSX.Element => {
  const {direction, className, showIcon} = props;
  const {i18n, t} = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggle = () => setIsDropdownOpen((prevState) => !prevState);

  const availableLanguages = React.useMemo(
    () => [
      {id: 'zh', name: t('languages.zh')},
      {id: 'en', name: t('languages.en')},
      {id: 'fr', name: t('languages.fr')},
      {id: 'pt', name: t('languages.pt')},
      {id: 'es', name: t('languages.es')},
      {id: 'tr', name: t('languages.tr')},
      {id: 'hi', name: t('languages.hi')},
      {id: 'ar', name: t('languages.ar')},
    ],
    [t],
  );

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language, () => {
      window.location.reload();
    });
  };

  const currentLanguage = React.useMemo(
    () =>
      availableLanguages.find(
        (language): boolean => i18n.language === language.id,
      ),
    [availableLanguages, i18n],
  );

  return (
    <Dropdown
      className={classNames('dropdown-option--language', className)}
      isOpen={isDropdownOpen}
      toggle={toggle}
      direction={direction}
    >
      <DropdownToggle
        color="dark"
        className="btn--dark d-flex align-items-center"
      >
        {showIcon && (
          <i className="fas fa-language standard-icon-size--14 pe-2" />
        )}
        {currentLanguage && currentLanguage.name}
        {isDropdownOpen ? (
          <i className="icomoon icon-drop_up standard-icon-size--8 ps-2" />
        ) : (
          <i className="icomoon icon-drop_down standard-icon-size--8 ps-2" />
        )}
      </DropdownToggle>
      <DropdownMenu end>
        {availableLanguages.map((language) => (
          <DropdownItem
            onClick={() => changeLanguage(language.id)}
            key={language.id}
          >
            {language.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSelect;
