import {AnyAction} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import {Context} from '../../utils/ApiInterface';
import Category from '../../interface/Category';
import CategoryType from '../type/CategoryType';
import merge from '../../utils/merge';
import AuthenticationType from '../type/AuthenticationType';

export interface CategoryReducer {
  category: Record<string, Category>;
  categoryParent: Record<string, Category>;
  context: Record<string, Context>;
  totalCategories: number | null;
}

export const initialState: CategoryReducer = {
  totalCategories: null,
  category: {},
  categoryParent: {},
  context: {},
};

const categorySchema = new schema.Entity('category', {
  parent: new schema.Entity('categoryParent'),
  children: [new schema.Entity('category', {})],
  context: new schema.Entity('context', {}),
});

const categoryReducer = (
  state = initialState,
  action: AnyAction,
): CategoryReducer => {
  switch (action.type) {
    case CategoryType.SAVE_CATEGORIES: {
      const {payload} = action;

      const normalizedData = normalize(payload['hydra:member'], [
        categorySchema,
      ]);

      const category = merge(
        {...state.category},
        normalizedData.entities.category,
      );

      const categoryParent = merge(
        {...state.categoryParent},
        normalizedData.entities.categoryParent,
      );

      const context = merge(
        {...state.context},
        normalizedData.entities.context,
      );

      return {
        ...state,
        category,
        categoryParent,
        context,
        totalCategories: payload['hydra:totalItems'],
      };
    }

    case AuthenticationType.SIGN_OUT: {
      return {...initialState};
    }

    default: {
      return state;
    }
  }
};

export default categoryReducer;
