import React from 'react';
import AssetsBySalesChannel from './AssetsBySalesChannel';
import AssetsByCompetency from './AssetsByCompetency';

export interface CategoriesByContextProps {
  context: string;
  isAssetByUserProfile?: boolean;
  showCategoryByProfile?: boolean;
  keyStorage?: string;
}

const CategoriesByContext: React.FC<CategoriesByContextProps> = (props) => {
  const {context, isAssetByUserProfile, showCategoryByProfile, keyStorage} =
    props;

  if ('competency' === context) {
    return (
      <AssetsByCompetency
        isAssetByUserProfile={isAssetByUserProfile}
        context={context}
        keyStorage={keyStorage}
        showCategoryByProfile={showCategoryByProfile}
      />
    );
  }

  return (
    <AssetsBySalesChannel
      isAssetByUserProfile={isAssetByUserProfile}
      context={context}
      keyStorage={keyStorage}
      showCategoryByProfile={showCategoryByProfile}
    />
  );
};

export default CategoriesByContext;
