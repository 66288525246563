import React from 'react';
import classNames from 'classnames';
import useGetAssetImage from '../hook/useGetAssetImage';
import {Asset} from '../utils/ApiInterface';

export interface AssetMediaProps {
  asset: Asset;
  className?: string;
}

/**
 * Renders an image of an asset.
 */
const AssetMedia: React.FC<AssetMediaProps> = (props) => {
  const {asset, className} = props;
  const poster = useGetAssetImage(asset);

  if (!poster) {
    return null;
  }

  return (
    <img
      src={poster}
      alt={asset.title}
      className={classNames('w-100', className)}
    />
  );
};

export default AssetMedia;
