import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ContainerMultimedia from '../component/ContainerMultimedia';
import ListItem from '../component/ListItem';
import {RootState} from '../store/store';
import {HistoryAsset} from '../utils/ApiInterface';
import useGetRequestStatus from '../hook/useGetRequestStatus';
import ApiAction from '../store/action/ApiAction';
import InfinityScroll from '../component/InfinityScroll';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import makeGetAssetsHistory from '../selector/makeGetAssetsHistory';
import useGetNextPage from '../hook/useGetNextPage';
import QueryAssetModal from './QueryAssetModal';

const History = (): JSX.Element => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const requestStatus = useGetRequestStatus(ApiAction.labelToGetHistory());
  const selectGetAssetsHistory = React.useMemo(makeGetAssetsHistory, []);
  const historyCollection = useSelector((state: RootState) =>
    selectGetAssetsHistory(state, {categoryName: 'history'}),
  );

  const [categoryName] = React.useState('history');

  const nextPage = useGetNextPage(categoryName);

  const getMoreData = React.useCallback(() => {
    if (nextPage) {
      dispatch(ApiAction.getAssetsViewed('history', {page: nextPage}));
    }
  }, [dispatch, nextPage]);

  return (
    <div className="history-page page">
      <QueryAssetModal />
      {0 === historyCollection.length && (
        <div className="text-center">{t('page.history.notVideoText')}</div>
      )}

      <InfinityScroll
        hasNextPage={undefined !== nextPage}
        isLoading={AsyncActionStatus.PENDING === requestStatus}
        onLoadMore={getMoreData}
      >
        {historyCollection.map((history: HistoryAsset) => {
          return (
            <ContainerMultimedia title={history.title} key={history.title}>
              <div className="grid-list">
                {history.multimediaCollection.map((multimedia) => {
                  return <ListItem assetId={multimedia} key={multimedia} />;
                })}
              </div>
            </ContainerMultimedia>
          );
        })}
      </InfinityScroll>
    </div>
  );
};

export default History;
