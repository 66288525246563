import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const makeGetChildrenCategoriesByCategory = () =>
  createDeepEqualSelector(
    [
      (state: RootState, categoryName: string) => {
        const {
          categories: {category},
        } = state;

        return Object.keys(category).filter(
          (categoryId) => category[categoryId].parent === categoryName,
        );
      },
    ],
    (categoryCollection) => {
      return categoryCollection;
    },
  );

export default makeGetChildrenCategoriesByCategory;
