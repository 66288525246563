import React from 'react';
import {Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import FavoriteButton from './FavoriteButton';
import salesSchoolLogo from '../../assets/SS_s_.png';
import AssetLinkToPlayer from './AssetLinkToPlayer';

export interface HeroContentMobileProps {
  assetId: number;
  title: string;
  assetUrlPath: string;
  content?: string | null;
  isExternal?: boolean;
}

const HeroContentMobile = (
  props: React.PropsWithChildren<HeroContentMobileProps>,
): JSX.Element => {
  const {t} = useTranslation();
  const {assetUrlPath, title, assetId, content, isExternal} = props;

  return (
    <div className="container_inner hero__content">
      {!isExternal && (
        <div className="mb-2 mb-md-4 pb-0 pb-lg-2 text-center">
          <img
            src={salesSchoolLogo}
            alt="sales-school"
            className="hero__brand"
          />
        </div>
      )}

      <div className="hero__wrapper mb-2">
        <div className="multimedia__content">
          <h1 className="multimedia__title mb-3">{title}</h1>
          <p className="multimedia__description">{content}</p>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-start">
        <FavoriteButton
          assetId={assetId}
          identifier="home"
          className="border-0 hero__button btn--dark"
          textClassName="font-size--regular"
          iconContainerClassName="standard-icon-height standard-icon-height--40 mb-2"
          iconClassName="hero-content-mobile__favorite"
        />

        <div className="mx-auto hero__button">
          <AssetLinkToPlayer
            assetId={assetId}
            color="dark"
            className="btn--dark border-0 w-100"
          >
            <div className="standard-icon-height standard-icon-height--40 mb-2">
              <i className="icomoon icon-play hero-content-mobile__play" />
            </div>
            <span className="font-size--regular">
              {t('component.playButton')}
            </span>
          </AssetLinkToPlayer>
        </div>

        <Button
          tag={Link}
          to={assetUrlPath}
          color="dark"
          className="btn--dark border-0 hero__button"
        >
          <div className="standard-icon-height standard-icon-height--40 mb-2">
            <i className="icomoon icon-information hero-content-mobile__info d-block" />
          </div>
          <span className="font-size--regular">
            {t('component.infoButtonShore')}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default HeroContentMobile;
