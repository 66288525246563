import React from 'react';
import useGetQueryParameters from './useGetQueryParameters';

/**
 * Retrieves the "asset" parameters passed by URL.
 */
const useGetQueryAssetParameter = (): number | undefined => {
  const [assetId, setAssetId] = React.useState<number | undefined>(undefined);

  const queryParameters = useGetQueryParameters();

  React.useEffect(() => {
    const {asset} = queryParameters;

    if (asset) {
      if ('string' === typeof asset) {
        setAssetId(parseInt(asset, 10));

        return;
      }
    }

    setAssetId(undefined);
  }, [queryParameters]);

  return assetId;
};

export default useGetQueryAssetParameter;
