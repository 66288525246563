import React from 'react';
import {ListGroup, ListGroupItem} from 'reactstrap';
import useGetHierarchies from '../hook/useGetHierarchies';
import AssetListItemLayout from './AssetListItemLayout';
import Preloader from './Preloader';

export interface AssetTrainingListProps {
  trainerId: number;
}

const AssetTrainingList: React.FC<AssetTrainingListProps> = (props) => {
  const {trainerId} = props;
  const [Hierarchies, isFetching] = useGetHierarchies(trainerId);

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Preloader />
      </div>
    );
  }

  /** {@todo Missing show message when it has no associated children}. */

  return (
    <div>
      <ListGroup flush className="asset-list">
        {Hierarchies.map((assetIdHierarchy, index) => {
          return (
            <ListGroupItem
              key={assetIdHierarchy.id}
              className="asset-list__item"
            >
              <AssetListItemLayout
                asset={assetIdHierarchy}
                trainingId={trainerId}
                position={index + 1}
              />
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </div>
  );
};

export default AssetTrainingList;
