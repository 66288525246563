import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import authenticationValidator from '../router/authenticationValidator';
import ApiAction from '../store/action/ApiAction';
import useGetRequestStatus from '../hook/useGetRequestStatus';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import Favorite from './Favorite';
import Preloader from '../component/Preloader';
import {AppDispatch, RootState} from '../store/store';
import ErrorMessage from '../component/ErrorMessage';
import AssetAction, {getFavoriteAssets} from '../store/action/AssetAction';
import ApiType from '../store/type/ApiType';
import {useGetCategoryQuery} from '../store/service/classificationService';
import CategoryEntity from '../entity/CategoryEntity';

const FavoriteHOC = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const {data, isLoading} = useGetCategoryQuery(CategoryEntity.ID_LANGUAGE);
  const favoriteAssetRequest = useGetRequestStatus(
    ApiAction.labelToGetFavoriteAssets(),
  );

  const favoriteResponse = useSelector((state: RootState) => {
    return state.assets.assetsByCategory[ApiAction.labelToGetFavoriteAssets()];
  });

  React.useEffect(() => {
    let abortPromise: VoidFunction | undefined;

    const promise = dispatch(getFavoriteAssets({}));
    abortPromise = promise.abort;
    const label = ApiAction.labelToGetFavoriteAssets();
    dispatch({
      type: ApiType.API_INIT,
      payload: {label},
    });

    promise
      .unwrap()
      .then((response) => {
        dispatch(AssetAction.saveAssetsByCategory(label, response));
        dispatch({
          type: ApiType.API_SUCCESS,
          payload: {label},
        });
      })
      .catch((error) => {
        dispatch({
          type: ApiType.API_FAILURE,
          payload: {label, error},
        });
      })
      .finally(() => {
        abortPromise = undefined;
      });

    return () => {
      if (abortPromise) {
        abortPromise();
      }
    };
  }, [dispatch]);

  if (
    undefined === favoriteResponse &&
    AsyncActionStatus.FAILURE === favoriteAssetRequest
  ) {
    return (
      <ErrorMessage>
        <h1>{t('page.error')}</h1>
      </ErrorMessage>
    );
  }

  if (undefined === favoriteResponse || undefined === data || isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Preloader />
      </div>
    );
  }

  return <Favorite />;
};

export default authenticationValidator(FavoriteHOC);
