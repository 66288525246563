import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import makeGetAsset from '../selector/makeGetAsset';
import {RootState} from '../store/store';
import {Asset} from '../utils/ApiInterface';

const useGetAsset = (assetId: number): Asset => {
  const selectUserQualification = useMemo(makeGetAsset, []);

  const page = useSelector((state: RootState) =>
    selectUserQualification(state, {assetId}),
  );

  return page;
};

export default useGetAsset;
