import React, {useState} from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {
  DropdownProps,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {Link} from 'react-router-dom';
import useGetQueryParameters from '../hook/useGetQueryParameters';
import useGetAvailableLanguage from '../hook/useGetAvailableLanguage';
import AssetEntity from '../class/Asset';
import UrlProcessor from '../utils/UrlProcessor';
import routeCollection from '../router/routeCollection';
import {Asset} from '../utils/ApiInterface';

export interface AssetLanguageSelectProps
  extends Pick<DropdownProps, 'direction'> {
  className?: string;
  showIcon?: boolean;
  asset: Asset;
}

const AssetLanguageSelect: React.FC<AssetLanguageSelectProps> = (props) => {
  const {direction, className, showIcon, asset} = props;
  const {i18n} = useTranslation();
  const queryParameters = useGetQueryParameters();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggle = () => setIsDropdownOpen((prevState) => !prevState);

  const availableLanguages = useGetAvailableLanguage();

  // Collection of languages in which content is available.
  const assetLanguages = React.useMemo(
    () =>
      asset.medias
        .filter(
          (media) =>
            AssetEntity.MEDIA_METADATA_CONTEXT_RESOURCE ===
            media.providerMetadata.context,
        )
        .map((media) => {
          const {
            providerMetadata: {source_language: sourceLanguage = ''},
          } = media || {};
          return sourceLanguage.split(/[_-]/)[0];
        }),
    [asset.medias],
  );

  // We filter the languages of the asset considering valid only those that are registered in `availableLanguages`.
  const assetLanguageFiltered = React.useMemo(() => {
    return availableLanguages.filter(({id}) => assetLanguages.includes(id));
  }, [assetLanguages, availableLanguages]);

  // Update queryParameters
  const getUrlByLanguage = React.useCallback(
    (language: string) => {
      return UrlProcessor.createPath(
        routeCollection.player,
        {id: asset.id},
        {language},
      );
    },
    [asset],
  );

  const value = React.useMemo(() => {
    const languageQueryParameter = queryParameters.language;

    if (languageQueryParameter && 'string' === typeof languageQueryParameter) {
      return languageQueryParameter;
    }

    return '';
  }, [queryParameters]);

  const currentLanguage = React.useMemo(() => {
    if (value) {
      const isLangAssetValid = assetLanguageFiltered.find(
        (language) => language.id === value,
      );

      if (isLangAssetValid) {
        return isLangAssetValid;
      }
    }

    const isLangAssetValid = assetLanguageFiltered.find(
      (language) => language.id === i18n.language,
    );

    if (isLangAssetValid) {
      return isLangAssetValid;
    }

    return assetLanguageFiltered[0];
  }, [i18n.language, assetLanguageFiltered, value]);

  return (
    <Dropdown
      className={classNames('dropdown-option--language', className)}
      isOpen={isDropdownOpen}
      toggle={toggle}
      direction={direction}
    >
      <DropdownToggle
        color="dark"
        className="btn--dark d-flex align-items-center py-2 line-height--0"
      >
        {showIcon && (
          <i className="fas fa-language standard-icon-size--21 pe-2" />
        )}
        {currentLanguage && currentLanguage.name}
        {isDropdownOpen ? (
          <i className="icomoon icon-drop_up standard-icon-size--8 ps-2" />
        ) : (
          <i className="icomoon icon-drop_down standard-icon-size--8 ps-2" />
        )}
      </DropdownToggle>
      <DropdownMenu end>
        {assetLanguageFiltered.map((language) => {
          const urlPath = getUrlByLanguage(language.id);

          return (
            <DropdownItem tag={Link} to={urlPath} key={language.id}>
              {language.name}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default AssetLanguageSelect;
