import React from 'react';
import {Link} from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';
import HeaderPlayer from '../component/HeaderPlayer';
import Preloader from '../component/Preloader';
import useAuthenticationValidator from '../hook/useAuthenticationValidator';
import routeCollection from '../router/routeCollection';
import TermsCondition from './TermsCondition';

const TermConditionHoc: React.FC = () => {
  const {isAuthenticated, isValidating} = useAuthenticationValidator();

  if (isValidating) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column layout">
      <div className="layout__body">
        {isAuthenticated ? (
          <Header />
        ) : (
          <HeaderPlayer>
            <Link to={routeCollection.home} className="text-white">
              <div className="standard-icon-height standard-icon-height--26">
                <i className="icomoon icon-go_back_menu px-2 px-md-3 py-2 header-icon__logout" />
              </div>
            </Link>
          </HeaderPlayer>
        )}
        <div className="container_inner flex-grow-1">
          <TermsCondition />
        </div>
      </div>
      <Footer isAuthenticatedUser={isAuthenticated} />
    </div>
  );
};

export default TermConditionHoc;
