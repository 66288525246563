import {useEffect, RefObject, useRef, useMemo} from 'react';
import videoJS, {VideoJsPlayer, VideoJsPlayerOptions} from 'video.js';
import 'videojs-landscape-fullscreen';
import mainVideoSetting from '../utils/mainVideoSetting';

const usePlayer = (
  videoRef: RefObject<HTMLVideoElement>,
  options: VideoJsPlayerOptions,
): RefObject<VideoJsPlayer | null> => {
  const playerRef = useRef<VideoJsPlayer | null>(null);

  const videoPlayerOptions = useMemo((): VideoJsPlayerOptions => {
    return {
      html5: {
        ...mainVideoSetting,
        nativeTextTracks: false,
        nativeAudioTracks: false,
        nativeVideoTracks: false,
        vhs: {
          withCredentials: true,
        },
        hls: {
          overrideNative: true,
        },
      },
      bigPlayButton: true,
      controlBar: {
        pictureInPictureToggle: false,
        remainingTimeDisplay: false,
      },
      fluid: true,
      ...options,
    };
  }, [options]);

  useEffect(() => {
    let playerInstance: VideoJsPlayer;

    if (videoRef.current) {
      if (!playerRef.current) {
        playerInstance = videoJS(videoRef.current, {
          ...videoPlayerOptions,
          plugins: {
            landscapeFullscreen: {
              enterOnRotate: true,
              alwaysInLandscapeMode: true,
              iOS: true,
            },
          },
        });

        playerInstance.playsinline(true);

        playerRef.current = playerInstance;
      } else {
        if (videoPlayerOptions.autoplay) {
          playerRef.current.autoplay(videoPlayerOptions.autoplay);
        }

        if (videoPlayerOptions.muted) {
          playerRef.current.muted(videoPlayerOptions.muted);
        }

        if (videoPlayerOptions.sources) {
          playerRef.current.src(videoPlayerOptions.sources);
        }

        if (videoPlayerOptions.poster) {
          playerRef.current.poster(videoPlayerOptions.poster);
        }

        if (videoPlayerOptions.tracks) {
          const oldTracks = playerRef.current.remoteTextTracks();

          // Los previous tracks are son removed.
          const numberOfTotalTracks = oldTracks.length;
          for (let i = numberOfTotalTracks - 1; 0 <= i; i -= 1) {
            // @ts-ignore
            playerRef.current.removeRemoteTextTrack(oldTracks[i]);
          }

          // Los new tracks are added.
          videoPlayerOptions.tracks.forEach((track) => {
            playerRef.current?.addRemoteTextTrack(track, true);
          });
        }
      }
    }
  }, [videoRef, videoPlayerOptions]);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return playerRef;
};

export default usePlayer;
