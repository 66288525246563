import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import makeGetCategoryIdsByContext from '../selector/makeGetCategoryIdsByContext';
import makeGetCategoryIdsByContextAndUserProfile from '../selector/makeGetCategoryIdsByContextAndUserProfile';
import {RootState} from '../store/store';

const useGetCategoryIdByContext = (
  context: string,
  isUser?: boolean,
): string[] => {
  const selectCategory = useMemo(() => {
    return isUser
      ? makeGetCategoryIdsByContextAndUserProfile()
      : makeGetCategoryIdsByContext();
  }, [isUser]);

  const categoryIds = useSelector((state: RootState) => {
    return selectCategory(state, {context});
  });

  return categoryIds;
};

export default useGetCategoryIdByContext;
