import {Asset} from '../utils/ApiInterface';

class AssetModel {
  public static get TYPE_AUDIO(): string {
    return 'audio';
  }

  public static get TYPE_PDF(): string {
    return 'pdf';
  }

  public static get TYPE_VIDEO(): string {
    return 'video';
  }

  public static get TYPE_WEB(): string {
    return 'web';
  }

  public static get TYPE_TRAINING_PROGRAM(): string {
    return 'training_program';
  }

  public static getCover(asset: Asset): string | undefined {
    const {reference: poster} = asset?.cover?.publicUrls || {};

    return poster;
  }
}

export default AssetModel;
