import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import authenticationValidator from '../router/authenticationValidator';
import Contact from './Contact';
import Layout from '../component/Layout';
import {AppDispatch, RootState} from '../store/store';
import Preloader from '../component/Preloader';
import CategoryAction, {
  getCategoriesByContext,
} from '../store/action/CategoryAction';

const Component = Layout(Contact);
const ContactHOC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const isOrganizationLoaded = useSelector(
    (state: RootState) => state.categories.categoryParent.organization,
  );

  React.useEffect(() => {
    if (undefined === isOrganizationLoaded) {
      dispatch(
        getCategoriesByContext({
          categoryParam: {
            limit: 100,
            'context.id': ['organization'],
            exists: {parent: true},
          },
        }),
      )
        .unwrap()
        .then((response) => {
          dispatch(CategoryAction.saveCategories(response));
        });
    }
  }, [dispatch, isOrganizationLoaded]);

  if (undefined === isOrganizationLoaded) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Preloader />
      </div>
    );
  }

  return <Component />;
};

export default authenticationValidator(ContactHOC);
