import apiService from './apiService';
import {ContactMessage, PostContactMessageBody} from '../../utils/ApiInterface';

const contactMessageService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getContactMessage: builder.query<ContactMessage, number>({
      query: (id: number) => ({
        url: `/contact_messages/${id}`,
        method: 'get',
      }),
    }),
    postContactMessage: builder.mutation<
      ContactMessage,
      PostContactMessageBody
    >({
      query: (body) => ({
        url: '/contact_messages',
        method: 'post',
        config: {data: body},
      }),
    }),
  }),
});

export const {useGetContactMessageQuery, usePostContactMessageMutation} =
  contactMessageService;

export default contactMessageService;
