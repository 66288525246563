import React from 'react';
import {useTranslation} from 'react-i18next';
import ContactForm from '../component/ContactForm';

const Contact = (): JSX.Element => {
  const {t} = useTranslation();

  return (
    <div className="contact-page">
      <div className="card">
        <div className="card-header text-center">{t('page.contact.title')}</div>
        <div className="card-body">
          <p className="mb-3 card-text text-center">
            {t('page.contact.description')}
          </p>
          <p className="mb-5 card-text text-center">
            {t('page.contact.slogan')}
          </p>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
