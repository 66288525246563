import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import CategoryEntity from '../entity/CategoryEntity';
import {useGetCategoryQuery} from '../store/service/classificationService';

export interface Language {
  id: string;
  name: string;
}

const useGetAvailableLanguage = (): Language[] => {
  const {data} = useGetCategoryQuery(CategoryEntity.ID_LANGUAGE);
  const {children: languages = []} = data || {};
  const {t} = useTranslation();

  return useMemo(() => {
    return languages
      .slice()
      .sort(
        (lastLanguage, newLanguage) =>
          lastLanguage.position - newLanguage.position,
      )
      .map((language): Language => {
        const {id: languageId} = language;
        const [sourceLanguage] = languageId
          .replace('language_', '')
          .split(/[_-]/);

        return {id: sourceLanguage, name: t(`languages.${sourceLanguage}`)};
      });
  }, [t, languages]);
};

export default useGetAvailableLanguage;
