import React from 'react';
import classNames from 'classnames';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import SkeletonSlider from './SkeletonSlider';

export interface InfinityScrollProps {
  isLoading: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
  paginatorClassName?: string;
}

const InfinityScroll: React.FC<InfinityScrollProps> = (props) => {
  const {hasNextPage, isLoading, onLoadMore, paginatorClassName, children} =
    props;

  const [sentryRef] = useInfiniteScroll({
    hasNextPage,
    onLoadMore,
    loading: isLoading,
    disabled: undefined,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <div>
      {children}
      {isLoading && (
        <div className={classNames(paginatorClassName)} ref={sentryRef}>
          <SkeletonSlider />
        </div>
      )}
    </div>
  );
};

export default InfinityScroll;
