import React from 'react';
import useGetNextPage from '../hook/useGetNextPage';
import useGetRequestStatus from '../hook/useGetRequestStatus';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import InfinityScroll from './InfinityScroll';

interface AssetResultProps {
  categoryName: string;
  getMoreData: (page: number) => void;
}

const AssetResult: React.FC<AssetResultProps> = (props) => {
  const {children, getMoreData, categoryName} = props;

  const nextPage = useGetNextPage(categoryName);
  const requestStatus = useGetRequestStatus(categoryName);
  const activeShowMore = React.useCallback(() => {
    if (nextPage) {
      getMoreData(nextPage);
    }
  }, [getMoreData, nextPage]);

  return (
    <InfinityScroll
      hasNextPage={undefined !== nextPage}
      isLoading={AsyncActionStatus.PENDING === requestStatus}
      onLoadMore={activeShowMore}
    >
      {children}
    </InfinityScroll>
  );
};

export default AssetResult;
