import React from 'react';
import {useSelector} from 'react-redux';
import AssetModel from '../model/AssetModel';
import Player from '../page/Player';
import {Asset} from '../utils/ApiInterface';
import AssetPdfLayout from './AssetPdfLayout';
import AssetWebLayout from './AssetWebLayout';
import useCreateReproduction from '../hook/useCreateReproduction';
import Preloader from './Preloader';
import {RootState} from '../store/store';
import useHandlerPlayer from '../hook/useHandlerPlayer';

export interface AssetLayoutTrainerUIProps {
  asset: Asset;
  trainer?: Asset;
}

const AssetLayoutTrainerUI: React.FC<AssetLayoutTrainerUIProps> = (props) => {
  const {asset, trainer} = props;
  const {type} = asset;
  const {updateLastPosition} = useHandlerPlayer(asset, trainer);
  const isCreatingHistory = useCreateReproduction(asset, trainer);

  const isAssetEnding = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[asset.id];

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isEnding;
  });

  React.useEffect(() => {
    if (isAssetEnding) {
      updateLastPosition(0, true);
    }
  }, [isAssetEnding, updateLastPosition]);

  if (isCreatingHistory) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Preloader />
      </div>
    );
  }

  return (
    <>
      {AssetModel.TYPE_PDF === type && (
        <AssetPdfLayout asset={asset} trainer={trainer} />
      )}
      {AssetModel.TYPE_WEB === type && <AssetWebLayout asset={asset} />}
      {(AssetModel.TYPE_VIDEO === type || AssetModel.TYPE_AUDIO === type) && (
        <div className="layout__body player-main">
          <Player asset={asset} trainer={trainer} />
        </div>
      )}
    </>
  );
};

export default AssetLayoutTrainerUI;
