import React from 'react';
import useGetRequestStatus from '../hook/useGetRequestStatus';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import Carousel from './Carousel';
import SkeletonSlider from './SkeletonSlider';
import useGetAssetsByCategoryId from '../hook/useGetAssetsByCategoryId';

export interface AssetByCategoryProps {
  categoryId: string;
  categoryName: string;
  labelApiId: string;
}

const AssetByCategory: React.FC<AssetByCategoryProps> = (props) => {
  const {categoryId, categoryName, labelApiId} = props;
  const categoryRequestStatus = useGetRequestStatus(labelApiId);

  const assetIds = useGetAssetsByCategoryId(categoryId);

  if (AsyncActionStatus.SUCCESS !== categoryRequestStatus) {
    return (
      <div className="container_inner mb-4">
        <SkeletonSlider />
      </div>
    );
  }

  if (0 === assetIds.length) {
    return null;
  }

  return (
    <Carousel
      className="container_inner"
      sectionTitle={categoryName}
      carouselKey={categoryId}
      slideData={assetIds}
    />
  );
};

export default AssetByCategory;
