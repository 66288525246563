import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import {RootState} from '../store/store';

const useGetRequestStatus = (label: string): AsyncActionStatus => {
  const request = useSelector((state: RootState) => {
    return state.api[label];
  });

  const detailRequestStatus = useMemo(
    () => (request ? request.status : AsyncActionStatus.NOT_EXECUTED),
    [request],
  );

  return detailRequestStatus;
};

export default useGetRequestStatus;
