import {AnyAction} from '@reduxjs/toolkit';
import UserType from '../type/UserType';
import {User} from '../../utils/ApiInterface';
import AuthenticationType from '../type/AuthenticationType';

export interface UserReducer {
  user: Record<string, User>;
  authenticatedUser?: number;
}

export const initialState: UserReducer = {
  user: {},
};

const userReducer = (state = initialState, action: AnyAction): UserReducer => {
  const {type, payload} = action;

  switch (type) {
    case UserType.SAVE_AUTHENTICATED_USER: {
      const {user} = payload;

      return {
        ...state,
        authenticatedUser: user.id,
        user: {...state.user, [user.id]: user},
      };
    }

    case AuthenticationType.SIGN_OUT: {
      return {...initialState};
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
