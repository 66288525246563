import {useMemo} from 'react';
import videoJs from 'video.js';
import {Media} from '../utils/ApiInterface';

const useGetMediaToPlayer = (
  media: Media,
  showCLip?: boolean,
): videoJs.Tech.SourceObject[] => {
  return useMemo(() => {
    const {publicUrls} = media || {};
    const {reference, clip} = publicUrls || {};

    if (!reference) {
      return [];
    }

    return [{src: showCLip && clip ? clip : reference}];
  }, [showCLip, media]);
};

export default useGetMediaToPlayer;
