import React, {useState} from 'react';
import {Button, UncontrolledPopover, PopoverBody} from 'reactstrap';
import {Link, useHistory} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import salesSchoolLogo from '../../assets/sales-school.png';
import ssLogo from '../../assets/SS_s_.png';
import LoginForm, {AuthenticationForm} from '../component/LoginForm';
import {RootState} from '../store/store';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import ApiAction from '../store/action/ApiAction';
import routeCollection from '../router/routeCollection';
import LanguageSelect from '../component/LanguageSelect';

enum AuthenticationFormType {
  associateId = 'ASSOCIATE ID',
  email = 'email',
}

const Login = (): JSX.Element => {
  const [isConditionsAccepted, setIsConditionsAccepted] = useState(false);
  const dispatch = useDispatch();

  const {t} = useTranslation();

  const history = useHistory();

  const loginRequest = useSelector((state: RootState) => {
    return state.api.API_LOGIN;
  });

  const loginRequestStatus = React.useMemo(
    () => (loginRequest ? loginRequest.status : AsyncActionStatus.NOT_EXECUTED),
    [loginRequest],
  );

  const loginRequestError = React.useMemo(
    () => loginRequest?.error?.message || '',
    [loginRequest],
  );

  const [authenticationFormType, setAuthenticationFormType] =
    React.useState<string>('');

  const redirectToAzureLogin = React.useCallback(() => {
    if (isConditionsAccepted) {
      Auth.federatedSignIn({customProvider: 'SalesSchoolAzureAd'});
    }
  }, [isConditionsAccepted]);

  React.useEffect(() => {
    if (AsyncActionStatus.SUCCESS === loginRequestStatus) {
      history.push(routeCollection.home);
    }
  }, [loginRequestStatus, history]);

  const setAuthenticationType = React.useCallback(() => {
    if (isConditionsAccepted) {
      setAuthenticationFormType(AuthenticationFormType.associateId);
    }
  }, [isConditionsAccepted]);

  const onSubmit = (data: AuthenticationForm) => {
    dispatch(ApiAction.postLogin(data.associate.trim(), data.password));
  };

  const PopOverMessage = (
    <PopoverBody>{t('page.login.warningMessage')}</PopoverBody>
  );

  const conditionUpdate = React.useCallback(() => {
    if ('' === authenticationFormType) {
      setIsConditionsAccepted((prevValue) => !prevValue);
    }
  }, [authenticationFormType]);

  return (
    <div className="login-page gout">
      <div className="login-page__container">
        <div className="d-flex justify-content-between mx-4">
          <div>
            <img
              src={ssLogo}
              alt="SS logo"
              className="login-page__header__logo mt-3"
            />
          </div>

          <div className="me-4">
            <LanguageSelect
              direction="down"
              className="ms-auto mr-container mt-3"
            />
          </div>
        </div>
        <div className="login-page__wrapper container_inner py-4">
          <div className="login-page__wrapper login-page__wrapper--form">
            <p className="text-center mb-3 pb-2 form-data__title">
              {t('page.login.title')}
            </p>

            {AuthenticationFormType.associateId === authenticationFormType && (
              <div className="mb-3 pb-2">
                <LoginForm
                  onSubmit={onSubmit}
                  isLoading={
                    AsyncActionStatus.PENDING === loginRequestStatus ||
                    AsyncActionStatus.SUCCESS === loginRequestStatus
                  }
                  authenticationErrorMessage={loginRequestError}
                />
              </div>
            )}

            {AuthenticationFormType.associateId !== authenticationFormType && (
              <div className="mb-2 pb-2">
                <Button
                  color="dark"
                  className={classNames(
                    'd-block w-100 form-data__button btn--dark',
                    {disabled: !isConditionsAccepted},
                  )}
                  id="associated-id"
                  onClick={(buttonTarget) => {
                    buttonTarget.currentTarget.focus();
                    setAuthenticationType();
                  }}
                >
                  {t('page.login.loginWithAssociateId')}
                </Button>

                {!isConditionsAccepted && (
                  <UncontrolledPopover
                    trigger="focus"
                    target="associated-id"
                    placement="top"
                  >
                    {PopOverMessage}
                  </UncontrolledPopover>
                )}
              </div>
            )}

            <Button
              color="dark"
              className={classNames(
                'd-block w-100 form-data__button btn--dark',
                {
                  disabled:
                    AsyncActionStatus.PENDING === loginRequestStatus ||
                    AsyncActionStatus.SUCCESS === loginRequestStatus ||
                    !isConditionsAccepted,
                },
              )}
              id="email-bimbo"
              onClick={(buttonTarget) => {
                buttonTarget.currentTarget.focus();
                redirectToAzureLogin();
              }}
            >
              {t('page.login.loginWithEmail')}
            </Button>

            {!isConditionsAccepted && (
              <UncontrolledPopover
                trigger="focus"
                target="email-bimbo"
                placement="bottom"
              >
                {PopOverMessage}
              </UncontrolledPopover>
            )}
          </div>
          <div className="login-page__terms-condition mt-4">
            <div className="d-flex align-items-center mb-1">
              <div
                className="outline-0 me-2"
                role="button"
                tabIndex={0}
                onKeyPress={conditionUpdate}
                onClick={conditionUpdate}
              >
                <i
                  className={classNames({
                    'far fa-square': !isConditionsAccepted,
                    'far fa-check-square': isConditionsAccepted,
                  })}
                />
              </div>
              <button
                onClick={conditionUpdate}
                className="bg-transparent border-0 text-white outline-0 font-size--regular pe-0"
                type="button"
              >
                <p className="m-0 me-1 font-size-small line-height">
                  {t('page.login.acceptText')}
                </p>
              </button>
              <Link
                to={routeCollection.termsCondition}
                className="font-size--regular d-block text-decoration-none me-1"
              >
                {t('page.login.termsConditionText')}
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <i className="fas fa-exclamation-triangle me-2" />
              <div className="text-start">
                <p className="m-0 font-size--small line-height">
                  {t('page.login.warningMessage_1')}
                </p>
                <p className="m-0 font-size--small line-height">
                  {t('page.login.warningMessage_2')}
                </p>
              </div>
            </div>
          </div>
        </div>

        <img
          src={salesSchoolLogo}
          alt="sales-school"
          className="login-page__imagotipo"
        />
      </div>
    </div>
  );
};

export default Login;
