import React from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import authenticationValidator from '../router/authenticationValidator';
import Detail from './Detail';
import Preloader from '../component/Preloader';
import ErrorMessage from '../component/ErrorMessage';
import useGetUserQualificationByAsset from '../hook/useGetUserQualificationByAsset';
import {useGetCategoryQuery} from '../store/service/classificationService';
import CategoryEntity from '../entity/CategoryEntity';
import {useGetAssetQuery} from '../store/service/assetService';

const DetailHOC = () => {
  const {t} = useTranslation();
  const params = useParams<{id: string}>();
  const {data, isLoading} = useGetCategoryQuery(CategoryEntity.ID_LANGUAGE);
  const id = React.useMemo(() => parseInt(params.id, 10), [params.id]);
  const {isError, isFetching} = useGetAssetQuery(id);
  useGetUserQualificationByAsset(id);

  if (isFetching || undefined === data || isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Preloader />
      </div>
    );
  }

  if (isError) {
    return (
      <ErrorMessage>
        <h1>{t('page.error')}</h1>
      </ErrorMessage>
    );
  }

  return <Detail />;
};

export default authenticationValidator(DetailHOC);
