import {ThunkAction} from '@reduxjs/toolkit';
import AsyncActionStatus from '../../interface/AsyncActionStatus';
import {PayloadInterface} from '../interface';
import {RootState} from '../store';
import AuthenticationType from '../type/AuthenticationType';
import userSession from '../../utils/UserSession';

class AuthenticationAction {
  static signIn(authenticationType: string): PayloadInterface<string> {
    return {type: AuthenticationType.SIGN_IN, payload: authenticationType};
  }

  static signOut(): ThunkAction<
    void,
    RootState,
    undefined,
    PayloadInterface<undefined>
  > {
    return (dispatch, getState) => {
      const {api} = getState();

      Object.keys(api).forEach((apiKey) => {
        const request = api[apiKey];

        if (
          AsyncActionStatus.PENDING === request.status &&
          request.cancelToken
        ) {
          request.cancelToken.cancel();
        }
      });

      userSession.signOut();

      dispatch({type: AuthenticationType.SIGN_OUT, payload: undefined});
    };
  }
}

export default AuthenticationAction;
