import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {Navbar, NavbarBrand} from 'reactstrap';
import routeCollection from '../router/routeCollection';
import salesSchool from '../../assets/sales-school.png';

export interface HeaderPlayerProps {
  className?: string;
}

const HeaderPlayer: React.FC<HeaderPlayerProps> = (props) => {
  const {children, className} = props;

  return (
    <Navbar dark className={classNames('header', className)}>
      <NavbarBrand
        tag={Link}
        className="navbar-brand d-flex align-items-center me-2"
        to={routeCollection.home}
      >
        <img src={salesSchool} alt="sales-school" className="header__logo" />
      </NavbarBrand>
      {children}
    </Navbar>
  );
};

export default HeaderPlayer;
