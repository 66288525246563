import {useRef, RefObject} from 'react';
import {useIntersection} from 'use-intersection';
import useIsWindowVisible from './useIsWindowVisible';

const useCanPlayAsset = (target: RefObject<HTMLDivElement>): boolean => {
  const isWindowVisible = useIsWindowVisible();
  const root = useRef<HTMLDivElement>(null);
  const intersecting = useIntersection(target, {
    root,
    threshold: 0.7,
  });

  return isWindowVisible && intersecting;
};

export default useCanPlayAsset;
