import React from 'react';
import {useDispatch} from 'react-redux';
import PlayerStatusAction from '../store/action/PlayerStatusAction';
import AssetModel from '../model/AssetModel';
import AssetMedia from './AssetMedia';
import {Asset} from '../utils/ApiInterface';

export interface AssetWebLayoutProps {
  asset: Asset;
}

const AssetWebLayout: React.FC<AssetWebLayoutProps> = (props) => {
  const {asset} = props;
  const {id: assetId} = asset;
  const dispatch = useDispatch();

  const externalUrl = React.useMemo(() => {
    if (AssetModel.TYPE_WEB === asset.type) {
      const {url} = asset;

      return url || '';
    }

    return '';
  }, [asset]);

  React.useEffect(() => {
    if (externalUrl) {
      window.open(externalUrl, '_blank');
    }
  }, [externalUrl, asset]);

  React.useEffect(() => {
    dispatch(PlayerStatusAction.setAssetIsEnding(assetId.toString(), true));
  }, [dispatch, assetId, asset]);

  return (
    <div className="background-9-16__asset_display overflow-hidden d-flex align-items-center justify-content-center">
      <div className="background-9-16">
        <div className="background-9-16__asset_display">
          <AssetMedia asset={asset} />
        </div>
      </div>
    </div>
  );
};

export default AssetWebLayout;
