import React from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface PdfPageReaderProps {
  pdfInstance: any;
  pageNumber: number;
  onPdfLoaded: VoidFunction;
}

const PdfPageReader: React.FC<PdfPageReaderProps> = (props) => {
  const {pdfInstance, pageNumber, onPdfLoaded} = props;
  const pdfPageRef = React.useRef<HTMLCanvasElement>(null);
  const onPdfLoadedRef = React.useRef<VoidFunction>(onPdfLoaded);

  React.useEffect(() => {
    const canvas = pdfPageRef.current;
    if (null === canvas) {
      return;
    }

    pdfInstance.getPage(pageNumber).then((page: any) => {
      const scale = 1;
      let viewport = page.getViewport({scale});

      if (null !== canvas.parentElement) {
        const desiredWidth = canvas.parentElement.offsetWidth;
        if (viewport.width > desiredWidth) {
          viewport = page.getViewport({scale: desiredWidth / viewport.width});
        }
      }

      const context = canvas.getContext('2d');
      if (context) {
        canvas.style.removeProperty('height');
        canvas.style.height = `${viewport.height}px`;
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport,
        };
        const renderTask = page.render(renderContext);
        renderTask.promise.then(() => {
          onPdfLoadedRef.current();
        });
      }
    });
  }, [pdfInstance, pageNumber]);

  return <canvas ref={pdfPageRef} className="pdf" />;
};

export default PdfPageReader;
