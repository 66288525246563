import React from 'react';
import salesSchoolLogo from '../../assets/SS_s_.png';

/**
 * Renders the sales school logo.
 */
const InternalAssetLogo: React.FC = () => {
  return (
    <div className="multimedia-card__logo">
      <img
        src={salesSchoolLogo}
        alt="sales-school"
        className="ss-detail-logo"
      />
    </div>
  );
};

export default InternalAssetLogo;
