import React, {useState} from 'react';
import classNames from 'classnames';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import routeCollection from '../router/routeCollection';
import UrlProcessor from '../utils/UrlProcessor';

export interface LogoutDropdownProps {
  className?: string;
}

const LogoutDropdown: React.FC<LogoutDropdownProps> = (props) => {
  const {className} = props;
  const location = useLocation();
  const currentPath = React.useMemo(() => location.pathname, [location]);
  const {t} = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggle = () => setIsDropdownOpen((prevState) => !prevState);

  return (
    <ButtonDropdown
      isOpen={isDropdownOpen}
      toggle={toggle}
      className={classNames('dropdown-option--logout', className)}
    >
      <DropdownToggle
        color="dark"
        className={classNames(
          'dropdown-option--toggle rounded btn--dark d-fex',
          {
            'dropdown-toggle--is-open': isDropdownOpen,
          },
        )}
      >
        {isDropdownOpen ? (
          <i className="icomoon icon-drop_up standard-icon-size--8 px-2" />
        ) : (
          <i className="icomoon icon-drop_down standard-icon-size--8 px-2" />
        )}
      </DropdownToggle>

      <DropdownMenu end color="dark">
        {routeCollection.termsCondition !== currentPath && (
          <DropdownItem
            tag={Link}
            to={UrlProcessor.createPath(routeCollection.termsCondition)}
          >
            {t('component.logoutDropdown.termAndCondition')}
          </DropdownItem>
        )}
        <DropdownItem
          tag={Link}
          to={UrlProcessor.createPath(routeCollection.logout)}
        >
          {t('component.logoutDropdown.logout')}
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default LogoutDropdown;
