import React from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {InputGroup, Button} from 'reactstrap';
import {RootState} from '../store/store';

export interface FilterFormSearchProps {
  saveSearch: (term: string) => void;
  activateSearch: () => void;
}

interface Form {
  filter: string;
}

const FilterFormSearch: React.FC<FilterFormSearchProps> = (props) => {
  const {saveSearch, activateSearch} = props;
  const {t} = useTranslation();
  const term = useSelector((state: RootState) => state.search.term);
  const {register, watch, setValue, handleSubmit} = useForm<Form>({
    mode: 'onChange',
    defaultValues: {filter: term},
  });

  React.useEffect(() => {
    if (term) {
      // If `term` in redux store is updated, `filter` value in form is updated.
      setValue('filter', term);
    }
  }, [term, setValue]);

  const {filter} = watch();

  const onSubmit = () => {
    activateSearch();
  };

  React.useEffect(() => {
    saveSearch(filter);
  }, [saveSearch, filter]);

  return (
    <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
      <InputGroup className="m-0">
        <input
          ref={register}
          name="filter"
          placeholder={t('page.search.inputPlaceholder')}
          type="text"
          autoComplete="off"
          className="form-control"
        />
        <Button
          color="dark"
          type="submit"
          className="filter-search__button btn--dark"
        >
          <i className="icomoon icon-search" />
        </Button>
      </InputGroup>
    </form>
  );
};

export default FilterFormSearch;
