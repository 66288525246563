class SearchType {
  public static get SAVE_SEARCH_OPTION(): string {
    return 'SAVE_SEARCH_OPTION';
  }

  public static get SAVE_CATEGORY_ID(): string {
    return 'SAVE_CATEGORY_ID';
  }

  public static get REMOVE_CATEGORY_ID(): string {
    return 'REMOVE_CATEGORY_ID';
  }

  public static get RESET_SEARCH(): string {
    return 'RESET_SEARCH';
  }
}

export default SearchType;
