import React from 'react';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import PdfPageReader from './PdfPageReader';
import Preloader from './Preloader';
import PlayerStatusAction from '../store/action/PlayerStatusAction';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface PdfPageListProps {
  pdfInstance: any;
  className?: string;
  assetId: number;
  totalNumberPage: number;
}

const PdfPageList: React.FC<PdfPageListProps> = (props) => {
  const dispatch = useDispatch();
  const {pdfInstance, className, assetId, totalNumberPage} = props;
  const [pages, setPages] = React.useState<number[]>([]);
  const [isLoadingPdfPage, setIsLoadingPdfPage] =
    React.useState<boolean>(false);

  const loadPage = (): void => {
    setIsLoadingPdfPage(true);
    setPages((prevValue) => [...prevValue, prevValue.length + 1]);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoadingPdfPage,
    hasNextPage: pages.length < totalNumberPage,
    onLoadMore: loadPage,
    rootMargin: `0px 0px 0px 0px`,
  });

  const onPdfLoaded = () => {
    setIsLoadingPdfPage(false);
  };

  React.useEffect(() => {
    if (0 < totalNumberPage && pages.length === totalNumberPage) {
      dispatch(PlayerStatusAction.setAssetIsLoaded(assetId.toString(), true));
    }
  }, [dispatch, totalNumberPage, pages, assetId]);

  return (
    <div className={classNames(className)}>
      {pages.map((page) => (
        <PdfPageReader
          key={`pdf_page_${page}`}
          pageNumber={page}
          pdfInstance={pdfInstance}
          onPdfLoaded={onPdfLoaded}
        />
      ))}

      {(isLoadingPdfPage || pages.length < totalNumberPage) && (
        <div
          className="d-flex justify-content-center align-items-center my-auto py-3"
          ref={sentryRef}
        >
          <div className="my-4">
            <Preloader />
          </div>
        </div>
      )}
    </div>
  );
};

export default PdfPageList;
