import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import makeGetSearchParameter from '../selector/makeGetSearchParameter';
import {RootState} from '../store/store';
import {GetAssetParam} from '../utils/ApiInterface';

export type UseGetSearchParameters = Pick<
  GetAssetParam,
  'competencyParent' | 'search'
>;

const useGetSearchParameters = (): UseGetSearchParameters => {
  const selectSearchParameters = useMemo(makeGetSearchParameter, []);

  const queryParams = useSelector((state: RootState) =>
    selectSearchParameters(state),
  );

  return queryParams;
};

export default useGetSearchParameters;
