import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export interface MakeGetCategoryIdsByContext {
  context: string;
}

const makeGetCategoryIdsByContext = () =>
  createDeepEqualSelector(
    [
      (state: RootState) => state.categories.category,
      (state: RootState, props: MakeGetCategoryIdsByContext) => props.context,
    ],
    (categoryIdsByContext, context) => {
      return Object.keys(categoryIdsByContext).filter(
        (id) => categoryIdsByContext[id].parent === context,
      );
    },
  );

export default makeGetCategoryIdsByContext;
