import React from 'react';
import {isMobile} from 'react-device-detect';
import HeroContentMobile from './HeroContentMobile';
import HeroContentWeb from './HeroContentWeb';
import useUpdateQueryParameters from '../hook/useUpdateQueryParameters';
import useGetQueryParameters from '../hook/useGetQueryParameters';
import {useGetAssetQuery} from '../store/service/assetService';
import Preloader from './Preloader';

export interface HeroContentProps {
  assetId: number;
}

const HeroContent: React.FC<HeroContentProps> = (props) => {
  const {assetId} = props;
  const {data: asset, isLoading} = useGetAssetQuery(assetId);
  const {getUrl} = useUpdateQueryParameters();
  const queryParameters = useGetQueryParameters();

  const detailPath = React.useMemo(() => {
    return getUrl({...queryParameters, asset: assetId.toString()});
  }, [getUrl, assetId, queryParameters]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Preloader />
      </div>
    );
  }

  if (undefined === asset) {
    return null;
  }

  if (isMobile) {
    return (
      <HeroContentMobile
        isExternal={asset.external}
        assetId={assetId}
        title={asset.title}
        assetUrlPath={detailPath}
        content={asset.content}
      />
    );
  }

  return (
    <HeroContentWeb
      isExternal={asset.external}
      assetId={assetId}
      title={asset.title}
      detailPath={detailPath}
      content={asset.content}
    />
  );
};

export default HeroContent;
