import React from 'react';
import {NavLink as RouteLink, NavLinkProps} from 'react-router-dom';
import {NavItem, NavLink} from 'reactstrap';

interface MenuNavItemProps extends Omit<NavLinkProps, 'activeClassName'> {
  className?: string;
}

const MenuNavItem = (
  props: React.PropsWithChildren<MenuNavItemProps>,
): JSX.Element => {
  const {children, ...properties} = props;

  return (
    <NavItem>
      <NavLink tag={RouteLink} activeClassName="active" {...properties}>
        {children}
      </NavLink>
    </NavItem>
  );
};

export default MenuNavItem;
