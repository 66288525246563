import React from 'react';
import Hero from './Hero';
import Header from './Header';

export interface HeroHeaderProps {
  assetId?: number;
}

const HeroHeader = (
  props: React.PropsWithChildren<HeroHeaderProps>,
): JSX.Element => {
  const {children, assetId} = props;

  return (
    <Hero assetId={assetId}>
      <Header />

      {children}
    </Hero>
  );
};

export default HeroHeader;
