import {createApi} from '@reduxjs/toolkit/query/react';
import requestBase from './requestBase';

// initialize an empty api service that we'll inject endpoints into later as needed
const apiService = createApi({
  reducerPath: 'apiService',
  baseQuery: requestBase({baseUrl: ''}),
  endpoints: () => ({}),
  tagTypes: ['userAssetHistoryService'],
});

export default apiService;
