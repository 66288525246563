import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import ApiAction from '../store/action/ApiAction';
import useGetAssetsByCategoryId from '../hook/useGetAssetsByCategoryId';
import Preloader from './Preloader';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import ErrorMessage from './ErrorMessage';
import AssetAction, {
  getLastAssetsFallback,
  getSearchAssets,
} from '../store/action/AssetAction';
import {AppDispatch} from '../store/store';
import ApiType from '../store/type/ApiType';

/**
 * It is responsible for searching for the latest videos uploaded in "descending" order of creation.
 *
 * @todo The name of the component is not according to your responsibility. It must be renamed and analyzed if performing this action in this "HOC" is the most correct.
 */
function newAssetHOC(Component: React.FC): React.FC {
  function NewAssetHOC() {
    const {t} = useTranslation();
    const [isFallbackDispatched, setIsFallbackDispatched] =
      React.useState(false);

    const dispatch = useDispatch<AppDispatch>();
    const lastAssetsLabel = React.useMemo(
      () => ApiAction.labelToGetLastAssets(),
      [],
    );

    const [lastAssetsRequest, setLastAssetsRequest] = React.useState(
      AsyncActionStatus.NOT_EXECUTED,
    );
    const assets = useGetAssetsByCategoryId(lastAssetsLabel);

    React.useEffect(() => {
      let abortPromise: VoidFunction | undefined;
      if (0 === assets.length) {
        const promise = dispatch(getLastAssetsFallback({}));
        abortPromise = promise.abort;
        setLastAssetsRequest(AsyncActionStatus.PENDING);

        promise
          .unwrap()
          .then((response) => {
            dispatch(
              AssetAction.saveAssetsByCategory(
                ApiAction.labelToGetLastAssets(),
                response,
              ),
            );
            setLastAssetsRequest(AsyncActionStatus.SUCCESS);

            if (0 === response['hydra:member'].length) {
              setIsFallbackDispatched(true);
            }
          })
          .catch(() => {
            setLastAssetsRequest(AsyncActionStatus.FAILURE);
          })
          .finally(() => {
            abortPromise = undefined;
          });
      }

      return () => {
        if (abortPromise) {
          abortPromise();
        }
      };
    }, [assets, dispatch]);

    React.useEffect(() => {
      let abortPromise: VoidFunction | undefined;
      if (isFallbackDispatched) {
        const promise = dispatch(getSearchAssets({isPublic: true}));
        abortPromise = promise.abort;

        const label = ApiAction.labelToGetLastAssets();
        dispatch({
          type: ApiType.API_INIT,
          payload: {label},
        });

        promise
          .unwrap()
          .then((response) => {
            dispatch(AssetAction.saveAssetsByCategory(label, response));
            dispatch({
              type: ApiType.API_SUCCESS,
              payload: {label},
            });
          })
          .catch((error) => {
            dispatch({
              type: ApiType.API_FAILURE,
              payload: {label, error},
            });
          })
          .finally(() => {
            abortPromise = undefined;
          });
      }

      return () => {
        if (abortPromise) {
          abortPromise();
        }
      };
    }, [isFallbackDispatched, dispatch]);

    if (
      0 === assets.length &&
      (AsyncActionStatus.NOT_EXECUTED === lastAssetsRequest ||
        AsyncActionStatus.PENDING === lastAssetsRequest ||
        !isFallbackDispatched)
    ) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Preloader />
        </div>
      );
    }

    if (0 === assets.length) {
      return (
        <ErrorMessage>
          <h1>{t('page.notContent')}</h1>
        </ErrorMessage>
      );
    }

    return <Component />;
  }

  const componentName = Component.displayName || Component.name || 'component';
  NewAssetHOC.displayName = componentName;

  return NewAssetHOC;
}

export default newAssetHOC;
