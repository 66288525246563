export enum AssetLayoutActionEnum {
  QUALIFICATION = 'qualification',
  SUGGEST = 'suggest',
  RANKING = 'ranking',
  TRAINING = 'training_program',
}

class AssetLayout {
  public static ASSET_LAYOUT_ACTION = 'action';

  public static ASSET_LAYOUT_ACTION_SUGGEST_VALUE =
    AssetLayoutActionEnum.SUGGEST;

  public static ASSET_LAYOUT_ACTION_QUESTION_VALUE =
    AssetLayoutActionEnum.QUALIFICATION;

  public static ASSET_LAYOUT_ACTION_RANKING_VALUE =
    AssetLayoutActionEnum.RANKING;

  public static ASSET_LAYOUT_ACTION_TRAINING_VALUE =
    AssetLayoutActionEnum.TRAINING;

  public static get availableAssetLayoutAction(): AssetLayoutActionEnum[] {
    return [
      AssetLayout.ASSET_LAYOUT_ACTION_SUGGEST_VALUE,
      AssetLayout.ASSET_LAYOUT_ACTION_QUESTION_VALUE,
      AssetLayout.ASSET_LAYOUT_ACTION_RANKING_VALUE,
    ];
  }

  public static getAssetLayoutAction(option: string): AssetLayoutActionEnum {
    const action = AssetLayout.availableAssetLayoutAction.find(
      (delayedNotification) => delayedNotification === option,
    );

    if (!action) {
      throw new Error(
        `Argument 1 passed to \`AssetLayout.getAssetLayoutAction()\` must be one of ${AssetLayout.availableAssetLayoutAction
          .map((actionValue) => `"${actionValue}"`)
          .join(', ')}; "${option}" given.`,
      );
    }

    return action;
  }
}

export default AssetLayout;
