import React from 'react';
import {isMobile} from 'react-device-detect';
import {useSelector} from 'react-redux';
import HeroWebByAssetType from './HeroWebByAssetType';
import HeroMobile from './HeroMobile';
import {RootState} from '../store/store';

export interface HeroProps {
  className?: string;
  assetId: number;
}

const HeroAsset = (props: React.PropsWithChildren<HeroProps>): JSX.Element => {
  const {children, assetId} = props;

  const asset = useSelector((state: RootState) => state.assets.assets[assetId]);

  if (isMobile) {
    return <HeroMobile asset={asset}>{children}</HeroMobile>;
  }

  return <HeroWebByAssetType asset={asset}>{children}</HeroWebByAssetType>;
};

export default HeroAsset;
