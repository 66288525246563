class AuthenticationType {
  public static get SIGN_IN(): string {
    return 'SIGN_IN';
  }

  public static get SIGN_OUT(): string {
    return 'SIGN_OUT';
  }
}

export default AuthenticationType;
