import React from 'react';

const Preloader = (): JSX.Element => {
  return (
    <div className="container-spinner">
      <div className="lds-spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Preloader;

/* <div className="loadingio-spinner-pulse-f1ahebhjvjo">
  <div className="ldio-t0ic04e2g6">
    <div />
    <div />
    <div />
  </div>
</div> */
