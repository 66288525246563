import React from 'react';
import {ButtonProps} from 'reactstrap';
import {AssetLayoutActionEnum} from '../class/AssetLayout';
import ButtonLinkToPlayerTrainer from './ButtonLinkToPlayerTrainer';
import ButtonLinkToPlayer from './ButtonLinkToPlayer';
import {useGetAssetQuery} from '../store/service/assetService';

export interface AssetLinkToPlayerProps extends ButtonProps {
  assetId: number;
  className?: string;
}

/**
 * Renders an anchor with the link to the player.
 */
const AssetLinkToPlayer: React.FC<AssetLinkToPlayerProps> = (props) => {
  const {assetId, className, children, ...properties} = props;
  const {data: asset} = useGetAssetQuery(assetId);

  if (undefined === asset) {
    return null;
  }

  if (AssetLayoutActionEnum.TRAINING === asset.type) {
    return (
      <ButtonLinkToPlayerTrainer
        assetId={assetId}
        className={className}
        {...properties}
      >
        {children}
      </ButtonLinkToPlayerTrainer>
    );
  }

  return (
    <ButtonLinkToPlayer assetId={assetId} className={className} {...properties}>
      {children}
    </ButtonLinkToPlayer>
  );
};

export default AssetLinkToPlayer;
