import {BaseQueryFn} from '@reduxjs/toolkit/query/react';
import {AxiosRequestConfig, AxiosError, Method} from 'axios';
import api from '../../utils/api';

const requestBase =
  (
    {baseUrl}: {baseUrl: string} = {baseUrl: ''},
  ): BaseQueryFn<
    {
      url: string;
      method: Method;
      config?: Omit<AxiosRequestConfig, 'method' | 'url' | 'baseUrl'>;
    },
    unknown,
    unknown
  > =>
  async ({url, method, config = {}}) => {
    const {headers, ...configurations} = config;
    try {
      const authorizationHeader = await api.getAuthorizationHeader();
      const request = await api.api({...authorizationHeader, ...headers});

      await api.validateCookie();

      const result = await request({
        method,
        ...configurations,
        url: baseUrl + url,
      });

      return {data: result.data};
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {status: err.response?.status, data: err.response?.data},
      };
    }
  };

export default requestBase;
