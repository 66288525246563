import {Auth} from 'aws-amplify';
import storage from './storage';

class UserSession {
  private TOKEN_KEY: string;

  public constructor(tokenKey: string) {
    this.TOKEN_KEY = tokenKey;
  }

  public storageToken = (token: string) => {
    storage.setItem(this.TOKEN_KEY, token);
  };

  public removeAuthenticatedUser = () => {
    storage.removeItem(this.TOKEN_KEY);
  };

  public isAuthenticated = () => {
    return Auth.currentAuthenticatedUser();
  };

  public getSession = () => {
    return Auth.currentSession();
  };

  public signOut = () => {
    return Auth.signOut();
  };
}

export default new UserSession('AUTHENTICATION_TOKEN');
