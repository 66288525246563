import React from 'react';
import UserQualification from './UserQualification';
import useUpdateQueryParameters from '../hook/useUpdateQueryParameters';
import useGetQueryParameters from '../hook/useGetQueryParameters';

interface AssetQualificationModalUIProps {
  assetId: number;
}

/**
 * Renders the UI of an asset's qualification form on a modal.
 */
const AssetQualificationModalUI: React.FC<AssetQualificationModalUIProps> = (
  props,
) => {
  const {assetId} = props;
  const {updateHistoryBrowser} = useUpdateQueryParameters();
  const queryParameters = useGetQueryParameters();

  const redirectToDetail = React.useCallback(() => {
    // `queryParameters` is destructed to omit `asset` and `action` values.
    const {asset, action, ...parameters} = queryParameters;
    updateHistoryBrowser({
      ...parameters,
      asset: assetId.toString(),
    });
  }, [assetId, queryParameters, updateHistoryBrowser]);

  return (
    <div className="multimedia-card">
      <UserQualification
        assetId={assetId}
        onSuccessCallback={redirectToDetail}
        onCancelCallback={redirectToDetail}
      />
    </div>
  );
};

export default AssetQualificationModalUI;
