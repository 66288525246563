import React from 'react';
import {useTranslation} from 'react-i18next';

export interface AssetViewNumberProps {
  totalViews?: number;
}

/**
 * Renders the view number of an asset.
 */
const AssetViewNumber: React.FC<AssetViewNumberProps> = (props) => {
  const {totalViews = 0} = props;
  const {t} = useTranslation();

  return (
    <p className="multimedia-card__view">
      {t('multimedia.view', {count: totalViews})}
    </p>
  );
};

export default React.memo(AssetViewNumber);
