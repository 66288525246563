import {useMemo} from 'react';
import useGetAvailableLanguage from './useGetAvailableLanguage';
import useGetQueryParameters from './useGetQueryParameters';

const useGetLanguageQueryAsset = (): string => {
  const queryParameters = useGetQueryParameters();
  const availableLanguages = useGetAvailableLanguage();

  return useMemo(() => {
    const {language: queryLanguage} = queryParameters;

    if ('string' === typeof queryLanguage) {
      const currentLanguage = availableLanguages.find(
        (language) => language.id === queryLanguage.toLowerCase(),
      );

      if (currentLanguage) {
        return currentLanguage.id;
      }
    }

    return '';
  }, [queryParameters, availableLanguages]);
};

export default useGetLanguageQueryAsset;
