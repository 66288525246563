import React from 'react';
import HeroAsset from './HeroAsset';
import HeroLayout from './HeroLayout';

export interface HeroProps {
  className?: string;
  assetId?: number;
}

const Hero = (props: React.PropsWithChildren<HeroProps>): JSX.Element => {
  const {children, assetId} = props;

  if (assetId) {
    return <HeroAsset assetId={assetId}>{children}</HeroAsset>;
  }

  return (
    <HeroLayout>
      <div className="hero__content">{children}</div>
    </HeroLayout>
  );
};

export default Hero;
