import React from 'react';
import classNames from 'classnames';

export interface MultimediaCardDescriptionProps {
  content: string;
  className?: string;
}

const MultimediaCardDescription = (
  props: React.PropsWithChildren<MultimediaCardDescriptionProps>,
): JSX.Element => {
  const {className, content} = props;

  return (
    <div className={classNames('multimedia-card__general', className)}>
      <p className="multimedia-card__description m-0">{content}</p>
    </div>
  );
};

export default MultimediaCardDescription;
