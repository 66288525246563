import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import {isMobile} from 'react-device-detect';
import {Navigation, Pagination, Thumbs} from 'swiper';
import {Asset} from '../utils/ApiInterface';

export interface CarouselComponentProps {
  carouselKey: string;
  slideData: Asset[];
  Children: React.ElementType;
}

const CarouselComponent = (
  props: React.PropsWithChildren<CarouselComponentProps>,
): JSX.Element => {
  const {slideData, carouselKey, Children} = props;
  return (
    <>
      <Swiper
        updateOnWindowResize
        modules={[Navigation, Pagination, Thumbs]}
        preloadImages
        tag="section"
        navigation={!isMobile}
        slidesPerView={2}
        slidesPerGroup={2}
        breakpoints={{
          500: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          800: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          1100: {
            slidesPerView: 5,
            slidesPerGroup: 5,
          },
          1400: {
            slidesPerView: 6,
            slidesPerGroup: 6,
          },
        }}
      >
        {slideData.map((slide) => (
          <SwiperSlide key={`${carouselKey}_${slide.id}`}>
            <Children asset={slide} showThumbnail />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CarouselComponent;
