import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {RootState} from '../store/store';
import QualificationOption from './QualificationOption';

export interface CommentRowProps {
  qualificationId: string;
}

const CommentRow: React.FC<CommentRowProps> = (props) => {
  const {qualificationId} = props;
  const qualification = useSelector(
    (state: RootState) => state.qualification.qualification[qualificationId],
  );
  const {comment, score, createdAt, user} = qualification;
  const {firstname, lastname} = user;
  const date = moment(createdAt);

  return (
    <div className="d-flex align-items-center flex-row comment__row">
      <div className="me-3">
        <div className="comment__picture">
          <QualificationOption className="comment__star" option={score} />
        </div>
      </div>
      <div className="w-100">
        <div
          className={classNames(
            'd-flex justify-content-between align-items-center',
            {'mb-2': comment},
          )}
        >
          <h6 className="comment__username mb-0">{`${lastname} ${firstname}`}</h6>
          <span className="ms-2 font-size--small">
            {date.format('DD MMMM YYYY')}
          </span>
        </div>
        <div className="comment__text font-size--regular">{comment}</div>
      </div>
    </div>
  );
};

export default CommentRow;
