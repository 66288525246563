import apiService from './apiService';
import {
  HydraResponse,
  GetUserAssetHistoriesParam,
  AssetHistory,
} from '../../utils/ApiInterface';

const userAssetHistoryService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getUserAssetHistories: builder.query<
      HydraResponse<AssetHistory[]>,
      GetUserAssetHistoriesParam
    >({
      query: (params) => ({
        url: '/user_asset_histories',
        method: 'get',
        config: {
          params,
          headers: {
            'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
          },
        },
      }),
      providesTags: (result) => {
        const {'hydra:member': histories = []} = result || {};
        const [history] = histories;
        if (history) {
          let historyKey = `history_${history.asset.id}`;

          if (history.parent) {
            historyKey += `_${history.parent.asset.id}`;
          }

          return [
            {type: 'userAssetHistoryService', id: 'Histories'},
            {type: 'userAssetHistoryService', id: historyKey} as const,
          ];
        }

        return [{type: 'userAssetHistoryService', id: 'Histories'}];
      },
    }),
    getUserAssetHistory: builder.query<AssetHistory, number>({
      query: (id: number) => ({
        url: `/user_asset_histories/${id}`,
        method: 'get',
        config: {
          headers: {
            'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
          },
        },
      }),
    }),
  }),
});

export const {useGetUserAssetHistoriesQuery, useGetUserAssetHistoryQuery} =
  userAssetHistoryService;

export default userAssetHistoryService;
