import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../store/store';
import makeGetQualificationIdsByAssetId from '../selector/makeGetQualificationIdsByAssetId';

const useGetUserQualificationIdsByAsset = (assetId: number): string[] => {
  const selectQualificationIds = useMemo(makeGetQualificationIdsByAssetId, []);

  return useSelector((state: RootState) =>
    selectQualificationIds(state, {assetId}),
  );
};

export default useGetUserQualificationIdsByAsset;
