import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import makeIsUserProfileLoaded from '../selector/makeIsUserProfileLoaded';
import {RootState} from '../store/store';

const useGetIsUserProfileLoaded = (): boolean => {
  const selectIsUserProfileLoaded = useMemo(makeIsUserProfileLoaded, []);

  const userQualification = useSelector((state: RootState) =>
    selectIsUserProfileLoaded(state),
  );

  return userQualification;
};

export default useGetIsUserProfileLoaded;
