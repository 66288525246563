import React from 'react';
import {ParsedQuery} from 'query-string';
import {useLocation} from 'react-router-dom';
import UrlProcessor from '../utils/UrlProcessor';

/**
 * Retrieves the parameters passed by URL.
 */
const useGetQueryParameters = (): ParsedQuery => {
  const {search} = useLocation();

  const queryParameters = React.useMemo(() => {
    return UrlProcessor.queryToObject(search) || {};
  }, [search]);

  return queryParameters;
};

export default useGetQueryParameters;
