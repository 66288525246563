import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import {Button} from 'reactstrap';
import {AssetLayoutActionEnum} from '../class/AssetLayout';
import useGetQueryParameters from '../hook/useGetQueryParameters';
import routeCollection from '../router/routeCollection';
import {useGetHierarchiesQuery} from '../store/service/assetHierarchyService';
import {Asset, OrderOption} from '../utils/ApiInterface';
import UrlProcessor from '../utils/UrlProcessor';
import ContinueWatchingButton from './ContinueWatchingButton';
import PreviewNextAssetAction from './PreviewNextAssetAction';
import PreviewNextAssetDetail from './PreviewNextAssetDetail';

export interface ShowPreviewNextTrainingProps {
  currentAsset: Asset;
  trainer: Asset;
}

const ShowPreviewNextTraining: React.FC<ShowPreviewNextTrainingProps> = (
  props,
) => {
  const {t} = useTranslation();
  const {trainer, currentAsset} = props;
  const queryParameters = useGetQueryParameters();

  const {data, isLoading} = useGetHierarchiesQuery({
    'parent.id': [trainer.id],
    'order[position]': OrderOption.asc,
  });

  const currentIndex = React.useMemo(() => {
    if (undefined === data) {
      return undefined;
    }

    const current = data['hydra:member'].find(
      (hierarchy) => hierarchy.childId === currentAsset.id,
    );

    return current?.position;
  }, [data, currentAsset]);

  const nextAssetId = React.useMemo(() => {
    if (undefined !== currentIndex && undefined !== data) {
      const nextPosition = data['hydra:member'].find(
        (hierarchy) => hierarchy.position === currentIndex + 1,
      );

      return nextPosition?.childId;
    }

    return undefined;
  }, [data, currentIndex]);

  const assetUrlPath = React.useMemo(
    () =>
      UrlProcessor.createPath(
        routeCollection.detail,
        {
          id: trainer.id,
        },
        {
          ...queryParameters,
          action: AssetLayoutActionEnum.QUALIFICATION,
        },
      ),
    [trainer, queryParameters],
  );

  const restoreWindows = React.useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return null;
  }

  if (undefined !== currentIndex && undefined !== nextAssetId) {
    return (
      <div className="player-layout__overlay">
        <div className="container_inner py-3 h-100 d-flex justify-content-end align-items-end">
          <div className="player-layout__next-element">
            <PreviewNextAssetDetail asset={currentAsset} />
            <PreviewNextAssetAction
              currentAsset={currentAsset}
              nextAssetId={nextAssetId}
              trainer={trainer}
            />
          </div>
        </div>
      </div>
    );
  }

  if (
    undefined !== data &&
    undefined !== currentIndex &&
    currentIndex === data['hydra:member'].length - 1
  ) {
    return (
      <div className="player-layout__overlay">
        <div className="container_inner py-3 h-100 d-flex justify-content-end align-items-end">
          <div className="player-layout__next-element">
            <ContinueWatchingButton asset={currentAsset} trainer={trainer} />

            <Button
              color="dark"
              className="btn--dark"
              tag={Link}
              to={assetUrlPath}
              onClick={restoreWindows}
            >
              {t('component.qualifyButton')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return <Redirect to={assetUrlPath} />;
};

export default ShowPreviewNextTraining;
