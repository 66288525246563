import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import makeGetIfUserRateContent from '../selector/makeGetIfUserRateContent';
import ApiAction from '../store/action/ApiAction';
import AssetAction from '../store/action/AssetAction';
import {getUserQualificationsByAssetId} from '../store/action/userAssetQualification';
import {AppDispatch, RootState} from '../store/store';
import ApiType from '../store/type/ApiType';

const useGetUserQualificationByAsset = (assetId: number): void => {
  const dispatch = useDispatch<AppDispatch>();

  const selector = useMemo(makeGetIfUserRateContent, []);
  const ifUserRate = useSelector((state: RootState) =>
    selector(state, {assetId}),
  );

  const userId = useSelector((state: RootState) => {
    const {
      user: {authenticatedUser},
    } = state;
    return authenticatedUser;
  });

  useEffect(() => {
    let abortPromise: VoidFunction | undefined;
    if (!ifUserRate && userId) {
      const label = ApiAction.labelToGetUserQualificationByAssetId(
        userId,
        assetId,
      );

      dispatch({
        type: ApiType.API_INIT,
        payload: {label},
      });
      const promise = dispatch(getUserQualificationsByAssetId({assetId}));
      abortPromise = promise.abort;

      promise
        .unwrap()
        .then((response) => {
          dispatch(AssetAction.saveQualificationsByAsset(assetId, response));
          dispatch({
            type: ApiType.API_SUCCESS,
            payload: {label},
          });
        })
        .catch((error) => {
          dispatch({
            type: ApiType.API_FAILURE,
            payload: {label, error},
          });
        })
        .finally(() => {
          abortPromise = undefined;
        });
    }

    return () => {
      if (abortPromise) {
        abortPromise();
      }
    };
  }, [dispatch, ifUserRate, userId, assetId]);
};

export default useGetUserQualificationByAsset;
