import React from 'react';

const SkeletonSlider = (): JSX.Element => {
  return (
    <div className="skeleton_slider">
      <div>
        <div className="pulsate" />
      </div>
      <div>
        <div className="pulsate" />
      </div>
      <div>
        <div className="pulsate" />
      </div>
      <div>
        <div className="pulsate" />
      </div>
      <div>
        <div className="pulsate" />
      </div>
      <div>
        <div className="pulsate" />
      </div>
      <div>
        <div className="pulsate" />
      </div>
      <div>
        <div className="pulsate" />
      </div>
    </div>
  );
};

export default SkeletonSlider;
