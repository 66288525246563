import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../store/store';
import ShowPreviewNextTraining from '../component/ShowPreviewNextTraining';
import {Asset} from '../utils/ApiInterface';

export interface PreviewAssetProps {
  asset: Asset;
  trainer: Asset;
}

const PreviewAsset: React.FC<PreviewAssetProps> = (props) => {
  const {asset, trainer} = props;

  const isAssetEnding = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[asset.id];

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isEnding;
  });

  if (false === isAssetEnding) {
    return null;
  }

  return <ShowPreviewNextTraining currentAsset={asset} trainer={trainer} />;
};

export default PreviewAsset;
