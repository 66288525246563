import React from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import VideoPlayer from './VideoPlayer';
import usePlayer from '../hook/usePlayer';
import {Media} from '../utils/ApiInterface';
import useGetMediaToPlayer from '../hook/useGetMediaToPlayer';
import useCanPlayAsset from '../hook/useCanPlayAsset';
import PlayerStatusAction from '../store/action/PlayerStatusAction';
import {RootState} from '../store/store';
import useGetLanguageQueryAsset from '../hook/useGetLanguageQueryAsset';
import useFilterMediaByLanguage from '../hook/useFilterMediaByLanguage';
import useGetTrack from '../hook/useGetTrack';

export interface HeroWebProps {
  className?: string;
  playerClassName?: string;
  canPlay: boolean;
  identifier: string;
  poster?: string;
  medias: Media[];
}

const PlayerHandler: React.FC<HeroWebProps> = (props) => {
  const {medias, poster, className, canPlay, identifier} = props;
  const dispatch = useDispatch();

  const languageQueryParameter = useGetLanguageQueryAsset();
  const media = useFilterMediaByLanguage(medias, languageQueryParameter);
  const sources = useGetMediaToPlayer(media);
  const tracks = useGetTrack(media);
  const target = React.useRef<HTMLDivElement>(null);
  const isVisible = useCanPlayAsset(target);
  const [isPlayerReady, setIsPlayerReady] = React.useState(false);

  const option = React.useMemo(
    () => ({
      autoplay: false,
      controls: false,
      muted: true,
      sources,
      tracks,
      poster,
    }),
    [sources, tracks, poster],
  );

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const playerRef = usePlayer(videoRef, option);

  React.useEffect(() => {
    const player = playerRef.current;
    const onCanplaythrough = () => {
      dispatch(PlayerStatusAction.setAssetIsLoaded(identifier, true));
    };

    if (player) {
      player.on('canplaythrough', onCanplaythrough);
    }

    return () => {
      if (null !== player) {
        player.off('canplaythrough', onCanplaythrough);
      }
    };
  }, [dispatch, playerRef, identifier]);

  React.useEffect(() => {
    const player = playerRef.current;

    const onReady = () => {
      if (player) {
        dispatch(
          PlayerStatusAction.setAssetPlayerInit(identifier, {
            isMuted: player.muted(),
            isPlaying: !player.paused(),
            isEnding: false,
            isLoaded: false,
            isExecutable: false,
          }),
        );

        setIsPlayerReady(true);
      }
    };

    if (player) {
      player.on('ready', onReady);
    }

    return () => {
      if (null !== player) {
        player.off('ready', onReady);
      }
    };
  }, [dispatch, playerRef, identifier]);

  React.useEffect(() => {
    const player = playerRef.current;
    const onEnded = () => {
      dispatch(PlayerStatusAction.setAssetIsEnding(identifier, true));
    };

    if (player) {
      player.on('ended', onEnded);
    }

    return () => {
      if (null !== player) {
        player.off('ended', onEnded);
      }
    };
  }, [dispatch, playerRef, identifier]);

  React.useEffect(() => {
    const player = playerRef.current;
    const onPlay = () => {
      dispatch(PlayerStatusAction.setAssetStatus(identifier, true));
    };

    if (player) {
      player.on('play', onPlay);
    }

    return () => {
      if (null !== player) {
        player.off('play', onPlay);
      }
    };
  }, [dispatch, playerRef, identifier]);

  React.useEffect(() => {
    const player = playerRef.current;
    const onPause = () => {
      dispatch(PlayerStatusAction.setAssetStatus(identifier, false));
    };

    if (player) {
      player.on('pause', onPause);
    }

    return () => {
      if (null !== player) {
        player.off('pause', onPause);
      }
    };
  }, [dispatch, playerRef, identifier]);

  const isAssetPlaying = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[identifier];

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isPlaying;
  });

  const isAssetEnding = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[identifier];

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isEnding;
  });

  const isMuted = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;

    if (
      undefined === playerStatus[identifier] ||
      !playerStatus[identifier].isExecutable ||
      !playerStatus[identifier].isLoaded
    ) {
      return true;
    }

    return playerStatus[identifier].isMuted;
  });

  React.useEffect(() => {
    const player = playerRef.current;
    if (!player || !isPlayerReady) {
      return;
    }

    const isExecutable = canPlay && isVisible;

    dispatch(PlayerStatusAction.setAssetIsExecutable(identifier, isExecutable));
    if (!isAssetEnding) {
      dispatch(PlayerStatusAction.setAssetStatus(identifier, isExecutable));
    }
    if (!isExecutable) {
      player.pause();
    }
  }, [
    dispatch,
    isVisible,
    identifier,
    canPlay,
    isPlayerReady,
    playerRef,
    isAssetEnding,
  ]);

  React.useEffect(() => {
    const player = playerRef.current;
    if (!isPlayerReady || !player || null === playerRef.current) {
      return;
    }

    if (isAssetPlaying) {
      const isPlayerPlaying = !playerRef.current.paused();
      if (isPlayerPlaying === isAssetPlaying || isAssetEnding) {
        return;
      }

      const promisePlay = player.play();

      if (promisePlay) {
        promisePlay.catch(() => {
          dispatch(PlayerStatusAction.setAssetStatus(identifier, false));
        });
      }
    } else if (!player.paused()) {
      player.pause();
    }
  }, [
    isAssetPlaying,
    isPlayerReady,
    playerRef,
    identifier,
    dispatch,
    isAssetEnding,
  ]);

  React.useEffect(() => {
    const player = playerRef.current;
    if (!player || !isPlayerReady) {
      return;
    }

    if (isMuted !== player.muted()) {
      player.muted(isMuted);
    }
  }, [playerRef, isMuted, isPlayerReady]);

  return (
    <div
      className={classNames(className, {
        'movie--ending': isAssetEnding,
      })}
      ref={target}
    >
      <VideoPlayer ref={videoRef} />
    </div>
  );
};

export default PlayerHandler;
