import {AnyAction} from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';
import merge from 'lodash/merge';
import SearchType from '../type/SearchType';
import {
  AddCategoryIdInSearchAction,
  RemoveCategoryIdInSearchAction,
  SearchOptionAction,
} from '../interface';
import AuthenticationType from '../type/AuthenticationType';

export interface SearchReducer {
  term: string;
  categoryIds: Record<string, string[]>;
}

export const initialState: SearchReducer = {
  term: '',
  categoryIds: {},
};

const searchReducer = (
  state = initialState,
  action: AnyAction,
): SearchReducer => {
  const {type, payload} = action;

  switch (type) {
    case SearchType.SAVE_SEARCH_OPTION: {
      const {term} = payload as SearchOptionAction;

      return merge({}, state, {term});
    }

    case SearchType.SAVE_CATEGORY_ID: {
      const {categoryId, context} = payload as AddCategoryIdInSearchAction;

      const current = state.categoryIds[context];

      return {
        ...state,
        categoryIds: {
          ...state.categoryIds,
          [context]: current ? uniq([categoryId, ...current]) : [categoryId],
        },
      };
    }

    case SearchType.REMOVE_CATEGORY_ID: {
      const {categoryId, context} = payload as RemoveCategoryIdInSearchAction;

      const current = state.categoryIds[context];

      if (!current) {
        return state;
      }

      return {
        ...state,
        categoryIds: {
          ...state.categoryIds,
          [context]: current.filter((id) => id !== categoryId),
        },
      };
    }

    case AuthenticationType.SIGN_OUT: {
      return {...initialState};
    }

    default: {
      return state;
    }
  }
};

export default searchReducer;
