import apiService from './apiService';
import {
  GetAssetParam,
  Asset,
  HydraResponse,
  GetStartReproductionParam,
  GetUpdateLastPositionParam,
} from '../../utils/ApiInterface';

const assetService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getAssets: builder.query<HydraResponse<Asset[]>, GetAssetParam>({
      query: (params: GetAssetParam) => ({
        url: '/assets',
        method: 'get',
        config: {params},
      }),
    }),
    getAsset: builder.query<Asset, number>({
      query: (id: number) => ({
        url: `/assets/${id}`,
        method: 'get',
      }),
    }),
    startReproduction: builder.mutation<void, GetStartReproductionParam>({
      query: ({id, body = {}}) => ({
        url: `/assets/${id}/start-reproduction`,
        method: 'post',
        config: {data: body},
      }),
      invalidatesTags: (result, error, {id, body = {}}) => {
        let historyKey = `history_${id}`;

        if (body && body.parentAsset) {
          const chunkParentName = body.parentAsset.split('/');
          const trainerId = parseInt(
            chunkParentName[chunkParentName.length - 1],
            10,
          );
          historyKey += `_${trainerId}`;
        }

        return [{type: 'userAssetHistoryService', id: historyKey}];
      },
    }),
    updateLastPosition: builder.mutation<void, GetUpdateLastPositionParam>({
      query: ({id, body}) => ({
        url: `/assets/${id}/update-last-position`,
        method: 'post',
        config: {data: body},
      }),
      invalidatesTags: (result, error, {id, body}) => {
        let historyKey = `history_${id}`;

        if (body.parentAsset) {
          const chunkParentName = body.parentAsset.split('/');
          const trainerId = parseInt(
            chunkParentName[chunkParentName.length - 1],
            10,
          );
          historyKey += `_${trainerId}`;
        }

        return [{type: 'userAssetHistoryService', id: historyKey}];
      },
    }),
  }),
});

export const {
  useGetAssetQuery,
  useGetAssetsQuery,
  useStartReproductionMutation,
  useUpdateLastPositionMutation,
} = assetService;

export default assetService;
