import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../store/store';
import FilterList from './FilterList';

export interface FilterListByCategoryProps {
  context: string;
}

const FilterListByCategory: React.FC<FilterListByCategoryProps> = (props) => {
  const {context} = props;

  const category = useSelector((state: RootState) => {
    const {
      categories: {categoryParent},
    } = state;

    const data = categoryParent[context];

    if (!data) {
      return undefined;
    }

    return data;
  });

  if (!category) {
    return null;
  }

  return <FilterList context={context} category={category} />;
};

export default FilterListByCategory;
