import React from 'react';
import {useSelector} from 'react-redux';
import useGetRequestStatus from '../hook/useGetRequestStatus';
import useGetUserQualification from '../hook/useGetUserQualification';
import useGetUserQualificationIdsByAsset from '../hook/useGetUserQualificationIdsByAsset';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import ApiAction from '../store/action/ApiAction';
import {RootState} from '../store/store';
import Preloader from './Preloader';
import QualificationForm from './QualificationForm';

export interface UserQualificationProps {
  assetId: number;
  onSuccessCallback?: () => void;
  onCancelCallback?: () => void;
}

const UserQualification: React.FC<UserQualificationProps> = (props) => {
  const {assetId, onSuccessCallback, onCancelCallback} = props;

  const authenticatedUserId = useSelector((state: RootState) => {
    const {
      user: {authenticatedUser},
    } = state;

    if (!authenticatedUser) {
      throw new Error('`UserQualification` needs an authenticated user');
    }

    return authenticatedUser;
  });

  const userQualificationStatus = useGetRequestStatus(
    ApiAction.labelToGetUserQualificationByAssetId(
      authenticatedUserId,
      assetId,
    ),
  );

  const userQualification = useGetUserQualification(assetId);
  const qualificationIds = useGetUserQualificationIdsByAsset(assetId);

  // We show the preloader if:
  // * The search for `userQualification` is running.
  // * The content has a rating but the rating of the authenticated user has not yet been searched.
  if (
    AsyncActionStatus.PENDING === userQualificationStatus ||
    (qualificationIds &&
      0 < qualificationIds.length &&
      false === userQualification &&
      AsyncActionStatus.NOT_EXECUTED === userQualificationStatus)
  ) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Preloader />
      </div>
    );
  }

  return (
    <QualificationForm
      assetId={assetId}
      onSuccessCallback={onSuccessCallback}
      onCancelCallback={onCancelCallback}
      formData={{
        comment: (userQualification && userQualification?.comment) || '',
        score:
          userQualification && userQualification?.score
            ? userQualification.score.toString()
            : '',
      }}
    />
  );
};

export default UserQualification;
