import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  CancelTokenSource,
  AxiosError,
  AxiosPromise,
} from 'axios';
import stringify from 'qs-stringify';
import userSession from './UserSession';
import i18n from '../locale/i18n';
import storage from './storage';
import {
  GetAssetParam,
  GetUserAssetQualificationParam,
  GetCategoriesParam,
  HydraResponse,
  Category,
  Asset,
  PostContactMessageBody,
  ContactMessage,
  GetFavoritesAssetParam,
  UserAssetQualification,
  User,
  QualificationBody,
  HydraError,
  OrderOption,
  GetUserAssetHistoriesParam,
} from './ApiInterface';

/* eslint-disable @typescript-eslint/no-explicit-any */
const api = {
  getCancelToken(): CancelTokenSource {
    const cancelToken = axios.CancelToken;

    return cancelToken.source();
  },

  async getAuthorizationHeader(): Promise<Record<string, string>> {
    const isAuthenticated = await userSession.getSession();
    const token = isAuthenticated.getAccessToken().getJwtToken();

    return {Authorization: `Bearer ${token}`};
  },

  async api(headers = {}): Promise<AxiosInstance> {
    const innerHeaders = {
      Accept: 'application/ld+json',
      'Accept-Language': i18n.language,
      ...headers,
    };

    return axios.create({
      baseURL: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_SUBDOMAIN}.${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_API_VERSION}`,
      headers: innerHeaders,
      withCredentials: true,
      paramsSerializer: function queryParams(source: any) {
        return stringify(source);
      },
    });
  },

  getCookie(): Date | null {
    const hasCookie = storage.getItem('time-cookie') || null;

    if (hasCookie) {
      return new Date(hasCookie);
    }

    return null;
  },

  async getCategories(
    params: GetCategoriesParam,
    configuration: AxiosRequestConfig,
  ): Promise<HydraResponse<Category[]>> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    const response = await request.get('/categories', {
      params,
      ...configuration,
    });

    return response.data;
  },

  async signedCookies(): Promise<void> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    return request.get('/users/signed-cookies');
  },

  async validateCookie(forceSignedCookies = false): Promise<void> {
    // Defines the time the cookie expires in milliseconds.
    const TIME_COOKIE_EXPIRE = 3600000;
    const cookie = this.getCookie();

    if (
      true === forceSignedCookies ||
      null === cookie ||
      false ===
        Math.abs(new Date().getTime() - cookie.getTime()) <= TIME_COOKIE_EXPIRE
    ) {
      await this.signedCookies();
      storage.setItem('time-cookie', new Date().toISOString());
    }
  },

  async getAssets(
    parameters: GetAssetParam = {},
    configuration: AxiosRequestConfig = {},
  ): Promise<HydraResponse<Asset[]>> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.get('/assets', {
      params: {
        order: {publishStartAt: OrderOption.desc},
        ...parameters,
      },
      ...configuration,
    });
    return response.data;
  },

  async getAsset(
    id: number,
    configuration: AxiosRequestConfig = {},
  ): Promise<Asset> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.get(`/assets/${id}`, {...configuration});
    return response.data;
  },

  async getFavoriteAssets(
    parameters: GetFavoritesAssetParam,
    configuration: AxiosRequestConfig = {},
  ): Promise<HydraResponse<Asset[]>> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.get(`/favorite-assets`, {
      params: parameters,
      ...configuration,
    });
    return response.data;
  },

  async getWatchedAssets(
    parameters: GetFavoritesAssetParam,
    configuration: AxiosRequestConfig = {},
  ): Promise<HydraResponse<Asset[]>> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.get(`/watched-assets`, {
      params: parameters,
      ...configuration,
    });
    return response.data;
  },

  async postMarkAsFavorite(
    id: number,
    configuration: AxiosRequestConfig = {},
  ): Promise<void> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    await request.post(`/assets/${id}/mark-as-favorite`, {
      ...configuration,
    });
  },

  async deleteUnmarkAsFavorite(
    id: number,
    configuration: AxiosRequestConfig = {},
  ): Promise<void> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    await request.delete(`/assets/${id}/unmark-as-favorite`, {
      ...configuration,
    });
  },

  async postContactMessage(
    body: PostContactMessageBody,
    configuration: AxiosRequestConfig = {},
  ): Promise<ContactMessage> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.post('/contact_messages', body, {
      ...configuration,
    });
    return response.data;
  },

  async getQualificationsByAsset(
    parameters: GetUserAssetQualificationParam,
    configuration: AxiosRequestConfig = {},
  ): Promise<HydraResponse<UserAssetQualification[]>> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.get(`/user_asset_qualifications`, {
      params: parameters,
      ...configuration,
    });
    return response.data;
  },

  async request<T>(request: () => AxiosPromise<T>): Promise<T> {
    await this.validateCookie();

    return new Promise((resolve, reject) => {
      request()
        .then((response) => resolve(response.data))
        .catch((error: AxiosError<HydraError>) => {
          if (error.response && error.response.data) {
            reject(error.response.data);
          } else {
            reject(error.toJSON());
          }
        });
    });
  },

  async postQualificationsByAsset(
    body: QualificationBody,
    configuration: AxiosRequestConfig = {},
  ): Promise<UserAssetQualification> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    const response = await this.request<Promise<UserAssetQualification>>(() =>
      request.post(`/user_asset_qualifications`, body, {...configuration}),
    );

    return response;
  },

  async putQualificationsByAsset(
    qualificationId: string,
    body: QualificationBody,
    configuration: AxiosRequestConfig = {},
  ): Promise<UserAssetQualification> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    const response = await this.request<Promise<UserAssetQualification>>(() =>
      request.put(`/user_asset_qualifications/${qualificationId}`, body, {
        ...configuration,
      }),
    );

    return response;
  },

  async getUserProfile(configuration: AxiosRequestConfig = {}): Promise<User> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie(true);

    const response = await request.get(`/users/profile`, {
      ...configuration,
    });
    return response.data;
  },

  async postUpdateLastPosition(
    assetId: number,
    currentTime = 0,
    isFinished = false,
    configuration: AxiosRequestConfig = {},
  ): Promise<void> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.post(
      `/assets/${assetId}/update-last-position`,
      {
        lastPosition: currentTime,
        isFinished,
      },
      {
        ...configuration,
      },
    );
    return response.data;
  },

  async postStartReproduction(
    assetId: number,
    configuration: AxiosRequestConfig = {},
  ): Promise<void> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.post(
      `/assets/${assetId}/start-reproduction`,
      {},
      {
        ...configuration,
      },
    );
    return response.data;
  },

  async getUserAssetFavorite(
    assetId: number,
    configuration: AxiosRequestConfig = {},
  ): Promise<any> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.get(`/user_asset_favorites`, {
      params: {'asset.id': assetId},
      ...configuration,
    });

    return response.data;
  },

  async getUserAssetHistories(
    params: GetUserAssetHistoriesParam = {},
    configuration: AxiosRequestConfig = {},
  ): Promise<any> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api({
      ...authorization,
      'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
    });

    await this.validateCookie();

    const response = await request.get(`/user_asset_histories`, {
      params,
      ...configuration,
    });

    return response.data;
  },

  async postAssetMarkAsFavorite(
    assetId: number,
    configuration: AxiosRequestConfig = {},
  ): Promise<void> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.post(
      `/assets/${assetId}/mark-as-favorite`,
      {},
      {
        ...configuration,
      },
    );
    return response.data;
  },

  async DeleteAssetUnmarkAsFavorite(
    assetId: number,
    configuration: AxiosRequestConfig = {},
  ): Promise<void> {
    const authorization = await this.getAuthorizationHeader();
    const request = await this.api(authorization);

    await this.validateCookie();

    const response = await request.post(
      `/assets/${assetId}/unmark-as-favorite`,
      {},
      {
        ...configuration,
      },
    );
    return response.data;
  },
};

export default api;
