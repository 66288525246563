import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Category from '../page/CategoryHOC';
import Detail from '../page/DetailHOC';
import Player from '../page/PlayerHOC';
import Favorite from '../page/FavoriteHOC';
import History from '../page/HistoryHOC';
import Home from '../page/HomeHOC';
import Search from '../page/SearchHOC';
import routeCollection from './routeCollection';
import Contact from '../page/ContactHOC';
import TermsCondition from '../page/TermsConditionHOC';
import ResolutionWarningMessage from '../component/ResolutionWarningMessage';
import Logout from '../page/Logout';

const SwitchRouter = (): JSX.Element => {
  return (
    <>
      <ResolutionWarningMessage />

      <Switch>
        <Route
          exact
          path={routeCollection.termsCondition}
          component={TermsCondition}
        />
        <Route exact path={routeCollection.category} component={Category} />
        <Route exact path={routeCollection.favorite} component={Favorite} />
        <Route exact path={routeCollection.history} component={History} />
        <Route exact path={routeCollection.detail} component={Detail} />
        <Route exact path={routeCollection.player} component={Player} />
        <Route exact path={routeCollection.contact} component={Contact} />
        <Route exact path={routeCollection.logout} component={Logout} />
        <Route path={routeCollection.search} component={Search} />
        {/** The `Home` component is serving as a fallback if the route does not match any of the previously defined routers. */}
        <Route path={routeCollection.home} component={Home} />
        {/** {@todo Add view for invalid route}.  */}
      </Switch>
    </>
  );
};

export default SwitchRouter;
