import React from 'react';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Button} from 'reactstrap';
import {useSelector} from 'react-redux';
import makeGetChildrenCategoriesIdsByCategory from '../selector/makeGetChildrenCategoriesIdsByCategory';
import {RootState} from '../store/store';
import Preloader from './Preloader';
import FormMessage from './FormMessage';
import {usePostContactMessageMutation} from '../store/service/contactMessageService';

export interface Contact {
  name: string;
  organization: string;
  subject: string;
  message: string;
}

const ContactForm = (): JSX.Element => {
  const {t} = useTranslation();
  const [postContactMessage, {isLoading}] = usePostContactMessageMutation();

  const selectChildren = React.useMemo(
    makeGetChildrenCategoriesIdsByCategory,
    [],
  );

  const [parentCategoryIds] = React.useState('organization');
  const [isError, setIsError] = React.useState(false);
  const [canShowMessage, setCanShowMessage] = React.useState(false);

  const {register, handleSubmit, reset} = useForm<Contact>({
    defaultValues: {
      name: '',
      organization: '',
      subject: '',
      message: '',
    },
  });

  const childrenCategory = useSelector((state: RootState) =>
    selectChildren(state, parentCategoryIds),
  );

  const onSubmit = (data: Contact) => {
    postContactMessage(data)
      .unwrap()
      .then(() => {
        setCanShowMessage(true);
        reset();
      })
      .catch(() => {
        setIsError(true);
        setCanShowMessage(true);
      });
  };

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (true === canShowMessage) {
      timer = setTimeout((): void => {
        setCanShowMessage(false);
      }, 5000);
    }

    return (): void => {
      if (undefined === timer) {
        return;
      }

      clearTimeout(timer);
    };
  }, [canShowMessage]);

  return (
    <form className="contact__form" onSubmit={handleSubmit(onSubmit)}>
      {true === isLoading && (
        <div className="loading-absolute d-flex align-items-center justify-content-center">
          <Preloader />
        </div>
      )}
      <div className="input-group">
        <input
          className={classNames('input-group__input')}
          name="name"
          type="text"
          placeholder={t('form.contact.placeholder.name')}
          autoComplete="off"
          ref={register({required: true})}
          disabled={isLoading}
        />
      </div>

      <div className="input-group">
        <select
          name="organization"
          className="input-group__input"
          ref={register({required: true})}
        >
          <option value="" disabled>
            {t('form.contact.placeholder.organization')}
          </option>
          {childrenCategory.map((item) => (
            <option key={item['@id']} value={item['@id']}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <div className="input-group">
        <input
          className={classNames('input-group__input')}
          name="subject"
          type="text"
          placeholder={t('form.contact.placeholder.theme')}
          autoComplete="off"
          ref={register({required: true, maxLength: 150})}
          disabled={isLoading}
        />
      </div>

      <div className="input-group">
        <textarea
          className={classNames('input-group__input')}
          name="message"
          placeholder={t('form.contact.placeholder.message')}
          autoComplete="off"
          ref={register({required: true, maxLength: 500})}
          disabled={isLoading}
          maxLength={500}
        />
      </div>

      <div className="d-flex justify-content-end align-items-center mt-3">
        {true === canShowMessage && (
          <FormMessage isError={isError}>
            {true === isError
              ? t('form.contact.messages.error')
              : t('form.contact.messages.success')}
          </FormMessage>
        )}

        <Button
          color="dark"
          type="submit"
          className="contact__form-btn btn--dark"
        >
          {t('form.contact.submitButton')}
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
