import createDeepEqualSelector from './createDeepEqualSelector';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import {RootState} from '../store/store';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export interface MakeGetStatusRequestOFAssetsByCategory {
  apiIds: string[];
}

// retorna true si alguna de los apiId dados tiene el estatus de ejecucion pendiente o no ejecutado.
const makeGetStatusRequestByApiIds = () =>
  createDeepEqualSelector(
    [
      (state: RootState, props: MakeGetStatusRequestOFAssetsByCategory) => {
        const {api} = state;
        const {apiIds} = props;

        return apiIds
          .filter((apiKey) => api[apiKey])
          .map((apiKey) => api[apiKey].status);
      },
    ],
    (apiIds) => {
      if (0 === apiIds.length) {
        return false;
      }

      return (
        apiIds.find(
          (status) =>
            AsyncActionStatus.NOT_EXECUTED === status ||
            AsyncActionStatus.PENDING === status,
        ) === undefined
      );
    },
  );

export default makeGetStatusRequestByApiIds;
