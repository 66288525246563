import React from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Preloader from '../component/Preloader';
import UrlProcessor from '../utils/UrlProcessor';
import routeCollection from '../router/routeCollection';
import AuthenticationAction from '../store/action/AuthenticationAction';
import {RootState} from '../store/store';

/**
 * Removes user authentication.
 */
const Logout: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: RootState) => state.authentication.isAuthenticated,
  );

  React.useEffect(() => {
    if (false === isAuthenticated) {
      history.push(UrlProcessor.createPath(routeCollection.home));
    }
  }, [isAuthenticated, history]);

  React.useEffect(() => {
    dispatch(AuthenticationAction.signOut());
  }, [dispatch]);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Preloader />
    </div>
  );
};

export default Logout;
