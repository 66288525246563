import {createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../utils/api';
import {
  GetUserAssetQualificationParam,
  HydraResponse,
  UserAssetQualification,
} from '../../utils/ApiInterface';
import {RootState} from '../store';

export const getQualificationsByAssetId = createAsyncThunk<
  HydraResponse<UserAssetQualification[]>,
  {
    assetId: number;
    options?: Omit<GetUserAssetQualificationParam, 'asset'>;
  }
>('user/authentication', async ({assetId, options = {}}, {signal}) => {
  const {cancel, token} = api.getCancelToken();
  signal.addEventListener('abort', () => {
    cancel();
  });

  return await api.getQualificationsByAsset(
    {...options, 'asset.id': [assetId]},
    {cancelToken: token},
  );
});

export const getUserQualificationsByAssetId = createAsyncThunk<
  HydraResponse<UserAssetQualification[]>,
  {
    assetId: number;
    options?: Omit<GetUserAssetQualificationParam, 'asset'>;
  },
  {state: RootState}
>(
  'user/authentication',
  async ({assetId, options = {}}, {signal, getState}) => {
    const {cancel, token} = api.getCancelToken();
    signal.addEventListener('abort', () => {
      cancel();
    });

    const {
      user: {authenticatedUser},
    } = getState();
    if (!authenticatedUser) {
      throw new Error(
        '`ApiAction.getUserQualificationByAssetId()` needs an authenticated user.',
      );
    }

    return await api.getQualificationsByAsset(
      {...options, 'asset.id': [assetId], 'user.id': [authenticatedUser]},
      {cancelToken: token},
    );
  },
);

export default {getQualificationsByAssetId};
