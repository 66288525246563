import React from 'react';
import {Button} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import salesSchoolLogo from '../../assets/SS_s_.png';
import {RootState} from '../store/store';
import PlayerStatusAction from '../store/action/PlayerStatusAction';
import FavoriteButton from './FavoriteButton';
import VideoPlayButton from './VideoPlayButton';
import VideoRePlayButton from './VideoReplayButton';
import AssetLinkToPlayer from './AssetLinkToPlayer';

export interface HeroContentWebProps {
  assetId: number;
  title: string;
  detailPath: string;
  content?: string | null;
  isExternal?: boolean;
}

const HeroContentWeb = (
  props: React.PropsWithChildren<HeroContentWebProps>,
): JSX.Element => {
  const {t} = useTranslation();
  const muteButtonRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const {detailPath, content, title, assetId, isExternal} = props;

  const isAssetPlaying = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;

    if (
      undefined === playerStatus.main ||
      false === playerStatus.main.isExecutable ||
      false === playerStatus.main.isLoaded
    ) {
      return false;
    }

    return playerStatus.main.isPlaying;
  });

  const isAssetEnding = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus.main;

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isEnding;
  });

  const isAssetExecutable = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;

    if (undefined === playerStatus.main) {
      return false;
    }

    return playerStatus.main.isExecutable;
  });

  const isAssetLoaded = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;

    if (undefined === playerStatus.main) {
      return false;
    }

    return playerStatus.main.isLoaded;
  });

  const isMuted = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;

    if (
      undefined === playerStatus.main ||
      false === playerStatus.main.isExecutable ||
      false === playerStatus.main.isLoaded
    ) {
      return true;
    }

    return playerStatus.main.isMuted;
  });

  React.useEffect(() => {
    const onMuteEvent = () => {
      dispatch(PlayerStatusAction.setAssetIsMuted('main', !isMuted));
    };
    const button = muteButtonRef.current;
    let isEventActive = false;

    if (button && isAssetPlaying) {
      isEventActive = true;
      button.addEventListener('click', onMuteEvent);
    }

    return () => {
      if (button && isEventActive) {
        button.removeEventListener('click', onMuteEvent);
      }
    };
  }, [muteButtonRef, isAssetPlaying, dispatch, isMuted]);

  return (
    <div className="hero__content hero__content--web">
      <div className="container_inner hero__content-wrapper">
        {!isExternal && (
          <div className="mb-1 mb-md-2 pb-0 pb-lg-2">
            <img
              src={salesSchoolLogo}
              alt="sales-school"
              className="hero__brand"
            />
          </div>
        )}

        <div className="hero__wrapper mb-2 mb-md-4">
          <div className="multimedia__content">
            <h1 className="multimedia__title mb-3">{title}</h1>
            <p className="multimedia__description">{content}</p>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <FavoriteButton
              assetId={assetId}
              identifier="home"
              className="btn--dark d-flex align-items-center"
              iconContainerClassName="me-0 me-md-2"
              textClassName="d-none d-md-inline"
              iconClassName="hero-content-web__favorite"
            />

            <AssetLinkToPlayer
              assetId={assetId}
              color="dark"
              className="mx-2 btn--dark d-flex align-items-center"
            >
              <div className="me-0 me-md-2">
                <i className="icomoon icon-play" />
              </div>
              <span className="d-none d-md-inline">
                {t('component.playButton')}
              </span>
            </AssetLinkToPlayer>

            <Button
              tag={Link}
              to={detailPath}
              color="dark"
              className="btn--dark d-flex align-items-center"
            >
              <div className="me-0 me-md-2">
                <i className="icomoon hero-content-web__info icon-information d-block" />
              </div>
              <span className="d-none d-md-inline">
                {t('component.infoButtonShore')}
              </span>
            </Button>
          </div>
          <div className="text-end">
            {isAssetPlaying && (
              <Button
                color="dark"
                className="me-4 btn--dark"
                innerRef={muteButtonRef}
              >
                <div className="standard-icon-height standard-icon-height--40">
                  <i
                    className={classNames(
                      'multimedia__size text-white detail-page-icon__favorite',
                      {
                        'icomoon icon-volume_off': !isMuted,
                        'icomoon icon-volume_on': isMuted,
                      },
                    )}
                  />
                </div>
              </Button>
            )}
            {isAssetEnding && (
              <VideoRePlayButton
                id="main"
                buttonClassName="me-4 btn--dark"
                containerClassName="standard-icon-height standard-icon-height--40"
                iconClassName="multimedia__size text-white detail-page-icon__favorite"
              />
            )}
            {!isAssetEnding &&
              !isAssetPlaying &&
              isAssetExecutable &&
              isAssetLoaded && (
                <VideoPlayButton
                  id="main"
                  buttonClassName="me-4 btn--dark"
                  containerClassName="standard-icon-height standard-icon-height--40"
                  iconClassName="multimedia__size text-white detail-page-icon__favorite"
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroContentWeb;
