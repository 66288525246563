import React from 'react';
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';

const HeroLayout: React.FC = (props) => {
  const {children} = props;

  return (
    <div
      className={classNames('hero', {
        'hero--mobile': isMobile,
        'hero--web': !isMobile,
      })}
    >
      {children}
    </div>
  );
};

export default HeroLayout;
