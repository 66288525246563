import {useEffect, useState} from 'react';

const useIsWindowVisible = (): boolean => {
  const [isWindowActive, setWindowActive] = useState(true);

  const update = () => {
    setWindowActive('visible' === document.visibilityState);
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', update);

    return () => document.removeEventListener('visibilitychange', update);
  }, []);

  return isWindowActive;
};

export default useIsWindowVisible;
