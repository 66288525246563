import React from 'react';
import classNames from 'classnames';

export interface FormMessageProps {
  isError?: boolean;
  className?: string;
}

const FormMessage: React.FC<FormMessageProps> = (props) => {
  const {isError, className, children} = props;

  return (
    <div
      className={classNames(className, {
        'contact__form-error': isError,
        'contact__form-success': !isError,
      })}
    >
      {children}
    </div>
  );
};

export default FormMessage;
