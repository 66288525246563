import {
  Category,
  GetCategoriesParam,
  HydraResponse,
} from '../../utils/ApiInterface';
import apiService from './apiService';

const classificationService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<HydraResponse<Category[]>, GetCategoriesParam>(
      {
        query: (params: GetCategoriesParam) => ({
          url: '/categories',
          method: 'get',
          config: {params},
        }),
      },
    ),
    getCategory: builder.query<Category, string>({
      query: (id) => ({
        url: `/categories/${id}`,
        method: 'get',
      }),
    }),
  }),
});

export const {useGetCategoriesQuery, useGetCategoryQuery} =
  classificationService;

export default classificationService;
