import {AnyAction} from '@reduxjs/toolkit';
import {normalize, schema} from 'normalizr';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import merge from 'lodash/merge';
import {Asset, HydraResponse} from '../../utils/ApiInterface';
import {
  HydraResponseAsset,
  AssetsByKeyActionInterface,
  AssetActionInterface,
  AssetsHistoryInterface,
  AssetMarkAsFavoritesAction,
} from '../interface';
import AssetType from '../type/AssetType';
import AuthenticationType from '../type/AuthenticationType';
import SearchType from '../type/SearchType';

export interface AssetReducerInterface {
  assets: Record<string, Asset>;
  suggestedAssets: Record<string, HydraResponseAsset>;
  assetsByCategory: Record<string, HydraResponseAsset>;
  favoriteAssets: number[];
  search: number[];
  history: number[];
}

export const initialState: AssetReducerInterface = {
  assets: {},
  suggestedAssets: {},
  assetsByCategory: {},
  favoriteAssets: [],
  search: [],
  history: [],
};

const assetLoSchema = new schema.Entity('asset');

const assetHierarchy = new schema.Entity(
  'hierarchy',
  {
    child: assetLoSchema,
  },
  {
    idAttribute: (entity) => entity.child.id,
  },
);

const assetSchema = new schema.Entity('asset', {
  children: [assetHierarchy],
});

const assetUpdate = (
  state: Record<string, Asset>,
  newData: Record<string, Asset>,
): Record<string, Asset> => {
  return {
    ...state,
    ...Object.keys(newData).reduce((lastValue, key) => {
      return {...lastValue, [key]: {...get(state, [key], {}), ...newData[key]}};
    }, {}),
  };
};

const hydraUpdate = (
  state: Record<string, HydraResponseAsset>,
  key: string,
  newData: HydraResponse<Asset[]>,
  assetIds: number[],
): Record<string, HydraResponseAsset> => {
  const assetSaved = state[key];

  if (assetSaved) {
    const newIds = uniq([...assetSaved['hydra:member'], ...assetIds]);

    return {
      ...state,
      [key]: {
        ...newData,
        'hydra:member': newIds,
      },
    };
  }

  return merge({}, state, {
    [key]: {...newData, 'hydra:member': [...assetIds]},
  });
};

const sortAssetAccordingToPosition = (asset: Asset): Asset => {
  const {children} = asset;

  const childrenSorted = children.sort((lastElement, newElement) => {
    return lastElement.position - newElement.position;
  });

  return {...asset, children: childrenSorted};
};

const assetReducer = (
  state = initialState,
  action: AnyAction,
): AssetReducerInterface => {
  const {type, payload} = action;

  switch (type) {
    case AssetType.SAVE_ASSET_BY_OPTION:
    case AssetType.SAVE_ASSET: {
      const {asset} = payload as AssetActionInterface;

      const normalizedData = normalize<Asset>(
        sortAssetAccordingToPosition(asset),
        assetSchema,
      );

      if (!normalizedData.entities.asset) {
        return state;
      }

      return {
        ...state,
        assets: assetUpdate(state.assets, normalizedData.entities.asset),
      };
    }

    case AssetType.SAVE_SUGGESTED_ASSETS: {
      const {key, assets} = payload as AssetsByKeyActionInterface;
      const normalizedData = normalize<
        Asset,
        Record<string, Record<string, Asset>>,
        number[]
      >(assets['hydra:member'].map(sortAssetAccordingToPosition), [
        assetSchema,
      ]);

      if (!normalizedData.entities.asset) {
        return {
          ...state,
          suggestedAssets: {
            ...state.suggestedAssets,
            [key]: {
              ...assets,
              'hydra:member': [],
            },
          },
        };
      }

      return {
        ...state,
        suggestedAssets: {
          ...state.suggestedAssets,
          [key]: {
            ...assets,
            'hydra:member': [
              ...normalizedData.result.filter(
                (assetId) => parseInt(key, 10) !== assetId,
              ),
            ],
          },
        },
        assets: assetUpdate(state.assets, normalizedData.entities.asset),
      };
    }

    case AssetType.SAVE_ASSETS_BY_SEARCH: {
      const {assets, key} = payload as AssetsByKeyActionInterface;
      const normalizedData = normalize<
        void,
        {asset: Record<string, Asset>},
        number[]
      >(assets['hydra:member'], [assetSchema]);

      if (!normalizedData.entities.asset) {
        return {
          ...state,
          assetsByCategory: {
            ...state.assetsByCategory,
            [key]: {
              ...assets,
              'hydra:member': [],
            },
          },
          search: [],
        };
      }

      const newAssetsByCategoryState = hydraUpdate(
        state.assetsByCategory,
        key,
        {
          ...assets,
          'hydra:member': normalizedData.result.map((id: number) => {
            return normalizedData.entities.asset[id];
          }),
        },
        normalizedData.result,
      );

      return {
        ...state,
        assetsByCategory: newAssetsByCategoryState,
        search: [...state.search, ...normalizedData.result],
        assets: assetUpdate(state.assets, normalizedData.entities.asset),
      };
    }

    case AssetType.SAVE_ASSETS_BY_CATEGORY: {
      const {assets, key} = payload as AssetsByKeyActionInterface;
      const normalizedData = normalize<
        void,
        {asset: Record<string, Asset>},
        number[]
      >(assets['hydra:member'], [assetSchema]);

      if (!normalizedData.entities.asset) {
        return {
          ...state,
          assetsByCategory: {
            ...state.assetsByCategory,
            [key]: {
              ...assets,
              'hydra:member': [],
            },
          },
        };
      }

      const newAssetsByCategoryState = hydraUpdate(
        state.assetsByCategory,
        key,
        {
          ...assets,
          'hydra:member': normalizedData.result.map((id: number) => {
            return normalizedData.entities.asset[id];
          }),
        },
        normalizedData.result,
      );

      return {
        ...state,
        assetsByCategory: newAssetsByCategoryState,
        assets: assetUpdate(state.assets, normalizedData.entities.asset),
      };
    }

    case AssetType.SAVE_FAVORITE_ASSETS: {
      const {assets, key} = payload as AssetsByKeyActionInterface;

      const normalizedData = normalize<
        void,
        {asset: Record<string, Asset>},
        number[]
      >(assets['hydra:member'], [assetSchema]);

      if (!normalizedData.entities.asset) {
        return {
          ...state,
          assetsByCategory: {
            ...state.assetsByCategory,
            [key]: {
              ...assets,
              'hydra:member': [],
            },
          },
          search: [],
        };
      }

      const newAssetsState = assetUpdate(
        state.assets,
        normalizedData.entities.asset,
      );
      let newFavoritesAssetIds = state.favoriteAssets;

      // actualizamos la coleccion de asset marcadas como favoritas.
      if (newFavoritesAssetIds.length) {
        newFavoritesAssetIds = uniq([
          ...normalizedData.result,
          ...newFavoritesAssetIds,
        ]);
      } else {
        newFavoritesAssetIds = [...normalizedData.result];
      }

      const newAssetsByCategoryState = hydraUpdate(
        state.assetsByCategory,
        key,
        {
          ...assets,
          'hydra:member': normalizedData.result.map((id: number) => {
            return normalizedData.entities.asset[id];
          }),
        },
        normalizedData.result,
      );

      return {
        ...state,
        assetsByCategory: newAssetsByCategoryState,
        favoriteAssets: newFavoritesAssetIds,
        assets: newAssetsState,
      };
    }

    case AssetType.SAVE_ASSET_MARK_AS_FAVORITE: {
      const {assetId: assetFavoriteId} = payload as AssetMarkAsFavoritesAction;

      let newFavoritesAssetIds = state.favoriteAssets;

      // actualizamos la coleccion de asset marcadas como favoritas.
      if (newFavoritesAssetIds.length) {
        newFavoritesAssetIds = uniq([assetFavoriteId, ...newFavoritesAssetIds]);
      } else {
        newFavoritesAssetIds = [assetFavoriteId];
      }

      const category = state.assetsByCategory.API_GET_FAVORITE_ASSETS;

      if (category) {
        const newAssetIds = uniq([
          assetFavoriteId,
          ...category['hydra:member'],
        ]);
        const newCategoryState = merge({}, category, {
          ...category,
          'hydra:member': newAssetIds,
        });

        return {
          ...state,
          assetsByCategory: {
            ...state.assetsByCategory,
            API_GET_FAVORITE_ASSETS: newCategoryState,
          },
          favoriteAssets: newFavoritesAssetIds,
        };
      }

      return {...state, favoriteAssets: newFavoritesAssetIds};
    }

    case AssetType.REMOVE_ASSET_MARK_AS_FAVORITE: {
      const {assetId} = payload as AssetMarkAsFavoritesAction;

      let newFavoritesAssetIds = state.favoriteAssets;
      if (newFavoritesAssetIds.length) {
        newFavoritesAssetIds = newFavoritesAssetIds.filter(
          (id) => id !== assetId,
        );
      }

      const category = state.assetsByCategory.API_GET_FAVORITE_ASSETS;
      if (category) {
        const newCategoryState = {
          ...category,
          'hydra:totalItems': category['hydra:member'].includes(assetId)
            ? category['hydra:totalItems'] - 1
            : category['hydra:totalItems'],
          'hydra:member': [
            ...category['hydra:member'].filter((id) => id !== assetId),
          ],
        };

        return {
          ...state,
          assetsByCategory: {
            ...state.assetsByCategory,
            API_GET_FAVORITE_ASSETS: newCategoryState,
          },
          favoriteAssets: newFavoritesAssetIds,
        };
      }

      return {...state, favoriteAssets: newFavoritesAssetIds};
    }

    case AssetType.SAVE_ASSET_BY_VIEWED: {
      const {asset: assets, key} = payload as AssetsHistoryInterface;
      const normalizedData = normalize<
        void,
        {asset: Record<string, Asset>},
        number[]
      >(assets['hydra:member'], [assetSchema]);

      if (!normalizedData.entities.asset) {
        return {
          ...state,
          assetsByCategory: {
            ...state.assetsByCategory,
            [key]: {
              ...assets,
              'hydra:member': [],
            },
          },
          history: [],
        };
      }

      const newAssetsByCategoryState = hydraUpdate(
        state.assetsByCategory,
        key,
        {
          ...assets,
          'hydra:member': normalizedData.result.map((id: number) => {
            return normalizedData.entities.asset[id];
          }),
        },
        normalizedData.result,
      );

      return {
        ...state,
        assetsByCategory: newAssetsByCategoryState,
        history: [...state.history, ...normalizedData.result],
        assets: assetUpdate(state.assets, normalizedData.entities.asset),
      };
    }

    case AuthenticationType.SIGN_OUT: {
      return {...initialState};
    }

    case SearchType.RESET_SEARCH:
    case SearchType.REMOVE_CATEGORY_ID:
    case SearchType.SAVE_CATEGORY_ID:
    case SearchType.SAVE_SEARCH_OPTION: {
      return {
        ...state,
        search: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default assetReducer;
