import React from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import Clipboard from 'react-clipboard.js';

export interface ClipboardAssetURLProps {
  assetId: number;
}

/**
 * Copy the url of an asset to the clipboard
 *
 * @todo This component must receive by `props` the css classes to change its appearance.
 */
const ClipboardAssetURL: React.FC<ClipboardAssetURLProps> = (props) => {
  const {assetId} = props;
  const {t} = useTranslation();
  const [isCopy, setCopy] = React.useState<boolean>(false);

  const copyToClipboard = React.useCallback(() => {
    if (!isCopy) {
      setCopy(true);
    }
  }, [isCopy]);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isCopy) {
      timer = setTimeout(() => {
        setCopy(false);
      }, 5000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isCopy]);

  return (
    <div id={`share-button-${assetId}`} className="button-width">
      <UncontrolledTooltip target={`share-button-${assetId}`}>
        {isCopy ? t('component.copied') : t('component.copy')}
      </UncontrolledTooltip>
      <Clipboard
        onClick={copyToClipboard}
        data-clipboard-text={window.location.href}
        className="border-0 btn--dark btn btn-dark w-100"
      >
        <div className="standard-icon-height standard-icon-height--40 mb-2">
          <i className="icomoon icon-share detail-page-icon__share" />
        </div>
        <span className="font-size--regular">{t('component.shareButton')}</span>
      </Clipboard>
    </div>
  );
};

export default ClipboardAssetURL;
