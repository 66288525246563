import React from 'react';
import {Redirect, useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import routeCollection from '../router/routeCollection';
import {RootState} from '../store/store';
import UrlProcessor from '../utils/UrlProcessor';
import {AssetLayoutActionEnum} from '../class/AssetLayout';
import useGetTrainerIdFromQuery from '../hook/useGetTrainerIdFromQuery';
import {useGetAssetQuery} from '../store/service/assetService';
import Preloader from './Preloader';
import PreviewAsset from '../page/PreviewAsset';
import AssetPlayLayoutHeader from './AssetPlayLayoutHeader';
import AssetLayoutTrainerUI from './AssetLayoutTrainerUI';
import PlayerStatusAction from '../store/action/PlayerStatusAction';

const AssetPlayLayoutUI: React.FC = () => {
  const history = useHistory();
  const params = useParams<{id: string}>();
  const dispatch = useDispatch();
  const id = React.useMemo(() => parseInt(params.id, 10), [params.id]);
  const {data: asset, isLoading: isAssetLoading} = useGetAssetQuery(id);

  const trainerId = useGetTrainerIdFromQuery();
  const {data: assetTrainer, isLoading: isTrainerLoading} = useGetAssetQuery(
    trainerId || 0,
    {
      skip: undefined === trainerId,
    },
  );

  const isAssetEnding = useSelector((state: RootState): boolean => {
    const {playerStatus} = state;
    const currentPlayerStatus = playerStatus[id];

    if (undefined === currentPlayerStatus) {
      return false;
    }

    return currentPlayerStatus.isEnding;
  });

  const urlAfterAssetEnding = React.useMemo(() => {
    return UrlProcessor.createPath(
      routeCollection.detail,
      {
        id: trainerId || id,
      },
      {
        action: AssetLayoutActionEnum.QUALIFICATION,
      },
    );
  }, [id, trainerId]);

  React.useEffect(() => {
    if (undefined === trainerId && isAssetEnding) {
      history.push(urlAfterAssetEnding);
    }
  }, [isAssetEnding, urlAfterAssetEnding, history, trainerId]);

  React.useEffect(() => {
    const assetId = id.toString();
    // The reproduction status is created in redux.
    dispatch(PlayerStatusAction.setAssetStatus(assetId, false));

    return () => {
      // The reproduction status in redux is removed.
      dispatch(PlayerStatusAction.removePlayerStatus(assetId));
    };
  }, [dispatch, id]);

  if (isAssetLoading || isTrainerLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Preloader />
      </div>
    );
  }

  if (
    undefined === asset ||
    (undefined !== trainerId && undefined === assetTrainer)
  ) {
    return <Redirect to={UrlProcessor.createPath(routeCollection.home)} />;
  }

  return (
    <div className="position-relative layout--absolute min-vh-100 layout">
      {assetTrainer && <PreviewAsset asset={asset} trainer={assetTrainer} />}
      <AssetPlayLayoutHeader asset={asset} trainer={assetTrainer} />
      <AssetLayoutTrainerUI asset={asset} trainer={assetTrainer} />
    </div>
  );
};

export default AssetPlayLayoutUI;
