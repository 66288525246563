import React from 'react';
import {useDispatch} from 'react-redux';
import useGetCategoryIdByContext from '../hook/useGetCategoryIdByContext';
import useGetUserCategories from '../hook/useGetUserCategories';
import useIsThereAnActiveRequest from '../hook/useIsThereAnActiveRequest';
import ApiAction from '../store/action/ApiAction';
import {getAssetsBySalesChannelIds} from '../store/action/AssetAction';
import {GetAssetParam} from '../utils/ApiInterface';
import AssetByCategoriesLoad from './AssetByCategoriesLoad';

interface AssetsBySalesChannelProps {
  context: string;
  isAssetByUserProfile?: boolean;
  showCategoryByProfile?: boolean;
  keyStorage?: string;
}

const AssetsBySalesChannel: React.FC<AssetsBySalesChannelProps> = (props) => {
  const {context, isAssetByUserProfile, showCategoryByProfile = false} = props;
  const {keyStorage} = props;
  const dispatch = useDispatch();

  const categoryIds = useGetCategoryIdByContext(
    context,
    showCategoryByProfile ? isAssetByUserProfile : undefined,
  );

  const categoryNames = React.useMemo(
    () =>
      keyStorage
        ? categoryIds.map((apiId) => `${keyStorage}_${apiId}`)
        : categoryIds,
    [categoryIds, keyStorage],
  );

  const labelCategoryIds = React.useMemo(() => {
    return categoryNames.map((apiId) =>
      ApiAction.labelToGetAssetsByCategory(apiId),
    );
  }, [categoryNames]);

  const isThereAnActiveRequest = useIsThereAnActiveRequest(labelCategoryIds);
  const authenticatedCategoryFilter = useGetUserCategories();

  React.useEffect(() => {
    let queryParams: GetAssetParam = {};

    if (!isAssetByUserProfile) {
      // If the user does not have all the profile data loaded, only the public content is searched.
      queryParams = {isPublic: true};
    }

    if (isAssetByUserProfile && showCategoryByProfile) {
      const {organizationIds, positionIds} = authenticatedCategoryFilter;

      if (0 < organizationIds.length) {
        queryParams = {...queryParams, organizationParent: organizationIds};
      }

      if (0 < positionIds.length) {
        queryParams = {...queryParams, positionParent: positionIds};
      }
    }

    dispatch(
      getAssetsBySalesChannelIds({
        categoryIds,
        parameters: queryParams,
        keyStorage,
      }),
    );
  }, [
    dispatch,
    categoryIds,
    keyStorage,
    authenticatedCategoryFilter,
    isAssetByUserProfile,
    showCategoryByProfile,
  ]);

  if (!isThereAnActiveRequest) {
    return null;
  }

  return (
    <div>
      {categoryNames.map((categoryName, index) => (
        <AssetByCategoriesLoad
          key={categoryName}
          categoryId={categoryIds[index]}
          storeLabelId={categoryName}
        />
      ))}
    </div>
  );
};

export default AssetsBySalesChannel;
