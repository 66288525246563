import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import makeGetStatusRequestByApiIds from '../selector/makeGetStatusRequestByApiIds';
import {RootState} from '../store/store';

const useIsThereAnActiveRequest = (apiIds: string[]): boolean => {
  const selectCategoryByUser = useMemo(makeGetStatusRequestByApiIds, []);

  const isThereAnActiveRequest = useSelector((state: RootState) =>
    selectCategoryByUser(state, {apiIds}),
  );

  return isThereAnActiveRequest;
};

export default useIsThereAnActiveRequest;
