import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import HeroHeader from '../component/HeroHeader';
import Footer from '../component/Footer';
import HeroContent from '../component/HeroContent';
import {RootState} from '../store/store';
import AssetByCategory from '../component/AssetByCategory';
import ApiAction from '../store/action/ApiAction';
import InfinityScrollCategoryLoad from '../component/InfinityScrollCategoryLoad';
import useGetIsUserProfileLoaded from '../hook/useGetIsUserProfileLoaded';
import useGetAssetsByCategoryId from '../hook/useGetAssetsByCategoryId';
import Carousel from '../component/Carousel';

const Home: React.FC = () => {
  const {t} = useTranslation();
  const [parentCategoryIds] = React.useState(['competency', 'sales_channel']);

  const labelToGetLastAssets = React.useMemo(
    () => ApiAction.labelToGetLastAssets(),
    [],
  );

  const labelToGetFavorites = React.useMemo(
    () => ApiAction.labelToGetFavoriteAssets(),
    [],
  );

  const labelToGetMyTrainingPlan = React.useMemo(
    () => ApiAction.labelToGetMyTrainingPlan(),
    [],
  );

  const assets = useGetAssetsByCategoryId(labelToGetLastAssets);

  const isUserProfileLoaded = useGetIsUserProfileLoaded();

  const isCompetencyLoaded = useSelector(
    (state: RootState) => state.categories.categoryParent[parentCategoryIds[0]],
  );
  const isSalesChannelLoaded = useSelector(
    (state: RootState) => state.categories.categoryParent[parentCategoryIds[1]],
  );

  return (
    <div>
      <HeroHeader assetId={assets[0].id}>
        <HeroContent assetId={assets[0].id} />
      </HeroHeader>

      <div className="page body-main">
        {1 < assets.length && (
          <Carousel
            className="container_inner"
            sectionTitle={t('page.home.lastAssets')}
            carouselKey={labelToGetLastAssets}
            slideData={assets.slice(1)}
          />
        )}

        {isUserProfileLoaded && (
          <AssetByCategory
            categoryName={t('page.home.myTrainingPlan')}
            categoryId={labelToGetMyTrainingPlan}
            labelApiId={labelToGetMyTrainingPlan}
          />
        )}

        <AssetByCategory
          categoryName={t('page.home.myFavorites')}
          categoryId={labelToGetFavorites}
          labelApiId={labelToGetFavorites}
        />

        {undefined !== isCompetencyLoaded &&
          undefined !== isSalesChannelLoaded && (
            <InfinityScrollCategoryLoad
              parentCategoryIds={parentCategoryIds}
              isAssetByUserProfile={isUserProfileLoaded}
            />
          )}
      </div>
      <Footer isAuthenticatedUser />
    </div>
  );
};

export default Home;
