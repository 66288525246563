const awsConfiguration = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_AWS_OAUTH_SIGNIN_URL}`,
      redirectSignOut: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_AWS_OAUTH_SIGNOUT_URL}`,
      responseType: 'code',
    },
  },
};
export default awsConfiguration;
