/* eslint-disable import/no-cycle */
import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export interface MakeGetQualificationIdsByAssetIdProps {
  assetId: number;
}

const makeGetQualificationIdsByAssetId = () =>
  createDeepEqualSelector(
    [
      (state: RootState) => state.qualification.qualificationByAsset,
      (state: RootState, props: MakeGetQualificationIdsByAssetIdProps) =>
        props.assetId,
    ],
    (qualifications, assetId) => {
      const qualificationByAssetId = qualifications[assetId];
      return qualificationByAssetId
        ? qualificationByAssetId['hydra:member']
        : [];
    },
  );

export default makeGetQualificationIdsByAssetId;
