import React from 'react';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import AssetModel from '../model/AssetModel';
import {ReactComponent as PdfIcon} from '../../assets/infografia.svg';

export interface AssetTypeIconProps {
  type: string;
  className?: string;
  wrapperClassName?: string;
  showIcon?: boolean;
  showType?: boolean;
  iconClassName?: string;
}

/**
 * Renders an icon according to the asset type.
 */
const AssetTypeIcon: React.FC<AssetTypeIconProps> = (props) => {
  const {
    type,
    className,
    wrapperClassName,
    showIcon = true,
    showType = true,
    iconClassName = 'standard-icon-size--16',
  } = props;
  const {t} = useTranslation();

  return (
    <div className={classNames('d-flex align-items-center', className)}>
      {showIcon && (
        <div
          className={classNames(
            'standard-icon-height standard-icon-height--20',
            wrapperClassName,
          )}
        >
          {AssetModel.TYPE_AUDIO === type && (
            <i
              className={classNames('icomoon icon-audio', iconClassName, {
                'me-2': showType,
              })}
            />
          )}

          {AssetModel.TYPE_VIDEO === type && (
            <i
              className={classNames('icomoon icon-videos', iconClassName, {
                'me-2': showType,
              })}
            />
          )}

          {AssetModel.TYPE_PDF === type && (
            <span
              className={classNames('detail-page-icon__pdf', iconClassName)}
            >
              <PdfIcon />
            </span>
          )}

          {AssetModel.TYPE_WEB === type && (
            <i
              className={classNames('icomoon icon-attach', iconClassName, {
                'me-2': showType,
              })}
            />
          )}

          {AssetModel.TYPE_TRAINING_PROGRAM === type && (
            <i
              className={classNames('icomoon icon-programa', iconClassName, {
                'me-2': showType,
              })}
            />
          )}
        </div>
      )}

      {showType && (
        <span className="standard-icon-height standard-icon-height--20">
          {t(`asset.type.${type}`)}
        </span>
      )}
    </div>
  );
};

export default React.memo(AssetTypeIcon);
