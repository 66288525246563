import React from 'react';
import classNames from 'classnames';
import {Trans} from 'react-i18next';

export interface AssetAuthorProps {
  className?: string;
  authorName: string;
}

const AssetAuthor: React.FC<AssetAuthorProps> = (props) => {
  const {className, authorName} = props;

  return (
    <p className={classNames('font-size--small', className)}>
      <Trans i18nKey="asset.developedBy">
        developed by <strong>{{user: authorName}}</strong>
      </Trans>
    </p>
  );
};

export default AssetAuthor;
