import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

i18n
  // load translation using http -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // connect with React
  .use(initReactI18next)
  // config
  .init({
    fallbackLng: 'en',
    whitelist: ['en', 'es', 'pt', 'fr', 'zh', 'hi', 'tr', 'ar'],

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
