import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Media} from '../utils/ApiInterface';
import useGetAvailableLanguage from './useGetAvailableLanguage';

/**
 * Returns a `Media` based in the language given.
 * If `language` isn't given, it use language priorities to match a `Media`.
 * If none matches, it returns the first element of `medias`.
 */
const useFilterMediaByLanguage = (
  medias: Media[],
  language?: string,
): Media => {
  const {i18n} = useTranslation();
  const languages = useGetAvailableLanguage();

  const mediasGroupedByLanguage: Record<string, Media> = useMemo(() => {
    return medias.reduce((lastValue, media) => {
      const {publicUrls, providerMetadata} = media;
      const {source_language: sourceLanguage} = providerMetadata;
      const {reference = ''} = publicUrls || {};

      if (sourceLanguage && reference) {
        return {
          ...lastValue,
          [sourceLanguage.split(/[_-]/)[0]]: media,
        };
      }

      return lastValue;
    }, {});
  }, [medias]);

  if (language && mediasGroupedByLanguage[language]) {
    return mediasGroupedByLanguage[language];
  }

  if (i18n.language && mediasGroupedByLanguage[i18n.language]) {
    return mediasGroupedByLanguage[i18n.language];
  }

  const availableLanguageInMedias = languages.find(
    ({id}) => undefined !== mediasGroupedByLanguage[id],
  );

  if (availableLanguageInMedias) {
    return mediasGroupedByLanguage[availableLanguageInMedias.id];
  }

  return Object.values<Media>(mediasGroupedByLanguage)[0];
};

export default useFilterMediaByLanguage;
