import React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import routeCollection from '../router/routeCollection';
import UrlProcessor from '../utils/UrlProcessor';

export interface UseUpdateQueryParameters {
  getUrl: (
    newQueryParameters: Record<string, string | string[] | null>,
  ) => string;
  updateHistoryBrowser: (
    newQueryParameters: Record<string, string | string[] | null>,
  ) => void;
}

const useUpdateQueryParameters = (): UseUpdateQueryParameters => {
  const history = useHistory();
  const routeMatch = useRouteMatch();

  const getUrl = React.useCallback(
    (newQueryParameters: Record<string, string | string[] | null>) => {
      const {path, params} = routeMatch;

      if (
        newQueryParameters &&
        newQueryParameters.asset &&
        'string' === typeof newQueryParameters.asset &&
        routeCollection.detail === path
      ) {
        const {asset, ...queryParameters} = newQueryParameters;
        return UrlProcessor.createPath(
          path,
          {...params, id: newQueryParameters.asset},
          queryParameters,
        );
      }

      return UrlProcessor.createPath(path, {...params}, newQueryParameters);
    },
    [routeMatch],
  );

  const updateHistoryBrowser = React.useCallback(
    (newQueryParameters: Record<string, string | string[] | null>) => {
      history.push(getUrl(newQueryParameters));
    },
    [history, getUrl],
  );

  return {
    getUrl,
    updateHistoryBrowser,
  };
};

export default useUpdateQueryParameters;
