import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import GbLogo from '../../assets/gb-footer.png';
import LanguageSelect from './LanguageSelect';
import routeCollection from '../router/routeCollection';

export interface FooterProps {
  isAuthenticatedUser?: boolean;
}

const Footer: React.FC<FooterProps> = (props) => {
  const {isAuthenticatedUser} = props;
  const {t} = useTranslation();

  return (
    <footer className="footer mt-auto">
      <div className="footer__info container_inner">
        <div className="footer__brand">
          <img src={GbLogo} alt="" className="footer__gb-img" />
        </div>

        <div className="d-flex w-100 justify-content-end align-items-center">
          {isAuthenticatedUser && (
            <Link
              to={routeCollection.contact}
              className="footer__link text-white text-decoration-none ms-3"
            >
              {t('menu.contactLink')}
            </Link>
          )}
          <LanguageSelect direction="down" className="ms-3" />
        </div>
      </div>
      <div className="footer__detail__gout">
        <div className="gout" />
      </div>
    </footer>
  );
};

export default Footer;
