import React from 'react';
import classNames from 'classnames';
import humanizeDuration from 'humanize-duration';

export interface AssetDurationProps {
  duration: number;
  iconClassName?: string;
  className?: string;
  showIcon?: boolean;
  showDate?: boolean;
}

const AssetDurationHumanized: React.FC<AssetDurationProps> = (props) => {
  const {duration} = props;
  const {
    showDate = true,
    showIcon = true,
    iconClassName = 'icomoon icon-show_late',
    className,
  } = props;

  const durationDate = React.useMemo(() => {
    const shortHumanizer = humanizeDuration.humanizer({
      delimiter: ' ',
      spacer: ' ',
      language: 'shortEn',
      languages: {
        shortEn: {
          y: () => 'y',
          mo: () => 'mo',
          w: () => 'w',
          d: () => 'd',
          h: () => 'h',
          m: () => 'm',
          s: () => 's',
          ms: () => 'ms',
        },
      },
    });

    // Since 'humanizeDuration()' receives the time in milliseconds and `duration` is in seconds, its value is multiplied by 1000.
    return shortHumanizer(duration * 1000);
  }, [duration]);

  return (
    <div className={classNames('d-flex align-items-center', className)}>
      {showIcon && (
        <span className="standard-icon-height standard-icon-height--14 line-height">
          <i
            className={classNames('standard-icon-size--14 me-2', iconClassName)}
          />
        </span>
      )}
      {showDate && (
        <span className="standard-icon-height standard-icon-height--14 line-height">
          {durationDate}
        </span>
      )}
    </div>
  );
};

export default React.memo(AssetDurationHumanized);
