export interface LastTimeReduceAction {
  currentTime: number;
  interval: number;
}

const trackTimeReducer = (state = 0, action: LastTimeReduceAction): number => {
  const {currentTime, interval} = action;

  if (!currentTime) {
    return 0;
  }

  if (currentTime >= state + interval) {
    return currentTime;
  }

  return state;
};

export default trackTimeReducer;
