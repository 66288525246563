import React from 'react';
import {UseFormMethods, RegisterOptions} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

interface RateQualificationProps
  extends Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  isDisabled: boolean;
  name: string;
  rules?: RegisterOptions;
}

const RateQualification: React.FC<RateQualificationProps> = (
  props,
): JSX.Element => {
  const {register, rules, isDisabled, name} = props;
  const {t} = useTranslation();

  return (
    <div className="rate__fields">
      <p className="rate__text font-size--regular mt-2 mb-2 text-center">
        {t('form.rate.question')}
      </p>
      <div className="rate__options-control">
        <label htmlFor="qualification_answer_veryPoor" className="rate__option">
          <input
            type="radio"
            id="qualification_answer_veryPoor"
            name={name}
            ref={register && register(rules)}
            disabled={isDisabled}
            value="1"
          />
          <i className="icomoon icon-star_1" />
        </label>
        <label htmlFor="qualification_answer_poor" className="rate__option">
          <input
            type="radio"
            id="qualification_answer_poor"
            name={name}
            ref={register && register(rules)}
            disabled={isDisabled}
            value="2"
          />
          <i className="icomoon icon-star_1" />
        </label>
        <label htmlFor="qualification_answer_meh" className="rate__option">
          <input
            type="radio"
            id="qualification_answer_meh"
            name={name}
            ref={register && register(rules)}
            disabled={isDisabled}
            value="3"
          />
          <i className="icomoon icon-star_1" />
        </label>
        <label htmlFor="qualification_answer_good" className="rate__option">
          <input
            type="radio"
            id="qualification_answer_good"
            name={name}
            ref={register && register(rules)}
            disabled={isDisabled}
            value="4"
          />
          <i className="icomoon icon-star_1" />
        </label>
        <label
          htmlFor="qualification_answer_excellent"
          className="rate__option"
        >
          <input
            type="radio"
            id="qualification_answer_excellent"
            name={name}
            ref={register && register(rules)}
            disabled={isDisabled}
            value="5"
          />
          <i className="icomoon icon-star_1" />
        </label>
      </div>
    </div>
  );
};

export default RateQualification;
