import createDeepEqualSelector from './createDeepEqualSelector';
import {RootState} from '../store/store';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export interface MakeGetAssetsByCategoryProps {
  categoryName: string;
}

const makeGetAssetsByCategory = () =>
  createDeepEqualSelector(
    [
      (state: RootState) => {
        const {
          assets: {assets},
        } = state;

        return assets;
      },
      (state: RootState, props: MakeGetAssetsByCategoryProps) => {
        const {
          assets: {assetsByCategory},
        } = state;
        const {categoryName} = props;

        return assetsByCategory[categoryName];
      },
    ],
    (assets, assetsByCategory) => {
      if (assetsByCategory) {
        return assetsByCategory['hydra:member'].map(
          (assetId) => assets[assetId],
        );
      }

      return [];
    },
  );

export default makeGetAssetsByCategory;
