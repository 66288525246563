import React from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import PlayerStatusAction from '../store/action/PlayerStatusAction';
import AssetModel from '../model/AssetModel';
import {Asset} from '../utils/ApiInterface';
import useHandlerPlayer from '../hook/useHandlerPlayer';

export interface ContinueWatchingButtonProps {
  asset: Asset;
  trainer: Asset;
}

const ContinueWatchingButton: React.FC<ContinueWatchingButtonProps> = (
  props,
) => {
  const {asset, trainer} = props;
  const dispatch = useDispatch();
  const continueWatchingRef = React.useRef<HTMLButtonElement>(null);
  const {t} = useTranslation();
  const {startReproduction, updateLastPosition} = useHandlerPlayer(
    asset,
    trainer,
  );

  const preContinueWatching = React.useCallback(() => {
    return startReproduction();
  }, [startReproduction]);

  const continueWatching = React.useCallback(() => {
    if (AssetModel.TYPE_PDF === asset.type) {
      const bodyElement = document.querySelector<HTMLBodyElement>('body');
      if (bodyElement) {
        window.scrollTo(0, 0);
        bodyElement.style.overflow = '';
      }
    }

    if (
      AssetModel.TYPE_VIDEO === asset.type ||
      AssetModel.TYPE_AUDIO === asset.type
    ) {
      const videoElement =
        document.querySelector<HTMLVideoElement>('.video-js  video');

      if (videoElement) {
        videoElement.play();
      }
    }

    if (AssetModel.TYPE_WEB === asset.type) {
      const {url} = asset;
      if (url) {
        window.open(url, '_blank');
      }
    }

    if (AssetModel.TYPE_WEB !== asset.type) {
      dispatch(PlayerStatusAction.setAssetIsEnding(asset.id.toString(), false));
    }
  }, [dispatch, asset]);

  const postContinueWatching = React.useCallback(() => {
    if (AssetModel.TYPE_WEB === asset.type) {
      updateLastPosition(0, true);
    }
  }, [updateLastPosition, asset]);

  const onContinueWatching = React.useCallback(async () => {
    preContinueWatching();

    continueWatching();

    postContinueWatching();
  }, [preContinueWatching, continueWatching, postContinueWatching]);

  React.useEffect(() => {
    const button = continueWatchingRef.current;
    let isEventActive = false;

    if (button) {
      isEventActive = true;
      button.addEventListener('click', onContinueWatching);
    }

    return () => {
      if (button && isEventActive) {
        button.removeEventListener('click', onContinueWatching);
      }
    };
  }, [continueWatchingRef, onContinueWatching]);

  return (
    <Button
      color="dark"
      className="btn--dark me-2"
      innerRef={continueWatchingRef}
    >
      {t('component.watchAgain')}
    </Button>
  );
};

export default ContinueWatchingButton;
