import React from 'react';

const VideoPlayer = React.forwardRef<HTMLVideoElement>((props, ref) => {
  return (
    <div data-vjs-player>
      <video
        ref={ref}
        className="video-js vjs-big-play-centered"
        crossOrigin="use-credentials"
      />
    </div>
  );
});

export default VideoPlayer;
