import React from 'react';
import classNames from 'classnames';

export interface InputGroupProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  hasValue: boolean;
  label: string;
  errorMessage?: string;
  iconClassName?: string;
}

const InputGroup = React.forwardRef<HTMLInputElement, InputGroupProps>(
  (props, ref): JSX.Element => {
    const {
      label,
      errorMessage,
      hasValue,
      className,
      id,
      iconClassName,
      ...inputProperties
    } = props;

    return (
      <div className="input_form">
        <label htmlFor={id}>
          <input
            id={id}
            className={classNames(`input--login ${className}`, {
              'has-text': hasValue,
            })}
            {...inputProperties}
            ref={ref}
          />
          <span
            className={classNames(
              'place-label Associate ID d-flex justify-content-start align-items-baseline',
              {
                'text-danger': errorMessage?.length,
              },
            )}
            title={errorMessage}
          >
            {iconClassName && <i className={iconClassName} />} {label}{' '}
            {errorMessage}
          </span>
        </label>
      </div>
    );
  },
);

export default InputGroup;
