import React from 'react';
import {useDispatch} from 'react-redux';
import ApiAction from '../store/action/ApiAction';
import {AppDispatch} from '../store/store';
import AssetAction, {getSearchAssets} from '../store/action/AssetAction';
import {GetAssetParam} from '../utils/ApiInterface';
import ApiType from '../store/type/ApiType';

/**
 * Centralize the execution of an asynchronous action to be able to cancel it if it is called from several functions.
 *
 * @todo Create generic interface that allows to reuse this hook with any asynchronous action.
 */
const useFetchAsset = (
  parameters: GetAssetParam,
  initExecutionCounter = 1,
): VoidFunction => {
  const dispatch = useDispatch<AppDispatch>();
  const [executionCounter, setExecutionCounter] =
    React.useState<number>(initExecutionCounter);

  const incrementExecutionCounter = React.useCallback(() => {
    setExecutionCounter((prevState) => prevState + 1);
  }, []);

  React.useEffect(() => {
    let innerAbortPromise: VoidFunction | undefined;
    if (executionCounter) {
      const promise = dispatch(getSearchAssets(parameters));
      innerAbortPromise = promise.abort;
      const label = ApiAction.labelToGetSearchAssets();
      dispatch({
        type: ApiType.API_INIT,
        payload: {label},
      });

      promise
        .unwrap()
        .then((response) => {
          dispatch({
            type: ApiType.API_SUCCESS,
            payload: {label},
          });
          dispatch(AssetAction.saveAssetsBySearch(label, response));
        })
        .catch((error) => {
          if (error && 'AbortError' === error.name) {
            return;
          }

          dispatch({
            type: ApiType.API_FAILURE,
            payload: {label, error},
          });
        })
        .finally(() => {
          innerAbortPromise = undefined;
        });
    }

    return () => {
      if (innerAbortPromise) {
        innerAbortPromise();
      }
    };
  }, [dispatch, executionCounter, parameters]);

  return incrementExecutionCounter;
};

export default useFetchAsset;
