import React from 'react';
import {Redirect, useParams} from 'react-router-dom';
import UrlProcessor from '../utils/UrlProcessor';
import Preloader from './Preloader';
import routeCollection from '../router/routeCollection';
import {useGetHierarchiesQuery} from '../store/service/assetHierarchyService';
import {useGetAssetQuery} from '../store/service/assetService';

function trainerIdValidator(Component: React.FC): React.FC<{assetId: number}> {
  function TrainerIdValidator(props: {assetId: number}): JSX.Element {
    const {assetId} = props;
    const params = useParams<{id: string}>();
    const id = React.useMemo(() => parseInt(params.id, 10), [params.id]);

    const {data, isFetching: isTrainerFetching} = useGetAssetQuery(assetId);
    const {data: hierarchies = {'hydra:member': []}, isFetching} =
      useGetHierarchiesQuery({
        'parent.id': [assetId],
        'child.id': [id],
      });
    const [hierarchy] = hierarchies['hydra:member'];

    if (isTrainerFetching || isFetching) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Preloader />
        </div>
      );
    }

    if (undefined === data || undefined === hierarchy) {
      return <Redirect to={UrlProcessor.createPath(routeCollection.home)} />;
    }

    return <Component />;
  }

  const componentName = Component.displayName || Component.name || 'component';
  TrainerIdValidator.displayName = componentName;

  return TrainerIdValidator;
}

export default trainerIdValidator;
