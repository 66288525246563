import {
  AddCategoryIdInSearchAction,
  PayloadInterface,
  RemoveCategoryIdInSearchAction,
  SearchOptionAction,
} from '../interface';
import SearchType from '../type/SearchType';

class SearchAction {
  public static saveSearchOption(
    term: string,
  ): PayloadInterface<SearchOptionAction> {
    return {
      type: SearchType.SAVE_SEARCH_OPTION,
      payload: {term},
    };
  }

  public static saveCategoryId(
    categoryId: string,
    context: string,
  ): PayloadInterface<AddCategoryIdInSearchAction> {
    return {
      type: SearchType.SAVE_CATEGORY_ID,
      payload: {categoryId, context},
    };
  }

  public static removeCategoryId(
    categoryId: string,
    context: string,
  ): PayloadInterface<RemoveCategoryIdInSearchAction> {
    return {
      type: SearchType.REMOVE_CATEGORY_ID,
      payload: {categoryId, context},
    };
  }

  public static resetSearch(): PayloadInterface<undefined> {
    return {
      type: SearchType.RESET_SEARCH,
      payload: undefined,
    };
  }
}

export default SearchAction;
