import React from 'react';
import CustomScroll from 'react-custom-scroll';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {Button} from 'reactstrap';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import CommentRow from './CommentRow';
import Divider from './Divider';
import Preloader from './Preloader';
import useGetUserQualificationIdsByAsset from '../hook/useGetUserQualificationIdsByAsset';
import {AppDispatch, RootState} from '../store/store';
import UrlProcessor from '../utils/UrlProcessor';
import {getQualificationsByAssetId} from '../store/action/userAssetQualification';
import AssetAction from '../store/action/AssetAction';
import {GetUserAssetQualificationParam} from '../utils/ApiInterface';

export interface CommentListProps {
  assetId: number;
}

const CommentList: React.FC<CommentListProps> = (props) => {
  const {assetId} = props;
  const dispatch = useDispatch<AppDispatch>();
  const {t} = useTranslation();
  const [qualificationRequestStatus, setQualificationRequestStatus] =
    React.useState<AsyncActionStatus>(AsyncActionStatus.NOT_EXECUTED);

  const qualification = useGetUserQualificationIdsByAsset(assetId);

  const qualificationByAssetId = useSelector(
    (state: RootState) => state.qualification.qualificationByAsset[assetId],
  );

  const nextQueryParameters = React.useMemo(():
    | {page: number; limit?: number}
    | undefined => {
    if (
      qualificationByAssetId &&
      qualificationByAssetId['hydra:view'] &&
      qualificationByAssetId['hydra:view']['hydra:next']
    ) {
      const nextParams =
        qualificationByAssetId['hydra:view']['hydra:next'].split('?')[1];
      const queryParams = UrlProcessor.queryToObject(nextParams) || {};
      let page = 1;
      let limit = 30;

      if (queryParams.page && 'string' === typeof queryParams.page) {
        page = parseInt(queryParams.page, 10);
      }

      if (queryParams.limit && 'string' === typeof queryParams.limit) {
        limit = parseInt(queryParams.limit, 10);
      }

      return {page, limit};
    }

    return undefined;
  }, [qualificationByAssetId]);

  const getMoreData = React.useCallback(
    (queryParameters: Omit<GetUserAssetQualificationParam, 'asset'> = {}) => {
      setQualificationRequestStatus(AsyncActionStatus.PENDING);
      const promise = dispatch(
        getQualificationsByAssetId({
          assetId,
          options: {...queryParameters},
        }),
      );

      promise
        .unwrap()
        .then((response) => {
          setQualificationRequestStatus(AsyncActionStatus.SUCCESS);
          dispatch(AssetAction.saveQualificationsByAsset(assetId, response));
        })
        .catch(() => {
          setQualificationRequestStatus(AsyncActionStatus.FAILURE);
        });
    },
    [dispatch, assetId],
  );

  React.useEffect(() => {
    getMoreData({});
  }, [getMoreData]);

  return (
    <div>
      <h5 className="comment__title mb-4">
        {t('qualification.titleQualification')}
      </h5>
      <CustomScroll allowOuterScroll>
        <div className="comment__widgets">
          {0 === qualification.length &&
            (AsyncActionStatus.SUCCESS === qualificationRequestStatus ||
              AsyncActionStatus.FAILURE === qualificationRequestStatus) && (
              <div>{t('qualification.noQualification')}</div>
            )}
          {qualification.map((qualificationId) => (
            <CommentRow
              key={qualificationId}
              qualificationId={qualificationId}
            />
          ))}

          {AsyncActionStatus.PENDING === qualificationRequestStatus && (
            <div className="d-flex aling-items-center justify-content-center">
              <Preloader />
            </div>
          )}
        </div>
      </CustomScroll>
      {nextQueryParameters && (
        <Divider>
          <Button
            onClick={() => getMoreData(nextQueryParameters)}
            disabled={AsyncActionStatus.PENDING === qualificationRequestStatus}
            color="dark"
            className="divider__content-circle"
          >
            <i className="icomoon icon-chevron-down-solid" />
          </Button>
        </Divider>
      )}
    </div>
  );
};

export default CommentList;
