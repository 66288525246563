import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';
import {useTranslation} from 'react-i18next';
import AssetTrainer from '../class/AssetTrainer';
import useGetQueryParameters from '../hook/useGetQueryParameters';
import routeCollection from '../router/routeCollection';
import UrlProcessor from '../utils/UrlProcessor';
import ContinueWatchingButton from './ContinueWatchingButton';
import {Asset} from '../utils/ApiInterface';

export interface PreviewNextAssetActionProps {
  currentAsset: Asset;
  nextAssetId: number;
  trainer: Asset;
}

const PreviewNextAssetAction: React.FC<PreviewNextAssetActionProps> = (
  props,
) => {
  const {nextAssetId: assetId, currentAsset, trainer} = props;
  const queryParameters = useGetQueryParameters();
  const {t} = useTranslation();

  const nextAssetUrlPlayerPath = React.useMemo(
    () =>
      UrlProcessor.createPath(
        routeCollection.player,
        {
          id: assetId,
        },
        {
          ...queryParameters,
          [AssetTrainer.URL_QUERY_PARAMETER_KEY]: trainer.id,
        },
      ),
    [assetId, trainer, queryParameters],
  );

  return (
    <div>
      <ContinueWatchingButton asset={currentAsset} trainer={trainer} />
      <Button
        color="dark"
        tag={Link}
        to={nextAssetUrlPlayerPath}
        className="btn--dark"
      >
        {t('component.playNext')}
      </Button>
    </div>
  );
};

export default PreviewNextAssetAction;
