import React from 'react';
import {Link} from 'react-router-dom';
import {Asset} from '../utils/ApiInterface';
import ImageLoad from './ImageLoad';
import useUpdateQueryParameters from '../hook/useUpdateQueryParameters';
import useGetQueryParameters from '../hook/useGetQueryParameters';
import useGetAssetImage from '../hook/useGetAssetImage';

export interface AssetItemCarouselProps {
  showThumbnail?: boolean;
  asset: Asset;
}

const AssetItemCarousel: React.FC<AssetItemCarouselProps> = (props) => {
  const {asset, showThumbnail} = props;
  const poster = useGetAssetImage(asset, showThumbnail);
  const {title, id} = asset;
  const queryParameters = useGetQueryParameters();
  const {getUrl} = useUpdateQueryParameters();

  const detailPath = React.useMemo(() => {
    return getUrl({...queryParameters, asset: id.toString()});
  }, [getUrl, id, queryParameters]);

  return (
    <div className="carousel-asset-item">
      <Link
        className="f-block w-100 h-100 text-decoration-none"
        to={detailPath}
      >
        <div className="carousel-asset-item__background-opacity">
          {title && <p className="carousel-asset-item__description">{title}</p>}
        </div>
        <div className="carousel-asset-item__img-box">
          {poster && (
            <ImageLoad
              src={poster}
              alt={title}
              className="carousel-asset-item__img"
            />
          )}
        </div>
      </Link>
    </div>
  );
};

export default React.memo(AssetItemCarousel);
