import {parse, stringify, ParsedQuery} from 'query-string';
import {generatePath} from 'react-router-dom';

/* eslint-disable @typescript-eslint/no-explicit-any */

class UrlProcessor {
  public static createPath(
    url: string,
    urlParameter: Record<string, string | number | boolean> = {},
    queryParam: Record<string, any> = {},
    hashes: string[] = [],
  ): string {
    let newUrl = generatePath(url, urlParameter);

    if (Object.keys(queryParam).length) {
      newUrl += `?${UrlProcessor.objectToQuery(queryParam)}`;
    }

    if (hashes.length) {
      newUrl += hashes.map((hash) => `#${hash}`).join('');
    }

    return newUrl;
  }

  static objectToQuery(parameter: Record<string, any>): string {
    const options: Record<string, string> = Object.keys(parameter).reduce(
      (lastValue, option) => {
        let result = {...lastValue};
        if (parameter[option]) {
          result = {...result, [option]: parameter[option]};
        }

        return result;
      },
      {},
    );

    return stringify(options, {arrayFormat: 'index'});
  }

  /**
   * Transform a URL query string into an object.
   *
   * @param {string} queryString
   *
   * @returns {object}
   */
  static queryToObject(queryString: string): ParsedQuery<string> {
    return {...parse(queryString, {arrayFormat: 'index'})};
  }
}

export default UrlProcessor;
