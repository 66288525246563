import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import FilterSearch from '../component/FilterSearch';
import ApiAction from '../store/action/ApiAction';
import SearchAction from '../store/action/SearchAction';
import {AppDispatch, RootState} from '../store/store';
import useGetRequestStatus from '../hook/useGetRequestStatus';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import SkeletonSlider from '../component/SkeletonSlider';
import useGetSearchParameters from '../hook/useGetSearchParameters';
import AssetResult from '../component/AssetResult';
import ListItem from '../component/ListItem';
import useGetQueryParameters from '../hook/useGetQueryParameters';
import QueryAssetModal from './QueryAssetModal';
import useFetchAsset from '../hook/useFetchAsset';

const Search = (): JSX.Element => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = React.useState(1);
  const parameterBySearchAssetsRequest = useGetSearchParameters();
  const queryParameter = useGetQueryParameters();

  const some = React.useMemo(
    () => ({...parameterBySearchAssetsRequest, page}),
    [parameterBySearchAssetsRequest, page],
  );

  const executeSearchAssetsRequest = useFetchAsset(some);

  React.useEffect(() => {
    if (
      queryParameter &&
      'string' === typeof queryParameter.term &&
      queryParameter.term !== parameterBySearchAssetsRequest.search
    ) {
      dispatch(SearchAction.saveSearchOption(queryParameter.term || ''));
    }
  }, [dispatch, queryParameter, parameterBySearchAssetsRequest]);

  const categoryName = React.useMemo(
    () => ApiAction.labelToGetSearchAssets(),
    [],
  );

  // Clean previous search results when this component is unmounted.
  React.useEffect(() => {
    return () => {
      dispatch(SearchAction.resetSearch());
    };
  }, [dispatch]);

  const requestState = useGetRequestStatus(categoryName);

  const assetCollection = useSelector((state: RootState) => {
    const {
      assets: {search: searchCollections},
    } = state;

    return searchCollections;
  });

  const getMoreData = React.useCallback((newPage = 1) => {
    setPage(newPage);
  }, []);

  const restoreSearch = React.useCallback(() => {
    dispatch(SearchAction.resetSearch());
    executeSearchAssetsRequest();
  }, [dispatch, executeSearchAssetsRequest]);

  return (
    <div className="result-page page">
      <QueryAssetModal />
      <div className="row">
        <div className="col-12 col-lg-3 mb-4 pb-2 pb-md-0 mb-lg-0">
          <div className="result-page__filter">
            <FilterSearch activateSearch={restoreSearch} />
          </div>
        </div>
        <div className="col-12 col-lg-9">
          {0 === assetCollection.length &&
            (AsyncActionStatus.SUCCESS === requestState ||
              AsyncActionStatus.FAILURE === requestState ||
              AsyncActionStatus.CANCEL === requestState) && (
              <div className="text-center">
                {t('page.search.noSearch', {
                  term: parameterBySearchAssetsRequest.search,
                })}
              </div>
            )}

          {AsyncActionStatus.PENDING === requestState &&
          0 === assetCollection.length ? (
            <SkeletonSlider />
          ) : (
            <AssetResult getMoreData={getMoreData} categoryName={categoryName}>
              {0 < assetCollection.length && (
                <div className="grid-list grid-list--search">
                  {assetCollection.map((assetId) => {
                    return (
                      <ListItem assetId={assetId} key={`result_${assetId}`} />
                    );
                  })}
                </div>
              )}
            </AssetResult>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
