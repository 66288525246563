import React from 'react';
import omit from 'lodash/omit';
import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';
import routeCollection from '../router/routeCollection';
import UrlProcessor from '../utils/UrlProcessor';
import useGetQueryParameters from '../hook/useGetQueryParameters';

export interface ButtonLinkToPlayerProps {
  assetId: number;
  className?: string;
}

const ButtonLinkToPlayer: React.FC<ButtonLinkToPlayerProps> = (props) => {
  const {assetId, children, ...properties} = props;
  const queryParameters = useGetQueryParameters();
  const parameters = omit(queryParameters, ['asset']);

  const assetPlayerUrlPath = UrlProcessor.createPath(
    routeCollection.player,
    {
      id: assetId,
    },
    parameters,
  );

  return (
    <Button tag={Link} to={assetPlayerUrlPath} {...properties}>
      {children}
    </Button>
  );
};

export default ButtonLinkToPlayer;
