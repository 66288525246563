import {useMemo} from 'react';
import {useGetHierarchiesQuery} from '../store/service/assetHierarchyService';
import {useGetUserAssetHistoriesQuery} from '../store/service/userAssetHistoryService';
import {OrderOption} from '../utils/ApiInterface';

const useGetNextAssetId = (assetId: number): number | undefined => {
  const {data, isSuccess} = useGetHierarchiesQuery({
    'parent.id': [assetId],
    'order[position]': OrderOption.asc,
  });

  const {data: userAssetHistories = {'hydra:member': []}} =
    useGetUserAssetHistoriesQuery({
      'asset.parent.id': assetId,
      limit: 1,
      'order[lastViewAt]': OrderOption.desc,
    });
  const [history] = userAssetHistories['hydra:member'];

  return useMemo(() => {
    if (
      undefined === data ||
      false === isSuccess ||
      (isSuccess && 0 === data['hydra:member'].length)
    ) {
      return undefined;
    }

    const firstHierarchy =
      data['hydra:member'].find((hierarchy) => 0 === hierarchy.position) ||
      data['hydra:member'][0];

    // If the training program has no history.
    if (undefined === history) {
      return firstHierarchy.childId;
    }

    // If the last material viewed has not been completed.
    if (null === history.completedAt) {
      return history.asset.id;
    }

    // Current material position.
    const currentPosition = data['hydra:member'].find(
      (hierarchy) => hierarchy.childId === history.asset.id,
    );

    if (currentPosition) {
      // Retrieve the next child by incrementing the position by 1.
      const nextPosition = data['hydra:member'].find(
        (hierarchy) => hierarchy.position === currentPosition.position + 1,
      );

      if (nextPosition) {
        return nextPosition.childId;
      }
    }

    // By default the first child of the training program is returned.
    return firstHierarchy.childId;
  }, [data, history, isSuccess]);
};

export default useGetNextAssetId;
