import React from 'react';
import {useTranslation} from 'react-i18next';
import Preloader from './Preloader';
import AssetGridItemLayout from './AssetGridItemLayout';
import {useGetAssetsQuery} from '../store/service/assetService';
import {Asset} from '../utils/ApiInterface';

export interface SuggestAssetListProps {
  asset: Asset;
}

const SuggestAssetList: React.FC<SuggestAssetListProps> = (props) => {
  const {asset} = props;
  const {t} = useTranslation();

  const competencyIds = React.useMemo(
    () => asset.competencies.map((competency) => competency.id),
    [asset],
  );
  const {data: suggests = {'hydra:member': []}, isFetching} = useGetAssetsQuery(
    {
      competencyParent: competencyIds,
      limit: 10,
    },
    {
      skip: 0 === competencyIds.length,
    },
  );

  if (isFetching) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Preloader />
      </div>
    );
  }

  if (suggests && 0 === suggests['hydra:member'].length) {
    return <div>{t('suggest.noSuggest')}</div>;
  }

  return (
    <div className="row">
      {suggests &&
        suggests['hydra:member']
          .filter((assetSuggested) => assetSuggested.id !== asset.id)
          .slice(0, 9)
          .map((suggestAsset) => {
            return (
              <div
                key={suggestAsset.id}
                className="col-12 col-sm-6 col-lg-4 mb-3"
              >
                <div className="h-100 d-flex flex-column">
                  <AssetGridItemLayout asset={suggestAsset} className="h-100" />
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default SuggestAssetList;
