import React from 'react';
import {useSelector} from 'react-redux';
import {ListGroup, ListGroupItem} from 'reactstrap';
import Category from '../interface/Category';
import {RootState} from '../store/store';
import FilterItem from './FilterItem';

interface FilterListProps {
  category: Category;
  context: string;
}

const FilterList: React.FC<FilterListProps> = (props) => {
  const {category, context} = props;

  const childrenIds = useSelector((state: RootState) => {
    const {
      categories: {category: categories},
    } = state;

    return Object.keys(categories).filter(
      (categoryId) => categories[categoryId].parent === category.id,
    );
  });

  return (
    <ListGroup>
      <ListGroupItem className="filter-search__item">
        <div className="filter-search__title text-white">{category.name}</div>
        <ListGroup flush>
          {childrenIds.map((categoryId, index) => (
            <ListGroupItem
              className="filter-search__item filter-search__item--children"
              key={`search_${category.id}_${categoryId}`}
            >
              <FilterItem
                categoryId={categoryId}
                position={index}
                context={context}
              />
            </ListGroupItem>
          ))}
        </ListGroup>
      </ListGroupItem>
    </ListGroup>
  );
};

export default FilterList;
