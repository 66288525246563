import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

export interface AssetPublicationDateProps {
  publishStartAt: string;
  iconClassName?: string;
  className?: string;
  showIcon?: boolean;
  showDate?: boolean;
  dateFormat?: string;
}

/**
 * Renders the asset's publication date.
 */
const AssetPublicationDate: React.FC<AssetPublicationDateProps> = (props) => {
  const {
    publishStartAt,
    showDate = true,
    showIcon = true,
    dateFormat = 'YYYY',
    iconClassName = 'icomoon icon-show_late',
    className,
  } = props;
  const date = React.useMemo(() => {
    return moment(publishStartAt);
  }, [publishStartAt]);

  return (
    <div className={classNames('d-flex align-items-center', className)}>
      {showIcon && (
        <span className="standard-icon-height standard-icon-height--14">
          <i
            className={classNames('standard-icon-size--14 me-2', iconClassName)}
          />
        </span>
      )}
      {showDate && (
        <span className="standard-icon-height standard-icon-height--14">
          {date.format(dateFormat)}
        </span>
      )}
    </div>
  );
};

export default React.memo(AssetPublicationDate);
