import React from 'react';
import AssetModel from '../model/AssetModel';
import {useGetUserAssetHistoriesQuery} from '../store/service/userAssetHistoryService';
import {Asset, GetUserAssetHistoriesParam} from '../utils/ApiInterface';

export interface AssetProgressBarProps {
  asset: Asset;
  trainerId?: number;
}

/**
 * Renders the progress bar of the percentage of duration consumed.
 */
const AssetProgressBar: React.FC<AssetProgressBarProps> = (props) => {
  const {asset, trainerId} = props;
  const {duration = 0, type, id: assetId} = asset;
  const userAssetHistoryParams = React.useMemo(() => {
    let query: GetUserAssetHistoriesParam = {'asset.id': [assetId]};
    if (trainerId) {
      query = {...query, 'asset.parent.id': trainerId};
    }

    return query;
  }, [assetId, trainerId]);
  const {data = {'hydra:member': []}} = useGetUserAssetHistoriesQuery(
    userAssetHistoryParams,
  );
  const [history] = data['hydra:member'];

  const width = React.useMemo((): number => {
    if (undefined === history) {
      return 0;
    }

    // If the asset is of type `WEB` or `PDF`,
    // it is evaluated if `history.completedAt` is `true` to return 100 percent, otherwise 0 is returned.
    if (
      (history && AssetModel.TYPE_PDF === type) ||
      AssetModel.TYPE_WEB === type
    ) {
      return history.completedAt ? 100 : 0;
    }

    if (history.completedAt && 0 === history.lastPosition) {
      return 100;
    }

    return (history.lastPosition / duration) * 100;
  }, [duration, type, history]);

  return (
    <div className="progress-content">
      <div className="progress-bar" style={{width: `${width}%`}} />
    </div>
  );
};

export default React.memo(AssetProgressBar);
