import {useMemo} from 'react';
import {Asset} from '../utils/ApiInterface';

const useGetAssetImage = (
  asset: Asset,
  showThumbnail?: boolean,
): string | undefined => {
  return useMemo(() => {
    const {cover, thumbnail} = asset;
    const {publicUrls: coverPublicUrls} = cover || {};
    const {reference: posterUrl} = coverPublicUrls || {};
    const {publicUrls: thumbnailPublicUrls} = thumbnail || {};
    const {reference: miniatureUrl} = thumbnailPublicUrls || {};

    return showThumbnail ? miniatureUrl : posterUrl;
  }, [asset, showThumbnail]);
};

export default useGetAssetImage;
