import {AnyAction} from '@reduxjs/toolkit';
import AuthenticationType from '../type/AuthenticationType';

export interface AuthenticateReducer {
  isAuthenticated: boolean;
  authenticationType: string;
}

export const initialState: AuthenticateReducer = {
  authenticationType: '',
  isAuthenticated: false,
};

const authenticateReducer = (
  state = initialState,
  action: AnyAction,
): AuthenticateReducer => {
  switch (action.type) {
    case AuthenticationType.SIGN_IN: {
      const {payload} = action;

      return {
        authenticationType: payload,
        isAuthenticated: true,
      };
    }

    case AuthenticationType.SIGN_OUT: {
      return {
        authenticationType: '',
        isAuthenticated: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default authenticateReducer;
