import React from 'react';
import omit from 'lodash/omit';
import useGetQueryAssetParameter from '../hook/useGetQueryAssetParameter';
import useGetQueryParameters from '../hook/useGetQueryParameters';
import useUpdateQueryParameters from '../hook/useUpdateQueryParameters';
import AssetModal from '../component/AssetModal';
import AssetLayout from '../class/AssetLayout';

/**
 * Render a `AssetModal` component if it receives the parameter "asset" by URL.
 */
const QueryAssetModal: React.FC = () => {
  const queryParameters = useGetQueryParameters();
  const assetId = useGetQueryAssetParameter();
  const {updateHistoryBrowser} = useUpdateQueryParameters();

  const action = React.useMemo(() => {
    const actionLayoutQueryParameter =
      queryParameters[AssetLayout.ASSET_LAYOUT_ACTION];
    if (
      actionLayoutQueryParameter &&
      'string' === typeof actionLayoutQueryParameter
    ) {
      try {
        return AssetLayout.getAssetLayoutAction(actionLayoutQueryParameter);
      } catch (error) {
        return undefined;
      }
    }

    return undefined;
  }, [queryParameters]);

  const closeModal = React.useCallback(() => {
    updateHistoryBrowser(
      omit(queryParameters, [
        'asset',
        AssetLayout.ASSET_LAYOUT_ACTION,
        'language',
      ]),
    );
  }, [queryParameters, updateHistoryBrowser]);

  if (!assetId) {
    return null;
  }

  return (
    <AssetModal
      assetId={assetId}
      closeModal={closeModal}
      actionLayout={action}
    />
  );
};

export default QueryAssetModal;
