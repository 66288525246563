import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import authenticationValidator from '../router/authenticationValidator';
import HomePageLayout from '../component/HomePageLayout';
import ApiAction from '../store/action/ApiAction';
import useGetRequestStatus from '../hook/useGetRequestStatus';
import AsyncActionStatus from '../interface/AsyncActionStatus';
import Preloader from '../component/Preloader';
import {AppDispatch, RootState} from '../store/store';
import newAssetHOC from '../component/NewAssetHOC';
import CategoryAction, {
  getCategoriesByContext,
} from '../store/action/CategoryAction';
import AssetAction, {
  getFavoriteAssets,
  getLastAssets,
  getMyTrainingPlan,
} from '../store/action/AssetAction';
import ApiType from '../store/type/ApiType';
import {useGetCategoryQuery} from '../store/service/classificationService';
import CategoryEntity from '../entity/CategoryEntity';

const Component = newAssetHOC(HomePageLayout);

const HomeHOC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {data, isLoading} = useGetCategoryQuery(CategoryEntity.ID_LANGUAGE);

  const [lastAssetsRequest, setLastAssetsRequest] = React.useState(
    AsyncActionStatus.NOT_EXECUTED,
  );
  const myTrainingPlanAssetRequest = useGetRequestStatus(
    ApiAction.labelToGetMyTrainingPlan(),
  );

  const isCompetencyLoaded = useSelector(
    (state: RootState) => state.categories.categoryParent.competency,
  );
  const isSalesChannelLoaded = useSelector(
    (state: RootState) => state.categories.categoryParent.sales_channel,
  );

  React.useEffect(() => {
    if (undefined === isCompetencyLoaded) {
      dispatch(
        getCategoriesByContext({
          categoryParam: {
            limit: 100,
            'context.id': ['competency'],
            exists: {parent: true},
          },
        }),
      )
        .unwrap()
        .then((response) => {
          dispatch(CategoryAction.saveCategories(response));
        });
    }
  }, [dispatch, isCompetencyLoaded]);

  React.useEffect(() => {
    if (undefined === isSalesChannelLoaded) {
      dispatch(
        getCategoriesByContext({
          categoryParam: {
            limit: 100,
            'context.id': ['sales_channel'],
            exists: {parent: true},
          },
        }),
      )
        .unwrap()
        .then((response) => {
          dispatch(CategoryAction.saveCategories(response));
        });
    }
  }, [dispatch, isSalesChannelLoaded]);

  React.useEffect(() => {
    const promise = dispatch(getMyTrainingPlan());
    let abortPromise: VoidFunction | undefined = promise.abort;
    const label = ApiAction.labelToGetMyTrainingPlan();
    dispatch({
      type: ApiType.API_INIT,
      payload: {label},
    });

    promise
      .unwrap()
      .then((response) => {
        dispatch(AssetAction.saveAssetsByCategory(label, response));
        dispatch({
          type: ApiType.API_SUCCESS,
          payload: {label},
        });
      })
      .catch((error) => {
        dispatch({
          type: ApiType.API_FAILURE,
          payload: {label, error},
        });
      })
      .finally(() => {
        abortPromise = undefined;
      });

    return () => {
      if (abortPromise) {
        abortPromise();
      }
    };
  }, [dispatch]);

  React.useEffect(() => {
    let abortPromise: VoidFunction | undefined;

    const promise = dispatch(getLastAssets({}));
    abortPromise = promise.abort;
    const label = ApiAction.labelToGetLastAssets();
    dispatch({
      type: ApiType.API_INIT,
      payload: {label},
    });
    setLastAssetsRequest(AsyncActionStatus.PENDING);

    promise
      .unwrap()
      .then((response) => {
        dispatch(AssetAction.saveAssetsByCategory(label, response));
        dispatch({
          type: ApiType.API_SUCCESS,
          payload: {label},
        });
        setLastAssetsRequest(AsyncActionStatus.SUCCESS);
      })
      .catch((error) => {
        dispatch({
          type: ApiType.API_FAILURE,
          payload: {label, error},
        });
        setLastAssetsRequest(AsyncActionStatus.FAILURE);
      })
      .finally(() => {
        abortPromise = undefined;
      });

    return () => {
      if (abortPromise) {
        abortPromise();
      }
    };
  }, [dispatch]);

  React.useEffect(() => {
    let abortPromise: VoidFunction | undefined;

    const promise = dispatch(getFavoriteAssets({}));
    abortPromise = promise.abort;
    const label = ApiAction.labelToGetFavoriteAssets();
    dispatch({
      type: ApiType.API_INIT,
      payload: {label},
    });

    promise
      .unwrap()
      .then((response) => {
        dispatch(AssetAction.saveAssetsByCategory(label, response));
        dispatch({
          type: ApiType.API_SUCCESS,
          payload: {label},
        });
      })
      .catch((error) => {
        dispatch({
          type: ApiType.API_FAILURE,
          payload: {label, error},
        });
      })
      .finally(() => {
        abortPromise = undefined;
      });

    return () => {
      if (abortPromise) {
        abortPromise();
      }
    };
  }, [dispatch]);

  if (
    AsyncActionStatus.NOT_EXECUTED === lastAssetsRequest ||
    AsyncActionStatus.PENDING === lastAssetsRequest ||
    AsyncActionStatus.NOT_EXECUTED === myTrainingPlanAssetRequest ||
    AsyncActionStatus.PENDING === myTrainingPlanAssetRequest ||
    undefined === data ||
    isLoading
  ) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Preloader />
      </div>
    );
  }

  return <Component />;
};

export default authenticationValidator(HomeHOC);
